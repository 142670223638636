import React from "react";

const VideoList = ({ list, setHistory, contentz, subject }) => {
  console.log(list, contentz, subject);
  const videoItem = list.map((item) => {
    return (
      <div
        key={item.id}
        className="video-item row"
        onClick={() =>
          setHistory({
            path: "video",
            id: item.id,
            subject,
            contents: contentz,
          })
        }
      >
        <div className="col-2 padding-left0 padding-right0">
          <img
            src="images/play-button.svg"
            className="img-responsive"
            alt="general Thumbnail"
          />
        </div>

        <div className="col-9 px-0">
          <h4 title={item.topic}>{`${item.topic.substr(0, 25)} ${
            item.topic.length > 18 ? "..." : ""
          }`}</h4>
          <div className="short-desc">{item.videoName}</div>
          <div className="ratings"></div>
        </div>
      </div>
    );
  });
  return <div className="px---4">{videoItem}</div>;
};

export default VideoList;
