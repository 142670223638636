import React from "react";
import Avatar from "./Avatar";

const LoginForm = (props) => {
  return (
    <React.Fragment>
      {props.users && (
        <div className="container-fluid login-wrapper">
          <div className="container login-padding text-center">
            <div className="login-items ">
              <h2 className="choose">Choose your profile</h2>
              <h4 className="learning">Who is Learning?</h4>
            </div>
            <div className="row">
              <div className="col-md-12 forced-margin">
                {props.users.map((user) => (
                  <div className="card" key={user.id} className="card-avatar">
                    <div className="card-body">
                      <Avatar
                        id={user.id}
                        name={user.firstName}
                        sex={user.sex}
                        submitForm={(e, userId) => props.submitForm(e, userId)}
                      />
                    </div>
                  </div>
                ))}

                {props.users.length < 4 && (
                  <div className="newButton">
                    <div className="card" className="card-avatar">
                      <div className="card-body card-box-style">
                        <img
                          onClick={props.goToRegister}
                          className="butt c-pointer"
                          src="images/plus-sign.svg"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default LoginForm;
