import ls from '../sideEffects/ourLocalStorage'
const reviewReducer = (state = false, action) => {
    switch (action.type) {
        case "START_REVIEW":
            ls.persistItemInLS("review", action.data, 3)
            return action.data;
        default:
            return state;
    }
}

export default reviewReducer;