import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
//import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store/store';
import ConnectedApp from './App/ConnectedApp';
import { BrowserRouter, Route } from 'react-router-dom';

console.log = () => {};

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Route path="/" component={ConnectedApp} />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
