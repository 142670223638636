const singleVideoReducer = (state = [], action) => {
    switch (action.type) {
        case "ADD_VIDEO":
            return state.concat(action.data);
        case "EDIT_VIDEO":
            return state.map(video => video.id === action.id ? action.data : video);
        default:
            return state;
    }
}

export default singleVideoReducer;