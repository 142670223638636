export const setUserCourses = (data) => {
    return { type: "SET_USER_COURSES", data }
}

export const editUserCourses = (data) => {
    return { type: "EDIT_USER_COURSES", data }
}

export const persistUserCourses = (data) => {
    return { type: "PERSIST_USER_COURSES", data }
}