import React, { useState } from 'react'
import SignedInUser from '../SignedInUser'
import LoginForm from './LoginForm'
import auth from '../../../sideEffects/apis/auth'
import ls from '../../../sideEffects/ourLocalStorage'
import { useHistory, useLocation } from 'react-router-dom'

const Login = (props) => {
    const appHistory = useHistory();
    const usersFromStorage = ls.getItemInLs('users');
    let users = props.users;
    if(!users.length && usersFromStorage){
        props.persistUser(usersFromStorage);
        users = usersFromStorage;
    }

    const userFromStorage = ls.getItemInLs('user');
    let user = props.user;
    if(!user && userFromStorage){
        props.setActiveUser(userFromStorage);
        user = userFromStorage;
    }
    //Function that does login to the server where data
    //is data from login form and auth.login is a login
    //function defined at the auth file in ../../sideEfects/apis/auth
    const goToRegister = () => {
        appHistory.push(`/register`)
    }
    const login = (data) => {
        props.isBusy(true)
        auth.mockLogin(data)
            .then(response => {
                console.log(data)
                props.isBusy(false)
                props.setActiveUser(response);
                props.setHistory({ path: 'home' })
                appHistory.push(props.url || "/")
                
            })
            .catch(err => {
                props.isBusy(false)
                console.log(err)
            })
    }

    //This handles form submission
    const submitForm = (e, id) => {
        console.log(id)
        //debugger;
        console.log(users)
        const u = users.find(user => user.id === Number(id))
        console.log(u)
        login(u)
    }

    //This prepares all the props for the loginform component
    //the loginform component is the presentational component
    const loginProps = {
        submitForm,
        users,
        goToRegister,
        setHistory: props.setHistory,
    }

    //If the user is already signed in, do not show the login form fields
    return user ? <SignedInUser name='logged in' /> : <LoginForm {...loginProps} />
}

export default Login;


