//import axios from 'axios';
import ls from '../ourLocalStorage';

//const baseUrl = '/api/accounts/';

//const createHeaderToken = (token) => ({"authorization": `bearer ${token}`});


//const login = (loginCredentials) =>
//    axios.post(baseUrl + 'login')
//        .then(response => {
//            ls.persistItemInLS("user", response.data, 100)
//            return response.data
//        })
//        .catch(err => { throw new Error(err.response.data.message) })

//const register = (registerCredentials) =>
//    axios.post(baseUrl + 'register', { name: "Chisom Nwisu" })
//        .then(response => {
//            ls.persistItemInLS("user", response.data, 100)
//            return response.data
//        })
//        .catch(err => { throw new Error(err.response.data.message) })

const mockLogin = (data) => {
    return new Promise((resolve, reject) => {
        data ? resolve(data) : reject(data)
    })
};

export const logout = (id) => {
    ls.removeItemFromLS("user")
}

export default {
    mockLogin,
}
