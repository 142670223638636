import React, { useState } from "react";
import { useForm } from "../../../customHooks/useForm";
import { failure, defaultTo } from "../../../helpers/Validator";
import SignedInUser from "../SignedInUser";
import RegisterForm from "./RegisterForm";
import auth from "../../../sideEffects/apis/auth";
import ls from "../../../sideEffects/ourLocalStorage";
import { useHistory } from "react-router-dom";

const Register = (props) => {
  const appHistory = useHistory();
  const usersFromStorage = ls.getItemInLs("users");
  let users = props.users;
  if (!users.length && usersFromStorage) {
    props.persistUser(usersFromStorage);
    users = usersFromStorage;
  }

  const userFromStorage = ls.getItemInLs("user");
  let user = props.user;
  if (!user && userFromStorage) {
    props.setActiveUser(userFromStorage);
    user = userFromStorage;
  }
  //useForm is a custom hook that abstracts the process of setting state and value for
  //form fields. The hook simply declares a type, sets the value and performs a setState
  //funtion in an onChange funtion initialization. It returns an object in this format
  //{main: {value, type, onChange}, onSetChange} see ../../customHooks/useForm for
  //implementation
  const firstNameField = useForm("text", "", "first");
  const surNameField = useForm("text", "", "last");
  const phoneNumberField = useForm("tel", "", "phone");
  const emailField = useForm("email", "", "email");
  const [sex, setSex] = useState("Female");

  const firstSuccess = firstNameField.validateField()
  const lastSuccess = surNameField.validateField()
  const phoneSuccess = phoneNumberField.validateField()
  const emailSuccess = emailField.validateField()
  //const [password, setPassword] = useState('');
  //const [confirmPassword, setConfirmPassword] = useState('');
  //This is used for checking visibility of password field
  //const [showPassword, setShowPassword] = useState(false);
  //This contains an array of errors as a result of failed validation defined in
  //Validator.js at ../../../helpers/Validator
  //const [errors, setErrors] = useState([]);
  //const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [successStatus, setSuccessStatus] = useState({
    message: "",
    style: defaultTo,
  });

  //Show error messages when an error occurs during login
  const statusMessageHandler = (message, style) => {
    setSuccessStatus({ message, style });
    setTimeout(() => {
      setSuccessStatus({ message: "", style: defaultTo });
    }, 5000);
  };

  //This is the handler for setting password and checking validation
  //const onPasswordChange = (e) => {
  //    const passwordValue = e.target.value;
  //    setPassword(passwordValue);
  //    setErrors(passValidator(passwordValue))
  //    setConfirmPasswordError(confirmPassValidator(passwordValue, confirmPassword))
  //}

  //This is the handler for setting confirmPassword and checking validation
  //const onConfirmPasswordChange = (e) => {
  //    const confirmPasswordValue = e.target.value;
  //    setConfirmPassword(confirmPasswordValue)
  //    setConfirmPasswordError(confirmPassValidator(password, confirmPasswordValue))
  //}

  //Here, data to be sent to server is built
  const prepareData = () => {
    return {
      id: users.length + 1,
      firstName: firstNameField.main.value,
      surName: surNameField.main.value,
      phoneNumber: phoneNumberField.main.value,
      email: emailField.main.value,
      sex,
      hasTakenTest: false,
      totalScore: 0,
      courses: [],
    };
  };

  //This submits our data to the server using the auth.register function defined at auth.js at
  //../../sideEfects/apis/auth
  const submitForm = (e) => {
    e.preventDefault();
    props.isBusy(true);
    auth
      .mockLogin(prepareData())
      .then((response) => {
        props.isBusy(false);
        props.setHistory({ path: "home" });
        props.setUser(response);
        props.setActiveUser(response);
        appHistory.push(props.url || "/");
      })
      .catch((err) => {
        props.isBusy(false);
        statusMessageHandler(err.message, failure);
      });
  };

  const gotoLogin = () => {
    props.setHistory("login");
    appHistory.push("/login");
  };

  //This defines the props for the presentational component RegisterForm
  const registerProps = {
    submitForm,
    firstNameField: firstNameField.main,
    surNameField: surNameField.main,
    emailField: emailField.main,
    phoneNumberField: phoneNumberField.main,
    sex,
    users,
    setSex,
    users,
    successStatus,
    gotoLogin,
    firstSuccess,
    lastSuccess,
    emailSuccess,
    phoneSuccess
  };
  //Again, no need to show registration form for a registered user
  return user ? (
    <SignedInUser name="registered" />
  ) : (
    <RegisterForm {...registerProps} />
  );
};

export default Register;
