import ls from '../sideEffects/ourLocalStorage'
const userReducer = (state = [], action) => {
    switch (action.type) {
        case "SET_USER":
            const newData = state.concat(action.data);
            ls.persistItemInLS("users", newData, 3)
            return newData;
        case "PERSIST_USER":
            return action.data;
        case "EDIT_USER":
            console.log('edit user userReducer',action.data)
            const updatedData = state.map(user => user.id === action.data.id ? action.data : user);
            console.log('edit user userReducer after',updatedData)
            ls.persistItemInLS("users", updatedData, 3)
            return updatedData
        case "DELETE_USER":
            return null;
        default:
            return state;
    }
}

export default userReducer;