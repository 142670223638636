import React, { useState } from "react";
import { quizData } from "./quizData";
import { useHistory } from "react-router-dom";
import ParserCondition from "./ParserCondition";

const playDOM = document.getElementById("play");
const pauseDOM = document.getElementById("pause");
const audioDOM = document.getElementById("my-audio");
export default function Decision(props) {
  const appHistory = useHistory()
  const [decision, setDecision] = useState();
  const [view, setView] = useState(false);

  console.log("quiz obj", props.quizObj);
  console.log("q in store", props.qInStore);
  console.log(
    "my condition",
    (props.quizObj && props.quizObj.userId !== props.quizObj.correctId) ||
      (props.qInStore && !props.qInStore.shouldBeDisabled)
  );

  const handleClose = () => {
    setDecision(false);
    props.setHideExtras(false);
    props.setTempLockDown(() => false);
  };
  const handleShow = () => {
    setDecision(true);
    props.setHideParagraph(true);
    props.disable(true);
    props.setHideExtras(true);
    props.setTempLockDown(() => true);
    setDisabled(true);
    props.setCountForAnswer();
    props.sendQuestionToStore();
    console.log("clicked");
  };

  const openView = () => {
    props.removeVideo();
    setView(true);
    props.paddup(true);
    props.show();
    playDOM && (playDOM.style.display = "none");
    audioDOM && audioDOM.play();
    pauseDOM && (pauseDOM.style.display = "block");
  };

  const closeView = () => {
    props.changeQuestion();
    props.paddup(false);
    props.setHideParagraph(true);
    if (props.isLast) {
      appHistory.push("/thanks")
    } else {
      props.addVideo();
      setDecision(false);
      setView(false);
      props.setHideExtras(false);
      props.setTempLockDown(() => false);
      audioDOM && audioDOM.pause();
      //props.disable(false)
      setDisabled(false);
      //props.setHistory({path: "quiz", id: props.id, questionId: props.questionId + 1})
      props.finalizeTracker(props.questionId + 1);
      props.close();
    }
  };

  const [disabled, setDisabled] = useState(false);

  return (
    <React.Fragment>
      <section className="decision-style_">
        {console.log("in decision", props.qInStore)}
        <div
          className={
            (decision && props.qInStore) ||
            (props.qInStore && props.qInStore.shouldBeDisabled)
              ? "decision"
              : "decision-none"
          }
        >
          {/* <div className={view ? "view d-inline" : "viewnone"}>
            <div className="card bg-overlay">
              <div className="card-body">
                <p className="card-text font-reduced">{props.explanation}</p>
                <button className="submit move-it" onClick={closeView}>
                  Continue
                </button>
              </div>
            </div>
          </div> */}
          <div className=" row" id="dissapear">
            <div className={view ? "view d-inline" : "viewnone"}>
              <div className="card bg-overlay">
                <div className="card-body">
                  <p className="card-text font-reduced">
                    <ParserCondition
                      question={props.explanation}
                      isMathjax={
                        props.explanation
                          ? props.explanation.includes(`\(`)
                          : false
                      }
                    />
                  </p>
                  {/* <button className="submit move-it" onClick={closeView}>
                    Continue
                  </button> */}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-body decision-bg">
                <div className="padding-up">
                  <h4 className="card-title incorrect">
                    {props.quizObj &&
                    props.quizObj.userId === props.quizObj.correctId
                      ? "Correct answer"
                      : ``}
                  </h4>
                  {/* <div className="line-padding">
                    <div className="line-ish"></div>
                  </div> */}
                  <AnswerStatus
                    correct={
                      props.quizObj &&
                      props.quizObj.userId === props.quizObj.correctId
                    }
                  />

                  {props.quizObj &&
                    props.quizObj.userId !== props.quizObj.correctId &&
                    props.qInStore &&
                    !props.qInStore.shouldBeDisabled && (
                      <div className="text-center push-down">
                        <button
                          onClick={handleClose}
                          className="try-submit-blue"
                        >
                          {" "}
                          Try Again{" "}
                        </button>
                      </div>
                    )}
                  <div className="text-center push-down">
                    <button onClick={openView} className="try-submit-blue">
                      {" "}
                      View Explanation
                    </button>
                  </div>

                  <div className="text-center push-down">
                    <button onClick={closeView} className="try-submit-light">
                      {" "}
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-6">
                         <img className="character" src="images/character.png"/>  
                      </div> */}
          </div>
        </div>
      </section>
      <div>
        {props.hideExtras ||
        (props.qInStore && props.qInStore.shouldBeDisabled) ? (
          <button
            onClick={handleShow}
            disabled="disabled"
            className="done-button"
          >
            {" "}
            SUBMIT
          </button>
        ) : (
          <button onClick={handleShow} className="done-button">
            {" "}
            SUBMIT
          </button>
        )}
      </div>
    </React.Fragment>
  );
}

const AnswerStatus = ({ correct }) => {
  console.log("stat", correct);
  return (
    <>
      <div className="incorrect-text">
        {!correct && <span className="red-incorrect">That is incorrect </span>}
        {/* {!correct && <span className="text-center try-gain"></span>} */}
      </div>
    </>
  );
};
