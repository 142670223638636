import React, { useState, useEffect } from "react";
import SideMenu from "./SideMenu";
import Overlay from "./Overlay";
import Decision from "./Decision";
import ParserCondition from "./ParserCondition";
import track from "../../sideEffects/apis/courseTracker";
import ls from "../../sideEffects/ourLocalStorage";
import Audio from "./Audio";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";

//const asciimath = '`sum_(i=1)^n i^3=((n(n+1))/2)^2`'
//const content = `This can be dynamic text (e.g. user-entered) text with ascii math embedded in  symbols like $$ ${ascii}$$`

const UpsertCourse = (courses, course) => {
  return courses.find((c) => c.courseId === course.courseId)
    ? courses.map((c) => (c.courseId === course.courseId ? course : c))
    : courses.concat(course);
};

const Quiz = ({
  isBusy,
  id,
  addCourse,
  courses,
  activeCourse,
  time,
  timeUp,
  ...props
}) => {
  const appHistory = useHistory();

  let questionId = 0;
  if (
    props.history.questionId === null ||
    props.history.questionId === undefined
  ) {
    const { question } = activeCourse;
    if (question) {
      const id = question[question.length - 1].questionId;
      questionId = courses.findIndex((c) => c.id === id);
    }
    console.log("yeeeeeeeee");
  } else {
    questionId = Number(props.history.questionId);
  }
  const [show, setShow] = useState(false);
  const [showPassage, setShowPassage] = useState(false);

  const [psg, setShowPsg] = useState("d-none");
  const [disablePsgBtn, setDisablePsgBtn] = useState("d-block");
  const [disableSectBtn, setDisableSectBtn] = useState("d-block");
  const [showPssg, setShowPssg] = useState("d-none");
  const [showImage, setShowImage] = useState("d-none");
  const [showSect, setShowSect] = useState("d-none");
  const [dispsg, setDisPsg] = useState("d-none");
  const [dispImg, setDisImg] = useState("d-none");
  const [psgOverlay, setPsgOverlay] = useState("");
  const [bg, setBg] = useState("bg-transparent");
  const [zIndex, setZIndex] = useState("increase-zIndex");
  const [hideExtras, setHideExtras] = useState(false);
  const [audioPadding, setAudioPadding] = useState("");

  props.type(props.mode);

  const [disable, showDisable] = useState(false);
  const [tempLockDown, setTempLockDown] = useState(false);
  const [countForAnswer, setCountForAnswer] = useState(0);
  const [changeTracker, setChangeTracker] = useState(false);
  const [hardSetDisabled, setHardSetDisabled] = useState(false);
  const [hideParagraph, setHideParagraph] = useState(false);

  console.log("my since", courses, activeCourse);
  const mainQuiz = courses
    ? courses.find((course) => course.id === courses[questionId].id)
    : null;
  console.log("temp lockdown", tempLockDown);
  //const sec = mainQuiz.isfirstSection ? 'section-reset' : 'section-hide';
  const [showSection, setShowSection] = useState("section-hide");
  const current = activeCourse
    ? activeCourse.question.find((q) => q.questionId === courses[questionId].id)
    : null;

  const thisQuestionInStore = current;
  console.log("current idd", id, current, questionId);
  console.log("this q in store", thisQuestionInStore);
  function pushToUser() {
    const activeC = ls.getItemInLs("activeCourse");

    const editedUserDetails = {
      ...props.user,
      courses: UpsertCourse(props.user.courses, activeC),
    };
    props.setActiveUser(editedUserDetails);
    props.editUser(editedUserDetails);
  }

  const finalizeTracker = (qId) => {
    sendQuestionToStore();
    props.setHistory({ ...props.history, questionId: qId });
    setChangeTracker(!changeTracker);
  };

  const startReview = () => {
    props.setMode("REVIEW_MODE");
    props.setHistory({ ...props.history, questionId: 0 });
    setChangeTracker(!changeTracker);
  };

  //main option

  const [mainOption, setMainOption] = useState();

  //const [show, setShow] = useState(false);
  useEffect(() => {
    if (!questionId) {
      console.log("yeeeeee!!!");
    }
    setPsgOverlay("");

    return () => {
      setHideParagraph(false);
    };
  }, [questionId]);

  const cc = (thisQuestionInStore && thisQuestionInStore.questionId) || null;

  useEffect(() => {
    console.log("this q in effect", thisQuestionInStore);
    setMainOption(thisQuestionInStore ? thisQuestionInStore.userAnswerId : 0);
    if (timeUp === true) {
      setShow(true);
      document.body.style.overflowY = "hidden";
      document.body.style.height = "100%";
      document.body.style.width = "100%";
      document.body.style.position = "fixed";   
    }

    return () => {
      setCountForAnswer(() => 0);
      setHardSetDisabled(() => false);
    };
  }, [timeUp, cc]);

  const [generatedQuestion, setGeneratedQuestion] = useState();

  const changeQuestion = () => {
    setGeneratedQuestion(
      () =>
        generatedQuestion && { ...generatedQuestion, shouldBeDisabled: true }
    );
  };

  const onOptionChange = (id) => {
    const disability = countForAnswer >= 1 || hardSetDisabled ? true : false;
    setGeneratedQuestion(
      track.questionBuilder(mainQuiz.id, id, mainQuiz.answerId, disability)
    );
    setMainOption(id);
  };
  props.type(props.mode);

  const incrementQuestion = () => {
    console.log(questionId, courses.length);
    if (questionId < courses.length) {
      finalizeTracker(questionId + 1);
    }
  };

  const previousQuestion = () => {
    if (questionId > 0) {
      finalizeTracker(questionId - 1);
    }
  };

  function sendQuestionToStore() {
    let g = generatedQuestion;
    const disability =
      countForAnswer >= 1 || (g && g.shouldBeDisabled) || hardSetDisabled
        ? true
        : false;
    const storeItem = ls.getItemInLs("activeCourse");

    if (!g) {
      if (!thisQuestionInStore) return;
      g = track.questionBuilder(
        thisQuestionInStore.questionId,
        thisQuestionInStore.userAnswerId,
        thisQuestionInStore.correctAnswerId,
        disability
      );
      console.log("inside g", g);
    }
    console.log(
      "hard set value",
      hardSetDisabled,
      countForAnswer,
      generatedQuestion
    );

    props.updateActiveCourse(g);

    console.log("This is the store", storeItem, activeCourse);
    const score = storeItem.question.reduce(
      (acc, cur) =>
        track.correctAnswer(cur.userAnswerId, cur.correctAnswerId)
          ? acc + 1
          : acc,
      0
    );
    console.log("your score is", score);
    props.updateScore(score);
    pushToUser();
  }

  console.log(generatedQuestion);
  //console.log("the active course is",activeCourse);
  console.log("main quiz", mainQuiz);

  console.log("Neville", mainQuiz && mainQuiz.hasVideo);

  const disabledButtons = (val) => {
    showDisable(val);
  };
  console.log(disable);

  const [decision, setDecision] = useState();

  const handleShow = () => {
    setDecision(true);
  };

  const handleClose = () => {
    setDecision(false);
  };

  const videoClose = () => {
    setShow(false);
    var objectVideo = document.getElementById("stop-video");
    objectVideo.pause();
  };
  const videoShow = (id) => {
    setShow(true);
    setDecision(false);
  };

  const [video, setVideo] = useState();

  const hoverVideo = () => {
    setVideo(true);
  };

  const closeVideo = () => {
    setVideo(false);
  };

  const [noVideo, setNoVideo] = useState();

  const removeVideo = () => {
    setNoVideo(true);
  };

  const addVideo = () => {
    setNoVideo(false);
  };

  const closeVue = (callback) => {};

  console.log(disable);

  const timeupReview = () => {
    props.setMode("REVIEW_MODE");
    props.setHistory({ path: "quiz", questionId: 1 });
    setShow(false);
    document.body.style.overflowY = "auto"
    document.body.style.height = "100%"
    document.body.style.width = "100%"
    document.body.style.position = "relative"
  };

  const handleClosePassage = () => {
    setShowPassage(false);
  };

  const handleCloseSection = () => {
    showSection == "section-hide"
      ? setShowSection("section-reset")
      : setShowSection("section-hide");
    psg == "d-none" ? setShowPsg("d-block") : setShowPsg("d-none");
    showPssg == "d-block" ? setShowPssg("d-none") : setShowPssg("d-block");
    showImage == "d-block" ? setShowImage("d-none") : setShowImage("d-block");
    //showSect == "d-none" ? setShowPssg("d-none") : setShowPssg("d-block");
    dispsg == "d-block" ? setDisPsg("d-none") : setDisPsg("d-block");
    psgOverlay == "" ? setPsgOverlay("psg-overlay") : setPsgOverlay("");
    disableSectBtn == "d-block"
      ? setDisableSectBtn("d-none")
      : setDisableSectBtn("d-block");
    dispImg == "d-block" ? setDisImg("d-none") : setDisImg("d-block");
    bg == "bg-transparent" ? setBg("bg-white") : setBg("bg-transparent");
    zIndex == "increase-zIndex"
      ? setZIndex("reduce-zIndex")
      : setZIndex("increase-zIndex");
  };

  const handleClosePassage_ = () => {
    showSection == "section-hide"
      ? setShowSection("section-reset")
      : setShowSection("section-hide");
    psg == "d-none" ? setShowPsg("d-block") : setShowPsg("d-none");
    //showPssg == "d-block" ? setShowSect("d-none") : setShowSect("d-block");
    showSect == "d-none" ? setShowSect("d-block") : setShowSect("d-none");
    dispsg == "d-block" ? setDisPsg("d-none") : setDisPsg("d-block");
    dispImg == "d-block" ? setDisImg("d-none") : setDisImg("d-block");
    psgOverlay == "" ? setPsgOverlay("psg-overlay") : setPsgOverlay("");
    disablePsgBtn == "d-block"
      ? setDisablePsgBtn("d-none")
      : setDisablePsgBtn("d-block");
    bg == "bg-transparent" ? setBg("bg-white") : setBg("bg-transparent");
    zIndex == "increase-zIndex"
      ? setZIndex("reduce-zIndex")
      : setZIndex("increase-zIndex");
  };

  const firsSectionCheck = () => {
    alert("hi");
    setShowSection("section-reset");
  };
  const showSectionFn = (section, img, passage) => {
    //alert(aval, img);
    if (section || img || passage) {
      return "col-lg-5 px-0";
    } else {
      return "col-lg-1";
    }
  };
  const controlQuestionFn = (section, img, passage) => {
    if (section || img || passage) {
      return "col-lg-6";
    } else {
      return "col-lg-11";
    }
  };

  const checkForSideImage = (string) => {
    if (string.includes("side-image")) {
      let start = string.indexOf("<img");
      let end = string.indexOf("helper visual") + 17;
      let spliced = string.slice(start, end);
      spliced = spliced.replace("d-none", "d-block");
      console.log("spliced stuff", spliced);
      //alert(spliced);
      return spliced;
    }
    return "";
  };

  const checkForSideImageAvailability = (string) => {
    if (string.includes("side-image")) {
      return true;
    }
    //alert(string);
    return false;
  };

  const addPaddingonAudioShow = (payload) => {
    if (payload) {
      setAudioPadding("pad-for-audio");
    } else {
      setAudioPadding("");
    }
  };
  const beginStudy = () => {
    const updatedUser = { ...props.user, hasTakenTest: true, totalScore: 30 };
    console.log("updated user", updatedUser);
    props.editUser(updatedUser);
    props.setActiveUser(updatedUser);
    props.setHistory({ path: "home" });
  };

  const TimeUp = () => {
    return (
      <div className={show ? "timeup" : "overlay-none"}>
        <div className="row downish">
          <div className="col-md-12 my-auto mx-auto text-center"></div>
          <div className="col-md-12">
            <div className="card centered my-auto mx-auto timeup-card">
              <h3 className="card-header no-margin-up text-center timeup-heading">
                TIME UP
              </h3>
              <div className="card-body blue-box box">
                <div className="approach text-center">
                  <h3 className="timeup-text">
                    We hope you enjoyed the lesson
                  </h3>
                  <h3 className="timeup-text">
                    Click "Review" below to view the result
                  </h3>
                </div>
              </div>
              <div className="col-md-12 text-center review-container">
                {props.mode === "SKILL_MODE" ? (
                  <button className="btn review-button" onClick={beginStudy}>
                    BEGIN STUDY
                  </button>
                ) : (
                  <button className="btn review-button" onClick={timeupReview}>
                    REVIEW
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Passage = (props) => {
    return (
      <div className={!showPassage ? "timeup" : "overlay-none"}>
        <div
          className="row downish"
          style={{ bottom: "15%", position: "relative" }}
        >
          <div className="col-md-12 my-auto mx-auto text-center">
            <img
              onClick={handleClosePassage}
              src="images/No.png"
              className="close my-auto mx-auto text-center button-shift"
            />
          </div>
          <div className="col-md-12">
            <div
              className="card centered my-auto mx-auto"
              style={{ width: "60rem", border: "0px", borderRadius: "15px" }}
            >
              <h3 className="card-header no-margin-up text-center timeup-heading">
                Passage
              </h3>
              <div className="card-body blue-box box">
                <div className="approach text-center">
                  <h3 className="timeup-text">{props.passage}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <React.Fragment>
      <div className="container-fluid quizContainer px-0">
        <div className="d-block container px__0">
          <div className={`${psgOverlay}`}></div>
          <TimeUp />
          {mainQuiz && (
            <div className={`row m-0 ${audioPadding}`}>
              {/* {mainQuiz.hasPassage && (
                <div>
                  <Passage passage={parse(mainQuiz.passage)} />
                </div>
              )} */}

              <div
                className={`${zIndex} ${controlQuestionFn(
                  mainQuiz.passage,
                  checkForSideImageAvailability(mainQuiz.question),
                  mainQuiz.section
                )} padding-left0`}
              >
                <div className="quizQuestion">
                  <ParserCondition
                    question={mainQuiz.question}
                    isMathjax={mainQuiz.isQuestionMathJax}
                  />
                </div>

                <div className="optionSection">
                  <form>
                    {mainQuiz.options.map((option, index) => (
                      <React.Fragment key={index}>
                        <label
                          className={`app-flex r-pos ${
                            props.mode === "REVIEW_MODE" ||
                            tempLockDown ||
                            (thisQuestionInStore &&
                              thisQuestionInStore.shouldBeDisabled)
                              ? "c-disabled"
                              : "c-pointer"
                          }`}
                          key={option.id}
                        >
                          <img
                            className="position-tick tick"
                            style={{
                              display:
                                (mainOption === option.id &&
                                  mainOption === mainQuiz.answerId &&
                                  props.mode === "REVIEW_MODE") ||
                                (mainOption !== option.id &&
                                  option.id === mainQuiz.answerId &&
                                  props.mode === "REVIEW_MODE")
                                  ? "block"
                                  : "none",
                            }}
                            src="images/tick.png"
                          />
                          <img
                            className="position-tick red-tick"
                            style={{
                              display:
                                mainOption === option.id &&
                                mainOption !== mainQuiz.answerId &&
                                props.mode === "REVIEW_MODE"
                                  ? "block"
                                  : "none",
                            }}
                            src="images/red.png"
                          />
                          {console.log("yelp", mainOption, option.id)}
                          <div className="option-radio">
                            {props.mode === "REVIEW_MODE" ||
                            tempLockDown ||
                            (thisQuestionInStore &&
                              thisQuestionInStore.shouldBeDisabled) ? (
                              <>
                                <RadioButton
                                  disabled="disabled"
                                  disabled="disabled"
                                  className={
                                    (mainOption === option.id &&
                                      mainOption === mainQuiz.answerId) ||
                                    (mainOption !== option.id &&
                                      option.id === mainQuiz.answerId)
                                      ? "c-green larger-radio"
                                      : mainOption === option.id &&
                                        mainOption !== mainQuiz.answerId
                                      ? "c-red larger-radio"
                                      : "larger-radio"
                                  }
                                  onChange={() => onOptionChange(option.id)}
                                  checked={
                                    mainOption === option.id ||
                                    (props.mode === "REVIEW_MODE" &&
                                      option.id === mainQuiz.answerId) ||
                                    (thisQuestionInStore &&
                                      thisQuestionInStore.shouldBeDisabled &&
                                      option.id === mainQuiz.answerId)
                                  }
                                  name={`radio${option.id}`}
                                />
                              </>
                            ) : (
                              <RadioButton
                                onChange={() => onOptionChange(option.id)}
                                className="larger-radio"
                                checked={mainOption === option.id}
                                name={`radio${option.id}`}
                              />
                            )}
                          </div>

                          <div className="option-text">
                            <ParserCondition
                              question={option.content}
                              isMathjax={option.isMathJax}
                            />
                          </div>
                        </label>
                      </React.Fragment>
                    ))}
                  </form>
                </div>
                <SideMenu
                  mode={props.mode}
                  setDecision={setDecision}
                  questions={courses.length}
                  questionId={questionId}
                  finalizeTracker={(qId) => finalizeTracker(qId)}
                  history={props.history}
                  setHistory={props.setHistory}
                  disable={disabledButtons}
                  resetTempLockDown={() => setTempLockDown(false)}
                  setHideExtras={(data) => setHideExtras(data)}
                />
                <>
                  <div className="row main-width">
                    <div className="col-md-12">
                      {props.mode !== "LEARN_MODE" ? (
                        <>
                          <button
                            className="previous-button"
                            style={{
                              display: questionId === 0 ? "none" : "inline",
                            }}
                            onClick={previousQuestion}
                          >
                            PREVIOUS
                          </button>
                          {questionId === courses.length - 1 ? (
                            <Overlay
                              mode={props.mode}
                              user={props.user}
                              editUser={props.editUser}
                              setActiveUser={props.setActiveUser}
                              activeCourse={activeCourse}
                              className="next-button"
                              history={props.history}
                              totalQuestions={courses.length}
                              sendQuestionToStore={sendQuestionToStore}
                              startReview={startReview}
                              setMode={props.setMode}
                              mode={props.mode}
                              setHistory={props.setHistory}
                            />
                          ) : (
                            <button
                              className="next-button"
                              onClick={incrementQuestion}
                            >
                              NEXT QUESTION
                            </button>
                          )}
                        </>
                      ) : (
                        <Decision
                          paddup={addPaddingonAudioShow}
                          disable={disabledButtons}
                          setHideParagraph={setHideParagraph}
                          explanation={mainQuiz.answerUrl}
                          setHistory={props.setHistory}
                          finalizeTracker={(qId) => finalizeTracker(qId)}
                          isLast={questionId === courses.length - 1}
                          id={id}
                          questionId={questionId}
                          close={handleClose}
                          show={handleShow}
                          qInStore={thisQuestionInStore}
                          setTempLockDown={setTempLockDown}
                          hideExtras={hideExtras}
                          setHideExtras={(data) => setHideExtras(data)}
                          sendQuestionToStore={sendQuestionToStore}
                          setCountForAnswer={() =>
                            setCountForAnswer(() => countForAnswer + 1)
                          }
                          changeQuestion={changeQuestion}
                          setHardSetDisabled={setHardSetDisabled}
                          quizObj={{
                            userId: mainOption,
                            correctId: mainQuiz.answerId,
                          }}
                          removeVideo={removeVideo}
                          addVideo={addVideo}
                        />
                      )}
                    </div>

                    <div className="col-md-3">
                      {props.mode === "LEARN_MODE" && (
                        <div className="row">
                          <img
                            className={video ? "showvideo" : "offvideo"}
                            src="images/video.png"
                          />
                          <div
                            id="point"
                            onClick={videoShow}
                            className={
                              mainQuiz.hasVideo === false ? "decision-none" : ""
                            }
                          >
                            <img
                              className={noVideo ? "offvideo" : "media"}
                              onMouseOver={hoverVideo}
                              onMouseOut={closeVideo}
                              src="images/play.png"
                            ></img>
                          </div>

                          <div
                            className={decision ? "decision" : "decision-none"}
                          >
                            <Audio src={mainQuiz.audioUrl} />
                          </div>

                          <div id="vid">
                            <div
                              className={show ? "overlayvid" : "overlay-none"}
                            >
                              <video
                                id="stop-video"
                                style={{ height: 450, marginBottom: 60 }}
                                poster="images/vidThumbnail.png"
                                controls
                              >
                                <source src="video/video.mp4" />
                              </video>
                              <div className="video-resize">
                                <img
                                  onClick={videoClose}
                                  src="images/No.png"
                                  className="video-image-resize"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              </div>
              <div
                className={`${showSectionFn(
                  mainQuiz.passage,
                  checkForSideImageAvailability(mainQuiz.question),
                  mainQuiz.section
                )} text-center`}
              >
                {/* <button className="passage_view">View Passage</button> */}
                {checkForSideImage(mainQuiz.question) && !hideParagraph && (
                  <div className={`${showSection} section-bottom`}>
                    <div className={`${bg} containerr`}>
                      {showSection == "section-reset" ? (
                        <div className="button-wrapper">
                          <div className="button--wrapper">
                            <button
                              className={`passage_view ${showImage}`}
                              onClick={handleCloseSection}
                              // onClick={() => setShowPassage(false)}
                            >
                              close Image
                            </button>
                            <button
                              className={`passage_view ${showSect}`}
                              onClick={handleClosePassage_}
                              // onClick={() => setShowPassage(false)}
                            >
                              Close Section
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="button-wrapper">
                          <div className="button--wrapper">
                            <button
                              className="section_view"
                              onClick={handleCloseSection}
                            >
                              show Image
                            </button>

                            {mainQuiz.section && (
                              <button
                                className={` section_view`}
                                onClick={handleClosePassage_}
                              >
                                Show Section
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                      <div className={`${dispImg} wrapper-img`}>
                        <div className={`${showImage}`}>
                          <ParserCondition
                            question={checkForSideImage(mainQuiz.question)}
                            isMathjax={false}
                          />
                        </div>

                        <div className={`${showSect}`}>
                          <ParserCondition
                            question={mainQuiz.section}
                            isMathjax={false}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <button className="section_view">View Section</button>
                    <button className="passage_view">View Passage</button> */}
                  </div>
                )}

                {((mainQuiz.passage && mainQuiz.section) || mainQuiz.section) &&
                  !hideParagraph && (
                    <div className={`${showSection} section-bottom`}>
                      <div className={`${bg} containerr`}>
                        {/* {mainQuiz.hasPassage && (
                          <button
                            className={` ${psg}`}
                            onClick={handleCloseSection}
                            // onClick={() => setShowPassage(false)}
                          >
                            View Passage
                          </button>
                        )} */}
                        {showSection == "section-reset" ? (
                          <div className="button-wrapper_">
                            <div className="button--wrapper">
                              {mainQuiz.passage && (
                                <button
                                  className={`passage_view ${showPssg}`}
                                  onClick={handleCloseSection}
                                  // onClick={() => setShowPassage(false)}
                                >
                                  Close Passage
                                </button>
                              )}
                              {mainQuiz.section && (
                                <button
                                  className={`passage_view ${showSect}`}
                                  onClick={handleClosePassage_}
                                  // onClick={() => setShowPassage(false)}
                                >
                                  Close Section
                                </button>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="button-wrapper_">
                            <div className="button--wrapper">
                              {mainQuiz.passage && (
                                <button
                                  style={{ marginBottom: 10 }}
                                  className={`section_view`}
                                  onClick={handleCloseSection}
                                >
                                  Read Passage
                                </button>
                              )}

                              <button
                                className={` section_view`}
                                onClick={handleClosePassage_}
                              >
                                Show Section
                              </button>
                            </div>
                          </div>
                        )}

                        <div className={`${dispsg} wrapper-psg`}>
                          <div className={`${showSect}`}>
                            <ParserCondition
                              question={mainQuiz.section}
                              isMathjax={false}
                            />
                          </div>

                          <div className={`${showPssg}`}>
                            <ParserCondition
                              question={mainQuiz.passage}
                              isMathjax={false}
                            />
                          </div>
                        </div>

                        {/* This is where the content of the section is but i wish
                      lorem ipus lorem ipsum could be the way for us to know hwo
                      we are suposed to do the things that we are meant to do
                      with our times in the same way that they expect all of us
                      to be indirectily partial about the fucking governmnet
                      that we have found ourselves in but the aplications of all
                      that we leant in */}
                      </div>
                      {/* <button className="section_view">View Section</button>
                    <button className="passage_view">View Passage</button> */}
                    </div>
                  )}
              </div>
              {/* <div className="col-12 padding-left0">
                <div className="optionSection">
                  <form>
                    {mainQuiz.options.map((option, index) => (
                      <React.Fragment key={index}>
                        <img
                          className="position-tick tick"
                          style={{
                            display:
                              (mainOption === option.id &&
                                mainOption === mainQuiz.answerId &&
                                props.mode === "REVIEW_MODE") ||
                              (mainOption !== option.id &&
                                option.id === mainQuiz.answerId &&
                                props.mode === "REVIEW_MODE")
                                ? "block"
                                : "none"
                          }}
                          src="images/tick.png"
                        />
                        <img
                          className="position-tick red-tick"
                          style={{
                            display:
                              mainOption === option.id &&
                              mainOption !== mainQuiz.answerId &&
                              props.mode === "REVIEW_MODE"
                                ? "block"
                                : "none"
                          }}
                          src="images/red.png"
                        />
                        <label className="contain" key={option.id}>
                          <span className="question">
                            <ParserCondition
                              question={option.content}
                              isMathjax={option.isMathjax}
                            />
                          </span>
                          {props.mode === "REVIEW_MODE" ||
                          tempLockDown ||
                          (thisQuestionInStore &&
                            thisQuestionInStore.shouldBeDisabled) ? (
                            <input
                              disabled="disabled"
                              onChange={() => onOptionChange(option.id)}
                              checked={mainOption === option.id}
                              type="radio"
                              name={"radio"}
                            />
                          ) : (
                            <input
                              onChange={() => onOptionChange(option.id)}
                              checked={mainOption === option.id}
                              type="radio"
                              name="radio"
                            />
                          )}

                          <span className="checkmark"></span>
                        </label>
                      </React.Fragment>
                    ))}
                  </form>
                </div>
              </div> */}
              <div className="d-inline quiz-side"></div>

              {/* <SideMenu
                questions={courses.length}
                questionId={questionId}
                finalizeTracker={qId => finalizeTracker(qId)}
                history={props.history}
                setHistory={props.setHistory}
                disable={disabledButtons}
              />
              <>
                <div className="row main-width">
                  <div className="col-md-9">
                    {props.mode !== "LEARN_MODE" ? (
                      <>
                        <button
                          className="previous-button"
                          style={{
                            display: questionId === 1 ? "none" : "inline"
                          }}
                          onClick={previousQuestion}
                        >
                          PREVIOUS
                        </button>
                        {questionId === courses.length ? (
                          <Overlay
                            mode={props.mode}
                            user={props.user}
                            editUser={props.editUser}
                            setActiveUser={props.setActiveUser}
                            activeCourse={activeCourse}
                            className="next-button"
                            history={props.history}
                            totalQuestions={courses.length}
                            sendQuestionToStore={sendQuestionToStore}
                            startReview={startReview}
                            setMode={props.setMode}
                            mode={props.mode}
                            setHistory={props.setHistory}
                          />
                        ) : (
                          <button
                            className="next-button"
                            onClick={incrementQuestion}
                          >
                            NEXT QUESTION
                          </button>
                        )}
                      </>
                    ) : (
                      <Decision
                        disable={disabledButtons}
                        explanation={mainQuiz.answerUrl}
                        setHistory={props.setHistory}
                        finalizeTracker={qId => finalizeTracker(qId)}
                        isLast={questionId === courses.length}
                        id={id}
                        questionId={questionId}
                        close={handleClose}
                        show={handleShow}
                        qInStore={thisQuestionInStore}
                        setTempLockDown={setTempLockDown}
                        sendQuestionToStore={sendQuestionToStore}
                        setCountForAnswer={() =>
                          setCountForAnswer(() => countForAnswer + 1)
                        }
                        changeQuestion={changeQuestion}
                        setHardSetDisabled={setHardSetDisabled}
                        quizObj={{
                          userId: mainOption,
                          correctId: mainQuiz.answerId
                        }}
                        removeVideo={removeVideo}
                        addVideo={addVideo}
                      />
                    )}
                  </div>

                  <div className="col-md-3">
                    {props.mode === "LEARN_MODE" && (
                      <div className="row">
                        <img
                          className={video ? "showvideo" : "offvideo"}
                          src="images/video.png"
                        />
                        <div
                          id="point"
                          onClick={videoShow}
                          className={
                            mainQuiz.hasVideo === false ? "decision-none" : ""
                          }
                        >
                          <img
                            className={noVideo ? "offvideo" : "media"}
                            onMouseOver={hoverVideo}
                            onMouseOut={closeVideo}
                            src="images/play.png"
                          ></img>
                        </div>

                        <div
                          className={decision ? "decision" : "decision-none"}
                        >
                          <Audio src={mainQuiz.audioUrl} />
                        </div>

                        <div id="vid">
                          <div className={show ? "overlayvid" : "overlay-none"}>
                            <video
                              id="stop-video"
                              style={{ height: 450, marginBottom: 60 }}
                              poster="images/vidThumbnail.png"
                              controls
                            >
                              <source src="video/video.mp4" />
                            </video>
                            <div className="video-resize">
                              <img
                                onClick={videoClose}
                                src="images/No.png"
                                className="video-image-resize"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </> */}

              {/* {mainQuiz.isSectioned && (
                <div className={`${showSection} section-bottom`}>
                  <div className="container">
                    {mainQuiz.hasPassage && (
                      <button
                        className={`viewpassage-btn ${psg}`}
                        onClick={() => setShowPassage(false)}
                      >
                        View Passage
                      </button>
                    )}
                    {showSection == "section-reset" ? (
                      <img
                        className="viewsection-btn"
                        src="images/close.png"
                        onClick={handleCloseSection}
                        alt="close section"
                      />
                    ) : (
                      <button
                        className="viewsection-btn1"
                        onClick={handleCloseSection}
                      >
                        --> Show Section
                      </button>
                    )}
                    This is where the content of the section is but i wish lorem
                    ipus lorem ipsum could be the way for us to know hwo we are
                    suposed to do the things that we are meant to do with our
                    times in the same way that they expect all of us to be
                    indirectily partial about the fucking governmnet that we
                    have found ourselves in but the aplications of all that we
                    leant in
                  </div>
                </div>
              )} */}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Quiz;

export const RadioButton = ({
  onChange,
  className,
  checked,
  name,
  disabled,
}) => {
  const radioProps = { onChange, checked, disabled, name };
  return (
    <input
      type="radio"
      className={`self-style r-style ${className ? className : ""}`}
      {...radioProps}
    />
  );
};
