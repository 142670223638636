import { connect } from "react-redux";
import { addCourse, editCourse } from "../../creators/singleCourseCreator";
import { setActiveUser } from "../../creators/activeUserCreator";
import { editUser } from "../../creators/userCreator";
import {
  updateActiveCourse,
  updateScore
} from "../../creators/activeCourseCreator";
import Quiz from "./Quiz";
import { setHistory } from "../../creators/historyCreator";
import { startReview } from "../../creators/reviewCreator";
import { setMode } from "../../creators/modeCreator";

//remove courses when connecting to api
const mapStateToProps = ({
  singleCourse,
  activeCourse,
  courses,
  mode,
  history,
  user,
  time,
  timeUp,
  review
}) => {
  return {
    singleCourse,
    activeCourse,
    courses,
    mode,
    history,
    user,
    time,
    timeUp,
    review
  };
};

const ConnectedQuiz = connect(mapStateToProps, {
  addCourse,
  updateScore,
  editUser,
  setActiveUser,
  editCourse,
  setHistory,
  updateActiveCourse, 
  startReview, 
  setMode
})(Quiz);

export default ConnectedQuiz;
