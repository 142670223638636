// JavaScript source code
const startNewCourse = (userId) => {
    return {
        userId,
        courses: [],
    }
}

const correctAnswer = (userAnswerId, correctAnswerId) => userAnswerId === correctAnswerId;
const courseBuilder = (mainId, courseId, mode) => ({mainId, courseId, mode, question: [], date: new Date(), isComplete: false})
const questionBuilder = (questionId, userAnswerId, correctAnswerId, shouldBeDisabled) => ({questionId, userAnswerId, correctAnswerId, shouldBeDisabled})

export default {
    startNewCourse,
    correctAnswer,
    courseBuilder,
    questionBuilder
};