export const setActiveCourse = (data) => {
    return { type: "SET_ACTIVE_COURSE", data }
}
export const persistActiveCourse = (data) => {
    return { type: "PERSIST_ACTIVE_COURSE", data }
}
export const updateScore = (data) => {
    return { type: "UPDATE_SCORE", data }
}
export const updateActiveCourse = (data) => {
    return { type: "UPDATE_ACTIVE_COURSE", data }
}

