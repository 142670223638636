import ls from '../sideEffects/ourLocalStorage'
const userCoursesReducer = (state = [], action) => {
    switch (action.type) {
        case "SET_USER_COURSES":
            const newData = state.concat(action.data);
            ls.persistItemInLS("userCourses", newData, 3)
            return newData;
        case "PERSIST_USER_COURSES":
            return action.data;
        case "EDIT_USER_COURSES":
            const updatedData = state.map(uc => uc.id === action.data.id ? action.data : uc);
            ls.persistItemInLS("userCourses", updatedData, 3)
            return updatedData
        default:
            return state;
    }
}

export default userCoursesReducer;