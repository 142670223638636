import { connect } from 'react-redux';
import { isBusy } from '../../../creators/loaderCreator';
import { persistUser } from '../../../creators/userCreator';
import { setActiveUser } from '../../../creators/activeUserCreator';
import { setHistory } from '../../../creators/historyCreator';
import Login from './Login';

const mapStateToProps = ({ users, user, loading, history }) => {
    return { loading, users, user, history };
}

const ConnectedLogin = connect(mapStateToProps, { isBusy, setActiveUser, setHistory, persistUser })(Login)

export default ConnectedLogin