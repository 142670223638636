import { connect } from "react-redux";
import { isBusy } from "../../../creators/loaderCreator";
import { setUser, persistUser } from "../../../creators/userCreator";
import { setActiveUser } from "../../../creators/activeUserCreator";
import { setHistory } from "../../../creators/historyCreator";
import Register from "./Register";

const mapStateToProps = ({ user, users, loading, history }) => {
  return { loading, user, users, history };
};

const ConnectedRegister = connect(mapStateToProps, {
  setActiveUser,
  setUser,
  isBusy,
  setHistory,
  persistUser
})(Register);

export default ConnectedRegister;
