import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

function range(start, end) {
  if (start === end) return [start];
  return [start, ...range(start + 1, end)];
}

export default function SideMenu(props) {
  const appHistory = useHistory();
  const {pathname} = useLocation();
  console.log(props.questions);
  const finalize = (id) => {
    props.resetTempLockDown();
    props.setHideExtras(false);
    props.finalizeTracker(id);
    props.setDecision(false);
    closeNav();
  };

  const [close, setClose] = useState(true);

  const closeNav = () => {
    setClose(true);
    document.body.style.overflowY = "auto";
    document.body.style.height = "100%";
    document.body.style.width = "100%";
    document.body.style.position = "relative";
  };

  const openNav = () => {
    setClose(false);
    document.body.style.overflowY = "hidden";
    document.body.style.height = "100%";
    document.body.style.width = "100%";
    document.body.style.position = "fixed";
  };
  const [disabled] = useState(false);

  const quitQuiz = () => {
    props.setHistory({
      path: "courses",
      id: null,
      search: false,
    })
    appHistory.push('/courses')
    document.body.style.overflow = 'auto';
    document.body.style.height = '100%';
    document.body.style.width = '100%';
    document.body.style.position = 'relative';
  }

  return (
    <>
      <div onClick={openNav} className="row numbering">
        Question
        <span className="space">{`${props.questionId + 1}/${
          props.questions
        }`}</span>
        <img src="images/caret.svg" />
      </div>
      <div
        id="mySidenav"
        className={
          close ? "mobile-close sidenav side-bar" : "sidenav side-bar "
        }
      >
        <div className="row row-menu">
          <div className="col-md-12 col-xl-12 col-12 question">
            <p className="style-question">Questions</p>
          </div>

          <div className="col-md-6 button-close">
            <div className="closebtn">
              <img
                className="close-bar"
                src="images/close.svg"
                onClick={closeNav}
              />
            </div>
          </div>
        </div>

        {range(1, props.questions).map((number) => (
          <a
            onClick={(id) => finalize(number - 1)}
            key={number}
            className="c-pointer questionTag"
            id={props.questionId === number - 1 ? "active-link" : "link"}
          >
            Question {number}
          </a>
        ))}
          {props.mode !== "SKILL_MODE" && (
        <>
          <button
              onClick={quitQuiz}
              className="c-pointer quit"
            >
              <div>

                <img src="images/logout.svg" />
                <span className="quit-quiz ">Quit Quiz</span>
                </div>
            </button>
            

              
          </>

        )}
        <button className="quit-bg" ></button>

      </div>

    </>
  );
}
