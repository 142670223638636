import React, { useState, useEffect } from "react";
import Decision from "./Decision";
import ls from "../../sideEffects/ourLocalStorage";
import track from "../../sideEffects/apis/courseTracker";
import FooterHome from "../../Footer/FooterHome";
import { coursesData } from "../Data/Data";
import { CourseNavigator } from "../Video/StudyVideo";
import ConnectedSecondSearchBar from "../Navigation/ToRightOfNav/Searchbar/ConnectedSecondSearch";
import { RadioButton } from "../QuizProper/Quiz";
import { useHistory } from "react-router-dom";

const learnModeCss = {
  top: "127px",
};

const timeModeCss = {
  top: "160px",
};
const freeFormCss = {
  top: "240px",
};

const CoursesObj = (props) => {
  const appHistory = useHistory();
  console.log("test data in courses", props.testData, props.id);
  props.type("SEARCH_BAR");
  const [show, setShow] = useState(false);
  const [mode, setMode] = useState();
  const [specificCourse, setSpecificCourse] = useState();

  const handleClose = () => {
    document.getElementById("home").style.overflowY = "auto";
    setShow(false);
  };
  const handleShow = (id) => {
    setSpecificCourse(() => id);
    document.getElementById("home").style.overflowY = "hidden";
    setShow(true);
  };

  //Update logic
  let allCourses = [];
  console.log("search is", props.search, props.testData);
  if (props.search) {
    allCourses = props.testData.filter((course) =>
      props.id.some((i) => i === course.id)
    );
  } else {
    if (!props.id) {
      console.log("no props", props.id);
      allCourses = props.testData && [props.testData[0]];
    } else {
      console.log("id wierd", props.id);
      allCourses =
        props.testData &&
        props.testData.filter((course) => course.id === props.id);
      console.log(
        "some wierd",
        allCourses,
        props.testData,
        props.testData && [props.testData[0]]
      );
      if (!allCourses || (allCourses && !allCourses.length)) {
        console.log("wierd ss");
        allCourses = props.testData && [props.testData[0]];
        console.log("wierd", allCourses);
      }
    }
  }

  const { courses } = props.user;
  const coursesWithoutSkill = courses.filter(
    (course) => course.mode !== "SKILL_MODE"
  );
  console.log({ coursesWithoutSkill });
  const otherCourses = [];
  allCourses &&
    allCourses.forEach((c) => {
      console.log(c);
      c.tests.forEach((t) => {
        //console.log(t, c);
        const available = coursesWithoutSkill.find((cc) => {
          console.log(
            "comaparer",
            cc.courseId,
            cc.mainId,
            t.courseId,
            t.id,
            c.id
          );
          return cc.mainId === c.id && cc.courseId === t.id;
        });
        console.log("ava", available);
        if (available) {
          console.log("IN HERE");
          otherCourses.push({
            ...t,
            isComplete:
              t.quizes && t.quizes.length === available.question.length,
            mode: available.mode,
            active: {
              courseId: available.courseId,
              mainId: available.mainId,
              mode: available.mode,
              isComplete: available.isComplete,
              question: available.question,
            },
            completed: `${
              t.quizes && t.quizes.length > 0
                ? ((available.question.length / t.quizes.length) * 100).toFixed(
                    2
                  )
                : 0
            }`,
          });
        }
      });
    });
  console.log("hello there", otherCourses);
  const correctStyle = () => {
    switch (mode) {
      case "LEARN_MODE":
        return learnModeCss;
      case "TIME_MODE":
        return timeModeCss;
      case "FREE_FORM":
        return freeFormCss;
    }
  };

  const showMode = (type) => {
    setMode(type);
  };

  const setGlobalMode = (id, quiz) => {
    console.log("our quiz", quiz);
    if (mode) {
      if (mode === "TIME_MODE" && !props.time) return;
      props.setMode(mode);
      ls.persistItemInLS("mode", mode, 2);
      props.setHistory({
        path: "quiz",
        mainId: id,
        id: specificCourse,
        questionId: 0,
      });
      props.setCourses(quiz.quizes);
      props.setActiveCourse(track.courseBuilder(id, specificCourse, mode));
      appHistory.push("/quiz");
    }
  };

  const startPreviouslyTaken = (quizzes, active, click_mode, isComplete) => {
    if (!isComplete) {
      props.setCourses(quizzes);
      if (click_mode === "TIME_MODE") {
        props.timeValue(props.time);
      }
      props.setMode(click_mode);
      props.setActiveCourse(active);
      appHistory.push("/quiz");
    }
  };

  console.log("courses here", courses);
  const availableCourses = [
    "MATHEMATICS",
    "PHYSICS",
    "USE OF ENGLISH",
    "GOVERNMENT",
    "ECONOMICS",
    "ACCOUNT",
    "LITERATURE",
    "GEOGRAPHY",
    "CRS",
    "BIOLOGY",
  ];
  return (
    <React.Fragment>
      <section id="home" className="container-fluid">
        <div className="video-section">
          <div className="row">
            <div className="container px-0">
              <div className="col-md-12 col-sm-12 col-xs-12 px-0 ">
                <h1 className=" page-title">Choose your preferred course</h1>
              </div>
            </div>

            <CourseNavigator
              setHistory={props.setHistory}
              type="course"
              courseData={coursesData}
            />
            <div className="container contain-div px-0">
              <ConnectedSecondSearchBar />
            </div>

            {allCourses &&
              allCourses.length > 0 &&
              allCourses.map((selectedCourse) => (
                <React.Fragment key={selectedCourse.id}>
                  <div className={show ? "overlay" : "overlay-none"}>
                    <div className="row downish">
                      <div className="col-md-12 new-pad my-auto mx-auto text-center">
                        <img
                          onClick={handleClose}
                          src="images/No.png"
                          className="close my-auto mx-auto text-center button-shift"
                        />
                      </div>
                      <div className="col-md-12">
                        <div className="card centered my-auto mx-auto branded">
                          <h5 className="card-header no-margin-up text-center">
                            Please select an approach
                          </h5>
                          <div className="card-body blue-box box-overlay">
                            <h1 className="approach">Learning Approach</h1>
                            <label className="app-flex c-pointer">
                              <div className="option-radio">
                                <RadioButton
                                  onChange={() => showMode("LEARN_MODE")}
                                  className="smaller-radio"
                                  name="learn_type"
                                />
                              </div>
                              <div className="option-text-course">
                                <span>Learning Mode</span>
                              </div>
                            </label>
                            <h1 className="approach">Test Approach</h1>
                            <label className="app-flex c-pointer">
                              <div className="option-radio">
                                <RadioButton
                                  onChange={() => showMode("TIME_MODE")}
                                  className="smaller-radio"
                                  name="learn_type"
                                />
                              </div>
                              <div className="option-text-course">
                                <span>Time Mode</span>
                              </div>
                            </label>
                            <label className="app-flex c-pointer">
                              <div className="option-radio">
                                <RadioButton
                                  onChange={() => showMode("FREE_FORM")}
                                  className="smaller-radio"
                                  name="learn_type"
                                />
                              </div>
                              <div className="option-text-course">
                                <span>Free Form Mode</span>
                              </div>
                            </label>
                          </div>
                          <Decision type={mode} style={correctStyle()} />
                          <div className="col-md-12 centralized">
                            <button
                              className="btn-get-started scrollto blackish"
                              onClick={() =>
                                setGlobalMode(
                                  selectedCourse.id,
                                  selectedCourse.tests.find(
                                    (quiz) => quiz.id == specificCourse
                                  )
                                )
                              }
                            >
                              SUBMIT
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <header className="home container px-0">
                    <h3>{selectedCourse.name}</h3>
                    <img
                      className="img-fluid swipe_left"
                      src="images/Swipe.svg"
                      alt="swipe-left"
                    />
                  </header>
                  <div className="display-card scroll-bar container px-0">
                    {selectedCourse.tests.map((datum, key) => (
                      <div
                        key={datum.id}
                        className="col-md-6 col-xl-3 col-12 no-left-padding style-bar "
                      >
                        <div className="card r-pos shadowy">
                          <div className="card-body box">
                            <h5 className="card-title card-down ">
                              {" "}
                              {`Study pack ${key + 1}`}
                            </h5>
                            <h1 className="pastq mt-3">
                              Study Pack <br />
                              on {selectedCourse.name}{" "}
                            </h1>
                            <h6 className="card-subtitle mb-2 text-muted card-down ">
                              {datum.text}
                            </h6>
                            <p className="card-text card-down ">
                              {datum.shortDescription}
                            </p>
                            <div className="row covering">
                              <div className="col-md-6 col-xs--6 middle-question">
                                <p className="new-font">Question</p>

                                <a
                                  href="#"
                                  className="card-link card-down duration "
                                >
                                  {datum.questionNo}
                                </a>
                              </div>
                              <div className="col-md-6 col-xs--6">
                                <p className="new-font">Duration</p>

                                <a
                                  href="#"
                                  className="card-link card-down duration "
                                >
                                  {datum.duration}min
                                </a>
                              </div>
                            </div>

                            <button
                              key={datum.id}
                              className="btn btn-primary new-button"
                              onClick={() => handleShow(datum.id)}
                            >
                              {" "}
                              Start Test
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <>
                    <div className="container px-0">
                      <h3 className="taken container px-0 mx-0">
                        Taken Previously
                      </h3>
                      <div className="display-card scroll-bar">
                        {otherCourses.map((datum, key) => (
                          <div
                            key={datum.id}
                            onClick={() =>
                              startPreviouslyTaken(
                                datum.quizes,
                                datum.active,
                                datum.mode,
                                datum.isComplete
                              )
                            }
                            className={`col-md-6 col-xl-3 col-12 no-left-padding ${
                              !datum.isComplete ? "c-pointer" : ""
                            } style-bar`}
                          >
                            {console.log({ datum })}
                            <div className="card shadowy">
                              <div className="card-body card-hover box">
                                <h5 className="card-title card-down ">
                                  {" "}
                                  {`Study pack ${key + 1}`}
                                </h5>
                                <h1 className="pastq mt-3">
                                  Study Pack <br />
                                  on {selectedCourse.name}{" "}
                                </h1>
                                <h6 className="card-subtitle mb-2 text-muted card-down ">
                                  {datum.text}
                                </h6>
                                <p className="card-text card-down ">
                                  {datum.shortDescription}
                                </p>
                                <div className="row covering">
                                  <div className="col-md-6 col-xs--6 middle-question">
                                    <div className="row">
                                      <p className="new-font">Question</p>
                                    </div>
                                    <a
                                      href="#"
                                      className="card-link card-down duration "
                                    >
                                      {datum.questionNo}
                                    </a>
                                  </div>
                                  <div className="col-md-6 col-xs--6 leftish">
                                    <div className="row">
                                      <p className="new-font">Duration</p>
                                    </div>
                                    <a
                                      href="#"
                                      className="card-link card-down duration "
                                    >
                                      {datum.duration}min
                                    </a>
                                  </div>
                                </div>
                                <div>
                                  <p className="completion">
                                    <span className="percentage">
                                      {datum.completed}%
                                    </span>
                                    <span> completed</span>
                                  </p>
                                  <div
                                    style={{
                                      width: "100%",
                                      backgroundColor: "#F4F4F4",
                                      borderRadius: 20,
                                      height: 5.96,
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: `${datum.completed}%`,
                                        backgroundColor: "#10A3FF",
                                        borderRadius: 20,
                                        height: 5.96,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                </React.Fragment>
              ))}
          </div>
        </div>
        <FooterHome />
      </section>
    </React.Fragment>
  );
};

export default CoursesObj;
