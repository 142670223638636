import React, { useState } from "react";
import AccordionTest from "./AccordionTest";
import AccordionVideo from "./AccordionVideo";
import Togglable from "./Togglable";
import TogglableItem from "./TogglableItem";
import FirstLevel from "./FirstLevel";
import SecondLevel from "./SecondLevel";
import { coursesData } from "../Data/Data";


const marginCss = {
	marginLeft: "-175px"
};

const noMarginCss = {
	marginLeft: 0
};

const SideNav = props => {
	const onLogoutOut = () => {
		props.deleteActiveUser();
		props.setHistory("login");
	};
	return (
		<div className="sidebar" style={props.hamburger ? noMarginCss : marginCss}>
			<ul className="sidebar-nav">
				<li className="text-center">
					{!props.hamburger ? (
						" "
					) : (
						<div className="selected-profile">
							<img
								src={
									props.user.sex === "Male"
										? "images/maleAvatar.svg"
										: "images/femaleAvatar.svg"
								}
								alt={`${props.user.firstName}'s avatar icon`}
							/>
							{props.hamburger ? (
								" "
							) : (
								<img
									src={
										props.user.sex === "Male"
											? "images/male.png"
											: "images/female.png"
									}
									alt={`${props.user.firstName}'s avatar icon`}
									alt="user profile picture"
									style={{ float: "right", width: "25%" }}
								/>
							)}
							<div>
								<h5 className="text-uppercase">
									{props.user &&
										`${props.user.firstName} ${props.user.surName}`}
								</h5>
								<h6>Student</h6>
							</div>
						</div>
					)}

					{props.hamburger ? (
						" "
					) : (
						<div>
							<img
								src={
									props.user.sex === "Male"
										? "images/male.png"
										: "images/female.png"
								}
								alt="user profile picture"
								style={{
									float: "right",
									width: "25%",
									right: "3px",
									position: "relative"
								}}
							/>
							<img
								src="images/icon-profile.png"
								alt="user profile picture"
								style={{ position: "absolute", top: "140px", right: "25px" }}
							/>{" "}
						</div>
					)}
				</li>

				<li>
					<div className={props.hamburger ? "d-block" : "d-none"}>
						{" "}
						<ul id="Video" className="accordion">
							<li>
								<Togglable
									render={() => (
										<TogglableItem src="images/play-button.png" name="Videos" />
									)}
								>
									<ul className="submenu">
										<li>
											<div className="dropdown">
												<Togglable
													render={() => (
														<FirstLevel name="Junior Secondary School" />
													)}
												>
													<SecondLevel
														setHistory={props.setHistory}
														juniorSubjects={
															props.videos.length ? props.videos[0] : null
														}
													/>
												</Togglable>
											</div>
										</li>
										<li>
											<div className="dropdown">
												<Togglable
													render={() => (
														<FirstLevel name="Senior Secondary School" />
													)}
												>
													<SecondLevel
														setHistory={props.setHistory}
														juniorSubjects={
															props.videos.length ? props.videos[1] : null
														}
													/>
												</Togglable>
											</div>
										</li>
									</ul>
								</Togglable>
							</li>
						</ul>
					</div>
					{props.hamburger ? (
						""
					) : (
						<div>
							<img
								src="images/play-button.png"
								style={{
									float: "right",
									position: "relative",
									display: "block",
									top: "135px",
									left: "39px"
								}}
							/>
						</div>
					)}
				</li>
				<li>
					<div className={props.hamburger ? "d-block" : "d-none"}>
						<ul id="Video" className="accordion">
							<li>
								<Togglable
									render={() => (
										<TogglableItem
											src="images/study.png"
											name="Test Approach"
										/>
									)}
								>
									<ul className="submenu">
										<li>
											<div className="dropdown">
												{coursesData.map(course => {
													return (
														<FirstLevel
															name={course.name}
															isClickable={true}
															setHistory={props.setHistory}
															key={course.id}
															id={course.id}
														/>
													);
												})}
											</div>
										</li>
									</ul>
								</Togglable>
							</li>
						</ul>
					</div>
					{props.hamburger ? (
						""
					) : (
						<div>
							<img
								src="images/study.png"
								style={{
									float: "right",
									position: "relative",
									display: "block",
									top: "195px",
									left: "59px"
								}}
							/>
						</div>
					)}
				</li>
				<li></li>
				<li onClick={onLogoutOut} className="c-pointer log-out">
					<div style={{ paddingLeft: 28 }}>
						<img src="images/logout.svg" />
						<span className="logout-text">Logout</span>
					</div>
				</li>
			</ul>
		</div>
	);
};

export default SideNav;
