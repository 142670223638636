import { connect } from "react-redux";
import SecondSearchBar from "./SecondSearchBar";
import { setTestData } from "../../../../creators/testDataCreator";
import { setHistory } from "../../../../creators/historyCreator";

const matchStateToProps = ({ history, testData }) => ({ history, testData });

const ConnectedSecondSearchBar = connect(matchStateToProps, {
	setTestData,
	setHistory
})(SecondSearchBar);

export default ConnectedSecondSearchBar;
