export const setUser = (data) => {
    return {type: "SET_USER", data}
}

export const editUser = (data) => {
    return { type: "EDIT_USER", data }
}

export const persistUser = (data) => {
    return { type: "PERSIST_USER", data }
}

export const deleteUser = () => {
    return { type: "DELETE_USER" }
}

