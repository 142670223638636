import React, { useState, useEffect } from "react";
import Search from "../../../../img/Search.png";
import { coursesData } from "../../../Data/Data";
import VideoData from "../../../../helpers/videoData";

const staleData = coursesData;
const staleVideo = VideoData;

export default function Searchbar({ history, setTestData, setHistory }) {
  const [search, setSearch] = useState("");
  useEffect(() => {
    onSearch(search);
  }, [search]);

  const searchForVideos = (s) => {
    console.log("videos", staleVideo);
    const res = staleVideo.reduce((acc, level) => {
      console.log("levels to this", level);
      const vids = level.Video.reduce((innerAcc, content) => {
        console.log("some in cont", content);
        const cont = content.content.filter((c) =>
          c.topic.toLowerCase().includes(s.toLowerCase())
        );
        innerAcc = innerAcc.concat(cont);
        return innerAcc;
      }, []);
      return acc.concat(vids);
    }, []);
    console.log("my res", res);
    setHistory({
      ...history,
      id: res.length > 0 ? res[0].id : 0,
      contents: res,
    });
  };
  const searchForCourses = (s) => {
    console.log("search is", s);
    const searchTerms = s.split(" ");
    let results = [];
    console.log("searchlength", searchTerms.length, searchTerms);
    if (searchTerms.length === 1) {
      results =
        staleData &&
        staleData.filter((data) => {
          return data.name.toLowerCase().includes(searchTerms[0].toLowerCase());
        });
    } else {
      results =
        staleData &&
        staleData.filter((data) =>
          data.name.toLowerCase().includes(searchTerms[0].toLowerCase())
        );
      results = results.reduce((acc, data) => {
        let checker = data.tests.filter((test) =>
          test.year.toLowerCase().includes(searchTerms[1].toLowerCase())
        );
        console.log("checker is", checker);
        if (checker.length > 0) {
          acc = acc.concat({ ...data, tests: checker });
        }
        return acc;
      }, []);
    }
    console.log("My results", results);
    if (results.length > 0) {
      setTestData(staleData);
      setHistory({
        path: "courses",
        id: results.reduce((acc, cur) => acc.concat(cur.id), []),
        search: true,
      });
    } else {
      setTestData(staleData);
      setHistory({ path: "courses", id: staleData[0].id, search: false });
    }
    console.log("These are the search result", results);
  };
  const onSearch = (search) => {
    if (history.path === "courses" && search.length >= 3) {
      searchForCourses(search);
    } else if (history.path === "video" && search.length > 0) {
      searchForVideos(search);
    }
  };

  return (
    <ul>
      <li>
        <div
          className="h-100 nav-search"
          style={{ margin: "auto", width: "100%" }}
        >
          <input
            className="searchInput"
            style={{
              width: "80%",
              padding: "12px",
              backgroundColor: "#eee",
              border: "none",
              outline: "none",
              display: "inline-block",
              borderRadius: "7px",
            }}
            onChange={({ target }) => setSearch(() => target.value)}
            type="text"
            name=""
            value={search}
            placeholder={
              history.path === "courses"
                ? "english 2014"
                : "geometric construction"
            }
          />
          <button
            className="button"
            style={{
              width: "20%",
              padding: "12px",
              color: "#fff",
              background: "#002984",
              border: "none",
              borderRadius: "3px",
              fontFamily: "Helvetica Bold",
            }}
          >
            Search
          </button>
          <button
            className="s_icon"
            style={{
              width: "20%",
              padding: "12px",
              color: "#fff",
              background: "#eee",
              border: "none",
              borderRadius: "3px",
              fontFamily: "Helvetica Bold",
            }}
          >
            <img
              className="search-width"
              style={{ color: "#fff" }}
              src={Search}
            />
          </button>
        </div>
      </li>
    </ul>
  );
}
