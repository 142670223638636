import React, { useState } from "react";

const Togglable = props => {
  console.log("clicked");
  const [visiblility, setVisibility] = useState(false);

  return (
    <React.Fragment>
      <div
        onClick={() => setVisibility(!visiblility)}
        className="text-dark c-pointer"
      >
        {props.render ? props.render() : props.name}
      </div>
      <div className={`${visiblility ? "accordion-open" : "accordion-close"}`}>
        {props.children}
      </div>
    </React.Fragment>
  );
};

export default Togglable;
