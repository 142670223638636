import { connect } from "react-redux";
//import { setMode } from '../../creators/historyCreator';
import SkillAssessment from "./SkillAssessment";
import { timeValue } from "../../creators/timeValueCreator";
import { setMode } from "../../creators/modeCreator";
import { setActiveCourse } from "../../creators/activeCourseCreator";
import { setCourses } from "../../creators/courseCreator";

const mapStateToProps = ({ history, testData }) => ({ history, testData });

const ConnectedSkillAssessment = connect(mapStateToProps, {
  setMode,
  setActiveCourse,
  setCourses,
  timeValue
})(SkillAssessment);

export default ConnectedSkillAssessment;
