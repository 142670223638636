import App from "./App";
import { connect } from "react-redux";
import { isBusy } from "../creators/loaderCreator";
import { setActiveUser } from "../creators/activeUserCreator";
import { persistUser } from "../creators/userCreator";
import { setHistory } from "../creators/historyCreator";
import { setNav } from "../creators/navCreator";
import { timeValue } from "../creators/timeValueCreator";
import { persistActiveCourse } from "../creators/activeCourseCreator";
import { setCourses } from "../creators/courseCreator";
import { setVideos } from "../creators/videoCreator";
import { setTestData } from "../creators/testDataCreator";

const mapStateToProps = ({
  user,
  users,
  loading,
  activeCourse,
  history,
  courses,
  time
}) => {
  return { loading, user, users, history, time, activeCourse, courses };
};

const ConnectedApp = connect(mapStateToProps, {
  setActiveUser,
  isBusy,
  persistUser,
  persistActiveCourse,
  setNav,
  setCourses,
  timeValue,
  setHistory,
  setVideos,
  setTestData
})(App);

export default ConnectedApp;
