import React from "react";
import ConnectedWrapper from "../Wrapper/ConnectedWrapper";
import ls from "../../sideEffects/ourLocalStorage";
import StudyNav from "../Navigation/StudyNav/StudyNav";
import ConnectedSkillAssessment from "./ConnectedSkillAssessment";
import { Route, useHistory, Redirect, Switch } from "react-router-dom";
import ConnectedCoursesObj from "../Quiz/ConnectedCoursesObj";
import ConnectedVideo from "../Video/ConnectedVideo";
import ConnectedThankful from "../Thankful/ConnectedThankful";
import ConnectedQuiz from "../QuizProper/ConnectedQuiz";
import ErrorBoundary from "../../ErrorBoundary";
import AuthLayout from "../Layouts/AuthLayout/AuthLayout";
import ConnectedRegister from "../Authentication/Register/ConnectedRegister";
import AuthNavigation from "../Navigation/AuthNavigation/AuthNavigation";
import ConnectedLogin from "../Authentication/Login/ConnectedLogin";
import LandingPage from "./LandingPage";

const Home = (props) => {
  const appHistory = useHistory()
  props.type("SEARCH_BAR");
  const userFromStorage = ls.getItemInLs("user");
  let user = props.user;
  if (!user && userFromStorage) {
    props.setActiveUser(userFromStorage);
    user = userFromStorage;
  }

  const usersFromStorage = ls.getItemInLs("users");
	let users = props.users;
	if (!users.length && usersFromStorage) {
		props.persistUser(usersFromStorage);
		users = usersFromStorage;
	}

  const goToLearn = () => {
    props.setHistory({
      path: "courses",
      id: null,
      search: false,
    })
    appHistory.push("/courses")
  }
  const goToVideo = () => {
    props.setHistory({ path: "video", id: null })
    appHistory.push("/videos")
  }

  return (
    <React.Fragment>
      {
        !props.user ? (
          <React.Fragment>
          <Route exact path="/register" render={() => 
            (<React.Fragment>
              <div
                className="col-md-12"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <AuthLayout path="/login">
                  <ConnectedRegister {...props} url={props.url} user={props.user} />
                </AuthLayout>
              </div>
            </React.Fragment>)} />
          <Route exact path="/login" render={() => 
            (<React.Fragment>
              <AuthNavigation
                users={props.users}
                path="/register"
                text=""
                link="NEW PROFILE"
                history={props.history}
                setHistory={props.setHistory}
              />
              <div
                className="col-md-12"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <AuthLayout>
                  <ConnectedLogin {...props} url={props.url} user={props.user} />
                </AuthLayout>
              </div>
            </React.Fragment>)} />
          <Route exact path="/">
              <>
              {users && users.length ? <Redirect to="/login" /> : <Redirect to="/register" /> }
              </>
            
          </Route>
          <Route path="/courses" render={() => (
            <>
            {users && users.length ? <Redirect to="/login" /> : <Redirect to="/register" /> }
            </>)} />
          <Route path="/videos" render={() => (
            <>
            {users && users.length ? <Redirect to="/login" /> : <Redirect to="/register" /> }
            </>)} />
          <Route path="/test" render={() => (
            <>
            {users && users.length ? <Redirect to="/login" /> : <Redirect to="/register" /> }
            </>)} />
          <Route path="/quiz" render={() => (
            <>
            {users && users.length ? <Redirect to="/login" /> : <Redirect to="/register" /> }
            </>)} />
          <Route path="/thanks" render={() => (
            <>
            {users && users.length ? <Redirect to="/login" /> : <Redirect to="/register" /> }
            </>)} />
            </React.Fragment>
        )
      :
        (
        <ConnectedWrapper>
          <Route exact path="/" render={() => (<div className="default-home text-center">
            <div className="study-background">
              <div className="container px__0">
                <div className="row top-padding">
                  <div className="col-md-12 col-lg-6">
                    <h3 className="name-p">
                      {" "}
                      Hi{" "}
                      {user.firstName[0].toUpperCase() +
                        user.firstName.slice(1)}
                    </h3>
                    <p className="inner-p">Choose how you want to learn </p>
                    <div className="row">
                      <div className="col-md-6 col-xs--6">
                        <div
                          className="card card-design card-layout c-pointer"
                          onClick={goToVideo}
                        >
                          <div className="card-body card-learn">
                            <img
                              src="images/learn.svg"
                              alt="study background"
                              className="img-responsive image-res"
                            />
                            <h3 className="image-text">Learn</h3>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-md-6 col-xs-6 c-pointer"
                        onClick={goToLearn}
                      >
                        <div className="card card-design-2 card-layout">
                          <div className="card-body card-learn">
                            <img
                              src="images/test.svg"
                              alt="study background"
                              className="img-responsive image-res"
                            />
                            <h3 className="image-text">Test</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                  <div className="col-md-12 col-lg-5">
                    <img
                      src="images/student.svg"
                      alt="study background"
                      className="img-responsive students-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
            )} />
          <Route path="/courses" render={() => (
            <ConnectedCoursesObj 
              type={props.type} 
              id={props.history.id} 
              search={props.history.search} />)} />

          <Route path="/videos" render={() => (
            <ConnectedVideo
              id={props.history.id}
              type={props.type}
              contents={props.history.contents}
              />)} />

          <Route path="/quiz" render={() => (
            <ConnectedQuiz
            id={props.history.id}
            questionId={props.history.questionId}
            type={props.type}
          />
              
          )} />
          <Route path="/thanks" render={() => (
            <ConnectedThankful />)} />
          </ConnectedWrapper>
      )}
    </React.Fragment>
  );
};

export default Home;
