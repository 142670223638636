import { connect } from "react-redux";
import { isBusy } from "../../creators/loaderCreator";
import { setNav } from "../../creators/navCreator";
import { setHistory } from "../../creators/historyCreator";
import { setMode } from "../../creators/modeCreator";
import { setCourses } from "../../creators/courseCreator";
import { setVideos } from "../../creators/videoCreator";
import Wrapper from "./Wrapper";
import { setTestData } from "../../creators/testDataCreator";

const mapStateToProps = ({ user, loading, history, navType }) => {
  return { loading, user, history, navType };
};

const ConnectedWrapper = connect(mapStateToProps, {
  type: setNav,
  isBusy,
  setMode,
  setHistory,
  setTestData,
  setVideos
})(Wrapper);

export default ConnectedWrapper;
