import React from "react";
import track from "../../sideEffects/apis/courseTracker";
import { test } from "../Data/Test";
import { useHistory } from "react-router-dom";

const SkillAssessment = (props) => {
  const appHistory = useHistory();
  console.log(props.testData);
  const onClick = () => {
    const tests = test;
    console.log("first level", tests);
    const selectedCourse = tests && tests.tests[0];
    console.log("second lll", selectedCourse);
    props.setMode("SKILL_MODE");
    props.timeValue(1200);
    props.setHistory({ path: "quiz", id: selectedCourse.id, questionId: 0 });
    props.setCourses(selectedCourse && selectedCourse.quizes);
    props.setActiveCourse(
      track.courseBuilder(props.history.mainId, props.history.id, "SKILL_MODE")
    );
    appHistory.push("/test");
  };
  return (
    <div>
      <div className="skill-page">
        <div className="container">
          <div className="row text-center">
            <div className="card skill-padding px-0">
              <div className="card-body px-0">
                <div className="col-md-6 d__none" style={{ textAlign: "left" }}>
                  <p className="card-text text-skill ">
                    A thousand learning opportunities behind this screen. See
                    you at the other side.
                  </p>
                  <div className="start-caption">
                    <span style={{ fontWeight: "bolder" }}>Welcome Mate!</span>{" "}
                    <br /> Just before you begin your learning experience with
                    StudyMate, we would love to test your current strength with
                    these 20 question Quiz on Mathematics and English.
                    <br />
                    <span>Don't worry, we are in this together!</span>
                  </div>
                  <button onClick={onClick} className="start-button">
                    LET'S GET STARTED
                  </button>
                </div>
                <div className="col-md-6 padleft-0">
                  <img className="img-skill" src="images/Image.jpg" />
                </div>
                <div className="col-md-6 d--none" style={{ textAlign: "left" }}>
                  <p className="card-text text-skill ">
                    A thousand learning opportunities behind this screen. See
                    you at the other side.
                  </p>
                  <div className="start-caption">
                    <span style={{ fontWeight: "bolder" }}>Welcome Mate!</span>{" "}
                    <br /> Just before you begin your learning experience with
                    StudyMate, we would love to test your current strength with
                    these 20 question Quiz on Mathematics and English.
                    <br />
                    <span>Don't worry, we are in this together!</span>
                  </div>
                  <button onClick={onClick} className="start-button">
                    LET'S GET STARTED
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillAssessment;
