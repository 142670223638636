import React from "react";
import { useHistory } from "react-router-dom";

const Thankful = ({ setHistory, mode, activeCourse }) => {
  console.log("fuccccccc", activeCourse);
  const appHistory = useHistory();
  const goToHome = () => {
    if (mode === "SKILL_MODE") {
      appHistory.push("/");
    } else {
      setHistory({
        path: "courses",
        id: null,
        search: false,
      });
      appHistory.push("/courses");
    }
  };

  const CongratulatoryMsg = (subjectId) => {
    let total = 50;
    if (subjectId == 56) {
      total = 100;
    }

    let score = activeCourse.score / total;

    if (score < 0.5) {
      return (
        <div>
          <div className="header text-center">Congratulations</div>

          <div className="thanks-content text-center">
            <h2>Well done!</h2>
            <div className="caption">
              <span>That's okay, practice makes perfect! </span>
              <br /> Let's try another Study Pack shall we?!
            </div>
            <div className="text-center">
              <button
                className="done-button"
                onClick={goToHome}
                style={{ borderRadius: "5px" }}
              >
                Click Done
              </button>
            </div>
          </div>
        </div>
      );
    } else if (score >= 0.5 && score < 0.7) {
      return (
        <div>
          <div className="header text-center">Congratulations</div>

          <div className="thanks-content text-center">
            <h2>Great Job!</h2>
            <div className="caption">
              <span>You Passed! Good score! </span>
              <br /> Let's keep up the pace
            </div>
            <div className="text-center">
              <button
                className="done-button"
                onClick={goToHome}
                style={{ borderRadius: "5px" }}
              >
                CONTINUE
              </button>
            </div>
          </div>
        </div>
      );
    } else if (score >= 0.7 && score < 0.9) {
      return (
        <div>
          <div className="header text-center">Congratulations</div>

          <div className="thanks-content text-center">
            <h2>Excellent Work!</h2>
            <div className="caption">
              <span>You have outdone yourself!</span>
            </div>
            <div className="text-center">
              <button
                className="done-button"
                onClick={goToHome}
                style={{ borderRadius: "5px" }}
              >
                CONTINUE
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="header text-center">Congratulations</div>

          <div className="thanks-content text-center">
            <h2>Amazing!!!</h2>
            <div className="caption">
              <span>A golden performance! You deserve a badge! </span>
              <br /> Let's attempt other Study Packs shall we?
            </div>
            <div className="text-center">
              <button
                className="done-button"
                onClick={goToHome}
                style={{ borderRadius: "5px" }}
              >
                CONTINUE
              </button>
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <div className="container-fluid">
      <div className=" thanks-wrapper">
        <img
          src="images/Studylady.svg"
          alt="thank you for taking this quiz to enrich your knowledge"
        />
        <div className="thanks-section text-center">
          {mode === "SKILL_MODE" ? (
            <div>
              <div className="header text-center">Congratulations</div>

              <div className="thanks-content text-center">
                <h2>Hi Great Job!</h2>
                <div className="caption">
                  <span>Hope you enjoyed the Quiz?!</span> <br /> Now we know
                  exactly what we are working with
                </div>
                <div className="text-center">
                  <button
                    className="done-button"
                    onClick={goToHome}
                    style={{ borderRadius: "5px" }}
                  >
                    LET'S GET STARTED
                  </button>
                </div>
              </div>
            </div>
          ) : (
            CongratulatoryMsg(activeCourse.mainId)
          )}
        </div>
      </div>
    </div>
  );
};

export default Thankful;
