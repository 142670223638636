import { connect } from 'react-redux';
import SideNav from './SideNav';
import { setHistory } from '../../creators/historyCreator';
import { deleteActiveUser } from '../../creators/activeUserCreator';


const mapStateToProps = ({ videos, courses, hamburger, user }) => {
    return { videos, courses, hamburger, user };
}

const ConnectedSideNav = connect(mapStateToProps, { setHistory, deleteActiveUser })(SideNav);

export default ConnectedSideNav;