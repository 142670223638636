import React from "react";
import { Link } from "react-router-dom";

const StudyNavBar = (props) => {
	//props.children is populated in that space
	console.log("pppppppppppppp", props);
	return (
		<div className="fixed-top never-move">
			<header id="header">
				<div className="container">
					<div className="logo float-left nav-padding_ ">
						<Link to='/' className="scrollto" >
							<img src="images/logo2.svg" alt="" className="img-fluid" />
						</Link>
					</div>

					<nav
						className="main-nav float-right d-none d-lg-block"
						style={{position: "static"}}
					>
						<ul>{props.children}</ul>
					</nav>
				</div>
			</header>
		</div>
	);
};

export default StudyNavBar;
