import React from 'react'

const FirstLevel = ({name, isClickable, id, ...props}) => {
    const localHistory = () => {
        props.setHistory({path: "courses", id, search: false})
    }
    return (
        <React.Fragment>
            {isClickable ?  <button
                className="btn dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-toggle="dropdown"
                aria-haspopup="true"
                onClick={localHistory}
                aria-expanded="false">
                    <span>{name}</span>
              </button>
              :
              <button
                className="btn dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                    <span>{name}</span>
              </button>
              }
            
        </React.Fragment>
    )
}

export default FirstLevel;