import { connect } from "react-redux";
import { isBusy } from "../../creators/loaderCreator";
import { setUser, deleteUser } from "../../creators/userCreator";
import { setHistory } from "../../creators/historyCreator";
import { setMode } from "../../creators/modeCreator";
import { setNav } from "../../creators/navCreator";
import CoursesObj from "./CoursesObj";
import {
  setActiveCourse,
  updateActiveCourse
} from "../../creators/activeCourseCreator";
import { setUserCourses } from "../../creators/userCoursesCreator";
import { setCourses } from "../../creators/courseCreator";
import { timeValue } from "../../creators/timeValueCreator";

const mapStateToProps = ({ user, loading, time, history, testData }) => {
  return { loading, user, time, history, testData };
};

const ConnectedCoursesObj = connect(mapStateToProps, {
  setUser,
  deleteUser,
  isBusy,
  setNav,
  setActiveCourse,
  updateActiveCourse,
  setHistory,
  setMode,
  timeValue,
  setUserCourses,
  setCourses
})(CoursesObj);

export default ConnectedCoursesObj;
