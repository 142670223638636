const testDataReducer = (state = null, action) => {
  switch (action.type) {
    case "SET_TEST_DATA":
      console.log(action.data);
      return action.data;
    default:
      return state;
  }
};

export default testDataReducer;
