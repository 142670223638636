import React from "react";
import MathJaxParse from './MathJaxParse'
import HtmlParse from './HtmlParse'

const ParserCondition = (props) => {
    return (
        <React.Fragment>
            {props.isMathjax ? 
            <div><MathJaxParse question={props.question ? props.question : ""} /></div>
            : <div><HtmlParse question={props.question ? props.question : ""} /></div>      
             }
        </React.Fragment>
    )
}

export default ParserCondition