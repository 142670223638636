import React, { useState } from "react";
import { connect } from "react-redux";
import ls from "../../../sideEffects/ourLocalStorage";
import { deleteActiveUser } from "../../../creators/activeUserCreator";
import { setHistory } from "../../../creators/historyCreator";
import { useHistory, useLocation } from "react-router-dom";

const StudyNav = (props) => {
  const appHistory = useHistory()
  const {pathname} = useLocation()
  const onLogoutOut = () => {
    props.deleteActiveUser();
    props.setHistory("login");
    document.body.style.overflow = "auto";
  };

  const [shownav, setShow] = useState(false);

  const showMe = () => {
    setShow(true);
    document.body.style.overflow = "hidden";
  };

  const closeMe = () => {
    setShow(false);
    document.body.style.overflow = "auto";
  };

  const gotToHomeFromIcon = () => {
    console.log({pathname})
    if(pathname !== "/test" && pathname !== "/quiz"){
      appHistory.push("/")
    }
  }

  const gotToHomeFromBackButton = () => {
    props.setHistory({path: "home"})
    appHistory.push("/")
  }

  const gotToHomeFromBackButtonOnMobile = () => {
    props.setHistory({path: "home"})
    document.body.style.overflow = "auto";
    appHistory.push("/")

  }

  return (
    <header id="header">
      {/* {props.isHiddenMode && <ConnectedHamburger />} */}
      <div className="container px__0">
        <div className="logo float-left nav-padding ">
          <a
            onClick={gotToHomeFromIcon}
            className="scrollto c-pointer"
          >
            <img
              src="images/logo2.svg"
              alt="app logo identity"
              className="img-fluid"
            />
          </a>
        </div>
        {pathname === "/" ? (
          <>
            <span
              onClick={onLogoutOut}
              className="c-pointer logo float-right nav-padding-right dissapear-nav "
            >
              <div className="d-inline">
                <img className="log-out-nav" src="images/log-out-2.svg" />
              </div>
            </span>
            <span
              onClick={showMe}
              className="c-pointer logo float-right nav-padding-right appear-nav "
            >
              <div className="d-inline">
                <img className="log-out-nav" src="images/log-out.svg" />
              </div>
            </span>
            {shownav && (
              <div className="shownav">
                <div className="cancel">
                  <img onClick={closeMe} src="images/cancel.svg" />
                </div>
                <div className="d-inline">
                  <img
                    onClick={onLogoutOut}
                    className="log-out-nav"
                    src="images/log-out-2.svg"
                  />
                </div>
               
              </div>
            )}
            {shownav && (
              <div className="overlay-home "></div>
            )}
          </>
        ) : pathname === "/quiz" || pathname === "/test" ? (
          <span
            onClick={gotToHomeFromBackButton}
            className="c-pointer logo float-right nav-padding-right d-none dissapear-nav"
          >
            <div className="d-inline">
              {/* <button className="back-home">BACK TO MENU</button> */}
              <img
                className="img-responsive back-up"
                src="images/back.svg"
              ></img>
            </div>
          </span>
        ) : (
          <>
            <span
              onClick={gotToHomeFromBackButton}
              className="c-pointer logo float-right nav-padding-right dissapear-nav"
            >
              <div className="d-inline">
                {/* <button className="back-home">Back to Menu</button> */}
                <img
                  className="img-responsive back-up"
                  src="images/back.svg"
                ></img>
              </div>
            </span>

            <span
              onClick={showMe}
              className="c-pointer logo float-right nav-padding-right appear-nav "
            >
              <div className="d-inline">
                <img className="log-out-nav" src="images/log-out.svg" />
              </div>
            </span>
            {shownav && (
              <div className="shownav">
                <div className="cancel">
                  <img onClick={closeMe} src="images/cancel.svg" />
                </div>

                <div className="d-inline move-it-down">
                  <img
                    onClick={gotToHomeFromBackButtonOnMobile}
                    className="img-responsive back-up"
                    src="images/back.svg"
                  ></img>
                </div>
              </div>
            )}
              {shownav && (
              <div className="overlay-home "></div>
            )}
          </>
        )}

        <nav className="main-nav float-right nav-blue d-block d-lg-block">
          {props.children}
        </nav>
      </div>
    </header>
  );
};

export default connect(null, { setHistory, deleteActiveUser })(StudyNav);
