import ls from "../sideEffects/ourLocalStorage";
const courseReducer = (state = null, action) => {
  switch (action.type) {
    case "SET_COURSES":
      console.log(action.data);
      ls.persistItemInLS("courses", action.data, 3);
      return action.data || state;
    default:
      return state;
  }
};

export default courseReducer;
