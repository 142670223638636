import React from 'react'
import LearnDecision from './LearnDecision'
import FreeFormDecision from './FreeFormDecison'
import ConnectedTimeMode from './ConnectedTimeMode'

const Decision = ({type, style}) => {
    return (
        <div style={style} className="decision-box">
            {type === 'LEARN_MODE' && <LearnDecision />}
            {type === 'TIME_MODE' && <ConnectedTimeMode />}
            {type === 'SKILL_MODE' && <ConnectedTimeMode />}
            {type === 'FREE_FORM' && <FreeFormDecision />}
        </div>
    )
}

export default Decision

