import ls from '../sideEffects/ourLocalStorage'
const activeUserReducer = (state = null, action) => {
    switch (action.type) {
        case "SET_ACTIVE_USER":
            ls.persistItemInLS("user", action.data, 3)
            console.log('activeUserReducer after',action.data)
            return action.data;
        case "DELETE_ACTIVE_USER":
            ls.removeItemFromLS("user")
            return null;
        default:
            return state;
    }
}

export default activeUserReducer;