import ls from '../sideEffects/ourLocalStorage'
const userTestReducer = (state = null, action) => {
    switch (action.type) {
        case "SET_USER_TEST":
            ls.persistItemInLS("userTest", action.data, 3)
            return action.data;
        default:
            return state;
    }
}

export default userTestReducer;