import ls from '../sideEffects/ourLocalStorage'
const timeValueReducer = (state = 0, action) => {
    switch (action.type) {
        case "SET_TIME":
            ls.persistItemInLS("time", action.data, 3)
            return action.data
        default:
            return state;
    }
}

export default timeValueReducer;