import React from 'react'
import NoTimeNav from '../ToRightOfNav/NoTimeNav/NoTimeNav'
import ConnectedTimeNav from '../ToRightOfNav/TimeNav/ConnectedTimeNav'
import ConnectedSearchBar from '../ToRightOfNav/Searchbar/ConnectedSearchBar'
import Logout from '../../Logout/Logout'

export default function StudyNavRight({type, history}) {
    return (
        <React.Fragment>
            
            {type === "LEARN_MODE" && history !== "thanks" && <NoTimeNav text="Learning Mode" />}
            {(type === "TIME_MODE" || type === "SKILL_MODE") && history !== "thanks" && <ConnectedTimeNav />}
            {type === "FREE_FORM" && history !== "thanks" && <NoTimeNav text="Freeform Mode"/>}
            {type === "REVIEW_MODE" && history !== "thanks" && <NoTimeNav text="Review mode"/>}
        </React.Fragment>
    )

}










    