import ls from '../ourLocalStorage';
import Axios from 'axios';

const baseUrl = '/api/';
const axios = Axios;
export const postUserCourse = (model) => {
    return axios.post("http://infomall-001-site1.etempurl.com/api/usercourses", model)
            .then(response => response)
            .catch(err => {throw new Error(err)})
}    

const checkItemInLs = (name) => {
    //get intended item from local storage
    const item = ls.getItemInLs(name);
    return new Promise((resolve, reject) => {
        item ? resolve(item) : reject(item)
    })
}

const getAll = (url, name) => {
    const item = checkItemInLs(name);
    //check if we have that item, if we do return it, otherwise, make an api call
    return item
            .then(data => {
                return data
            })
            .catch(e => {
                return axios.get(baseUrl + url)
                            .then(response => {
                                ls.persistItemInLS(name, response.data, 1)
                                return response.data
                            })
                            .catch(err => { throw new Error(err.response.data) })
            })
}

const getItemWithId = (url, name) => {
    const item = checkItemInLs(name);
    return item
        .then(data => {
            return data
        })
        .catch(e => {
            return axios.get(baseUrl + url)
                    .then(response => {
                        ls.persistItemInLS(name, response.data, 1)
                        return response.data
                    })
                    .catch(err => { throw new Error(err.response.data) })
        })
}
    


const updateWithId = (url, newObject, name) => {
    const item = checkItemInLs(name);
    return item
        .then(data => data)
        .catch(e => {
            return axios.put(baseUrl + url, newObject)
                    .then(response => {
                        ls.persistItemInLS(name, response.data, 1)
                        return response.data
                    })
                    .catch(err => { throw new Error(err.response.data) })
        })
}
    


const create = (url, newObject, name) => {
    const item = checkItemInLs(name);
    return item
        .then(data => data)
        .catch(e => {
            return axios.post(baseUrl + url, newObject)
                    .then(response => {
                        ls.persistItemInLS(name, response.data, 1)
                        return response.data
                    })
                    .catch(err => { console.log(err.response); throw new Error(err.response.data.message) })
        })
}
    


const deleteWithId = (url, name) =>
    axios.delete(baseUrl + url)
        .then(response => {
            ls.removeItemFromLS(name)
            return response.data
        })
        .catch(err => { throw new Error(err.response.data) })


const mock = (data, name) => {
    const item = checkItemInLs(name);
    return item
        .then(data => {
            return data
        })
        .catch(e => {
            ls.persistItemInLS(name, data, 1);
            return data
        })
};

export default {
    getAll,
    getItemWithId,
    updateWithId,
    create,
    deleteWithId,
    mock,
}