import React, { useEffect } from "react";
import ls from "../../sideEffects/ourLocalStorage";
import ConnectedSideNav from "../SideNav/ConnectedSideNav";
import StudyNav from "../Navigation/StudyNav/StudyNav";
import StudyNavRight from "../Navigation/StudyNavRight/StudyNavRight";

const Wrapper = ({ isBusy, setTestData, setVideos, ...props }) => {
  useEffect(() => {
    // document.body.classList.add("no-overflow");
  }, []);

  const isQuizPage = props.history.path === "quiz";

  const modeFromStorage = ls.getItemInLs("mode");
  !props.mode && props.setMode(modeFromStorage);
  const getMode = () => props.mode || modeFromStorage;

  const isHideablePage = () => isQuizPage && getMode();

  return (
    <div>
      <div className="container-fluid p-0">
        <StudyNav
          history={props.history.path}
          setHistory={props.setHistory}
          isHiddenMode={!isHideablePage() && props.history.path !== "thanks"}
        >
          <StudyNavRight type={props.navType} history={props.history.path} />
        </StudyNav>
      </div>

      <div className="m-0" style={{ backgroundColor: "#fafafa" }}>
        {/* {!isHideablePage() && props.history.path !== "thanks" && (
          <div className="d-inline">
            <ConnectedSideNav />
          </div>
        )} */}

        <div>{props.children}</div>
      </div>
    </div>
  );
};

export default Wrapper;

// { false && (
// isHiddenMode={false}

// isHiddenMode={!isHideablePage() && props.history.path !== "thanks"}
// {!isHideablePage() && props.history.path !== "thanks" && (
