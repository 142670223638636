const singleCourseReducer = (state = [], action) => {
    switch (action.type) {
        case "ADD_COURSE":
            return state.concat(action.data);
        case "EDIT_COURSE":
            return state.map(course => course.id === action.id ? action.data : course);
        default:
            return state;
    }
}

export default singleCourseReducer;