import { useState } from 'react';

const allowedEmails = ["gmail.com", "ymail.com", "yahoo.com", "outlook.com", "yahoomail.com"]
const validateEmail = (email) => {
    if(email){
        let splitted = email.split("@")
        if(splitted.length === 2){
            let otherPart = splitted[1]
            if(allowedEmails.includes(otherPart.toLowerCase())){
                return true;
            }
        }
    }
    return false;
}

const validateName = (name) => {
    const regex = /^[a-zA-Z ]{2,30}$/;
    return regex.test(name)
}

const validatePhone = (phone) => {
    const regex = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
    return regex.test(phone)
}

export const useForm = (type, val, field) => {
    const [value, setValue] = useState(val);
    const onChange = (e) => setValue(e.target.value);
    const onSetChange = (data) => setValue(data);
    const validateField = () => {
        switch(field){
            case "email":
                return validateEmail(value);
            case "first":
                return validateName(value);
            case "last":
                return validateName(value);
            case "phone":
                return validatePhone(value);
            default:
                return false;
        }
    }
    return {
        main: { type, value, onChange },
        onSetChange,
        validateField
    }
}

export const useFormFile = () => {
    const [value, setValue] = useState();
    const onChange = (e) => {
        const file = e.target.files[0]
        setValue(file)
    };
    return { value, onChange }
}
