import React from "react";
import StudyNavBar from "../StudyNavBar/StudyNavBar";
import { useHistory, Link } from "react-router-dom";

const AuthNavigation = (props) => {
	const appHistory = useHistory();
	const routeProperly = () => {
		if(props.users.length < 4 && props.users.length !== 0){
			appHistory.push(props.path)
		}
		else{
			appHistory.push("/login")
		}

		
	}
	return (
		<React.Fragment>
			{!props.user && (
				<StudyNavBar>
					<React.Fragment>
							<li className="nav-padding ">
								{" "}
								<a>
									<span>{props.text} </span>{" "}
								</a>
							</li>
							<li className="nav-padding ">
								<Link
									className="blue-ish c-pointer"
									to={props.path || (props.users.length < 4 && props.users.length !== 0 ? "/login" : "/register")}
								>
									{" "}
									{props.link || (props.users.length < 4 && props.users.length !== 0 ? "LOG IN" : "SIGN UP")}
								</Link>
							</li>
						</React.Fragment>
				</StudyNavBar>
			)}
		</React.Fragment>
	);
};

export default AuthNavigation;
