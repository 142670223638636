export const test = {
  id: 41,
  name: "USE OF ENGLISH",
  subText: null,
  tests: [
    {
      id: 288,
      year: "2007",
      text: null,
      questionNo: 100,
      shortDescription: null,
      duration: 200,
      courseId: 41,
      quizes: [
        {
          id: 35938,
          isSectioned: false,
          isFirstSection: true,
          section:
            "In the next 2 questions, choose the appropriate stress pattern from the options. The syllables are written in capital letters.",
          hasPassage: false,
          isFirstPassage: false,
          passage: "",
          question: "genealogy",
          isQuestionMathJax: false,
          answerId: 0,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/use_of_english/2006/Q46.mp3",
          answerUrl: "‘geneAlogy’ it has the appropriate stress pattern",
          testId: 687,
          options: [
            {
              id: 143195,
              content: "geNEalogy",
              isMathJax: false,
              practiceId: 35938,
            },
            {
              id: 143196,
              content: "geneaLOgy",
              isMathJax: false,
              practiceId: 35938,
            },
            {
              id: 143197,
              content: "GEnealogy",
              isMathJax: false,
              practiceId: 35938,
            },
            {
              id: 143198,
              content: "geneAlogy",
              isMathJax: false,
              practiceId: 35938,
            },
          ],
        },
        {
          id: 35939,
          isSectioned: false,
          isFirstSection: false,
          section:
            "In the next 2 questions, choose the appropriate stress pattern from the options. The syllables are written in capital letters.",
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question: "Catholicism",
          isQuestionMathJax: false,
          answerId: 143199,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/use_of_english/2006/Q47.mp3",
          answerUrl: "‘caTHOlicism’ it has the appropriate stress pattern",
          testId: 687,
          options: [
            {
              id: 143199,
              content: "caTHOlicism",
              isMathJax: false,
              practiceId: 35939,
            },
            {
              id: 143200,
              content: "cathoLIcism",
              isMathJax: false,
              practiceId: 35939,
            },
            {
              id: 143201,
              content: "Catholicism",
              isMathJax: false,
              practiceId: 35939,
            },
            {
              id: 143202,
              content: "catholiCism",
              isMathJax: false,
              practiceId: 35939,
            },
          ],
        },
        {
          id: 35940,
          isSectioned: false,
          isFirstSection: true,
          section:
            "In the next 2 questions, choose the option that has a different stress pattern from the others.",
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question: ".",
          isQuestionMathJax: false,
          answerId: 143206,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/use_of_english/2006/Q48.mp3",
          answerUrl: "‘Registration’ it has a different stress pattern",
          testId: 687,
          options: [
            {
              id: 143203,
              content: "Revivalist",
              isMathJax: false,
              practiceId: 35940,
            },
            {
              id: 143204,
              content: "Quadruplicate",
              isMathJax: false,
              practiceId: 35940,
            },
            {
              id: 143205,
              content: "Ability",
              isMathJax: false,
              practiceId: 35940,
            },
            {
              id: 143206,
              content: "Registration",
              isMathJax: false,
              practiceId: 35940,
            },
          ],
        },
        {
          id: 35941,
          isSectioned: false,
          isFirstSection: false,
          section:
            "In the next 2 questions, choose the option that has a different stress pattern from the others.",
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question: ".",
          isQuestionMathJax: false,
          answerId: 143209,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/use_of_english/2006/Q49.mp3",
          answerUrl: "‘Represent’ it has a different stress pattern",
          testId: 687,
          options: [
            {
              id: 143207,
              content: "Quality",
              isMathJax: false,
              practiceId: 35941,
            },
            {
              id: 143208,
              content: "Syllabus",
              isMathJax: false,
              practiceId: 35941,
            },
            {
              id: 143209,
              content: "Represent",
              isMathJax: false,
              practiceId: 35941,
            },
            {
              id: 143210,
              content: "Competent",
              isMathJax: false,
              practiceId: 35941,
            },
          ],
        },
        {
          id: 35949,
          isSectioned: false,
          isFirstSection: false,
          section:
            "In the next 10 questions, choose the option that best explains the information conveyed in the sentence",
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question:
            "You are to write a report and give it to the security office.",
          isQuestionMathJax: false,
          answerId: 143242,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/use_of_english/2006/Q57.mp3",
          answerUrl:
            "‘You have been ordered to write a report, which you will give to the security office’ it best explains the information conveyed in the sentence",
          testId: 687,
          options: [
            {
              id: 143239,
              content:
                "The report you are writing should be given to the security office",
              isMathJax: false,
              practiceId: 35949,
            },
            {
              id: 143240,
              content:
                "You are writing a report, as the security office directed",
              isMathJax: false,
              practiceId: 35949,
            },
            {
              id: 143241,
              content:
                "After writing your report, give it to the security office",
              isMathJax: false,
              practiceId: 35949,
            },
            {
              id: 143242,
              content:
                "You have been ordered to write a report, which you will give to the security office",
              isMathJax: false,
              practiceId: 35949,
            },
          ],
        },
        {
          id: 35951,
          isSectioned: false,
          isFirstSection: false,
          section:
            "In the next 10 questions, choose the option that best explains the information conveyed in the sentence",
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question:
            "The prefect reeled off the names of all the students who broke the library law.",
          isQuestionMathJax: false,
          answerId: 143249,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/use_of_english/2006/Q59.mp3",
          answerUrl:
            "‘The prefect gave an accurate list of the students who disobeyed library regulations’ it best explains the information conveyed in the sentence",
          testId: 687,
          options: [
            {
              id: 143247,
              content:
                "The prefect omitted the names of the students who broke into the law library",
              isMathJax: false,
              practiceId: 35951,
            },
            {
              id: 143248,
              content:
                "The prefect submitted a complete list of the students who stole library books",
              isMathJax: false,
              practiceId: 35951,
            },
            {
              id: 143249,
              content:
                "The prefect gave an accurate list of the students who disobeyed library regulations",
              isMathJax: false,
              practiceId: 35951,
            },
            {
              id: 143250,
              content:
                "The prefect gave a disjointed list of the students in the law library",
              isMathJax: false,
              practiceId: 35951,
            },
          ],
        },
        {
          id: 35952,
          isSectioned: false,
          isFirstSection: false,
          section:
            "In the next 10 questions, choose the option that best explains the information conveyed in the sentence",
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question: "I owe you far less than you owe me",
          isQuestionMathJax: false,
          answerId: 143252,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/use_of_english/2006/Q60.mp3",
          answerUrl:
            "‘I owe you something, but you owe me much more’ it best explains the information conveyed in the sentence",
          testId: 687,
          options: [
            {
              id: 143251,
              content: "My debt to you is greater than yours to me",
              isMathJax: false,
              practiceId: 35952,
            },
            {
              id: 143252,
              content: "I owe you something, but you owe me much more",
              isMathJax: false,
              practiceId: 35952,
            },
            {
              id: 143253,
              content: "Your debt is not much greater than mine",
              isMathJax: false,
              practiceId: 35952,
            },
            {
              id: 143254,
              content: "What we owe each other is approximately the same",
              isMathJax: false,
              practiceId: 35952,
            },
          ],
        },
        {
          id: 35987,
          isSectioned: false,
          isFirstSection: false,
          section:
            "In the next 15 questions, choose the word opposite in meaning to the word or phrase in italics",
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question:
            "The chief said he would use the power <u><i>inherent</i></u> in his office to stop the celebration.",
          isQuestionMathJax: false,
          answerId: 143394,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/use_of_english/2006/Q95.mp3",
          answerUrl:
            "‘Unrelated to’ it is opposite in meaning to the word ‘inherent’.",
          testId: 687,
          options: [
            {
              id: 143391,
              content: "Common to",
              isMathJax: false,
              practiceId: 35987,
            },
            {
              id: 143392,
              content: "Allotted to",
              isMathJax: false,
              practiceId: 35987,
            },
            {
              id: 143393,
              content: "Impervious to",
              isMathJax: false,
              practiceId: 35987,
            },
            {
              id: 143394,
              content: "Unrelated to",
              isMathJax: false,
              practiceId: 35987,
            },
          ],
        },
        {
          id: 35989,
          isSectioned: false,
          isFirstSection: false,
          section:
            "In the next 15 questions, choose the word opposite in meaning to the word or phrase in italics",
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question:
            "The police found a <i><u>conclusive</u></i> proof of the boy’s guilt.",
          isQuestionMathJax: false,
          answerId: 143399,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/use_of_english/2006/Q97.mp3",
          answerUrl:
            "‘A doubtful’ it is opposite in meaning to the word ‘conclusive’.",
          testId: 687,
          options: [
            {
              id: 143399,
              content: "A doubtful",
              isMathJax: false,
              practiceId: 35989,
            },
            {
              id: 143400,
              content: "A consolatory",
              isMathJax: false,
              practiceId: 35989,
            },
            {
              id: 143401,
              content: "An incriminating",
              isMathJax: false,
              practiceId: 35989,
            },
            {
              id: 143402,
              content: "A corroborative",
              isMathJax: false,
              practiceId: 35989,
            },
          ],
        },
        {
          id: 35992,
          isSectioned: false,
          isFirstSection: false,
          section:
            "In the next 15 questions, choose the word opposite in meaning to the word or phrase in italics",
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question:
            "The notice reads, ‘<u><i>no loitering</i></u>&nbsp;in this hall’",
          isQuestionMathJax: false,
          answerId: 143414,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/use_of_english/2006/Q100.mp3",
          answerUrl:
            "‘Running’ it is opposite in meaning to the word ‘no loitering’.",
          testId: 687,
          options: [
            {
              id: 143411,
              content: "Business",
              isMathJax: false,
              practiceId: 35992,
            },
            {
              id: 143412,
              content: "Money",
              isMathJax: false,
              practiceId: 35992,
            },
            {
              id: 143413,
              content: "Gainful",
              isMathJax: false,
              practiceId: 35992,
            },
            {
              id: 143414,
              content: "Running",
              isMathJax: false,
              practiceId: 35992,
            },
          ],
        },
        {
          id: 17670,
          isSectioned: false,
          isFirstSection: false,
          section: null,
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question:
            "<p>Simplify \\(\\frac{3^-5}{9^{1-n}} \\times\\ 27^{n+1}\\)</span></p>",
          isQuestionMathJax: true,
          answerId: 70079,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/mathematics/2013/Q6.mp3",
          answerUrl:
            "\\(\\frac{3^{-5n}}{9^{1-n}}\\times 27^{n+1}\\\\ \\frac {3^{-5n}}{3^{2(1-n)}} \\times 3^{3(n+1)}\\)\n\\(3^{-5n} \\div 3^{2(1-n)} \\times 3^{3(n+1)}\\\\  3^{-5n -2(1-n) +3(n+1)}\\)\n\\(3^{-5n -2 +2n +3n +3}\\\\ 3^{-5n +5n -2 +3} = 3^1 = 3\\)",
          testId: 344,
          options: [
            {
              id: 70076,
              content: "\\(3^5\\)",
              isMathJax: true,
              practiceId: 17670,
            },
            {
              id: 70077,
              content: "\\(3^2\\)",
              isMathJax: true,
              practiceId: 17670,
            },
            {
              id: 70078,
              content: "\\(3^3\\)",
              isMathJax: true,
              practiceId: 17670,
            },
            {
              id: 70079,
              content: "3",
              isMathJax: false,
              practiceId: 17670,
            },
          ],
        },
        {
          id: 17675,
          isSectioned: false,
          isFirstSection: false,
          section: null,
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question: "If S= \\(\\sqrt{t^2 – 4t + 4}\\), find t in terms of S.",
          isQuestionMathJax: true,
          answerId: 70092,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/mathematics/2013/Q11.mp3",
          answerUrl:
            "\\( S = \\sqrt{t^{2} - 4t + 4} \\\\ S^2 = t^{2} – 4t + 4 \\\\ t^{2} – 4t + (4 – S^{2}) = 0 \\\\ Using \\ t = \\frac{-b \\pm \\sqrt{b^{2} – 4ac}}{2a} \\\\ we \\ get \\ t = \\frac{-(-4) \\pm \\sqrt{(-4)^2 -4(1)(4 – S^2)}}{2(1)} \\\\ = \\frac{4 \\pm  \\sqrt{16 -4(4 –S^2)}}{2} \\\\ = \\frac{4 \\pm \\sqrt{16 – 16 + 4S^{2}}}{2}\\\\ = \\frac{4 \\pm /sqrt{4S^2}}{2} = \\frac {4 \\pm 2S}{2} \\\\ = \\frac {2(2 \\pm S}{2} \\\\ Hence, t = S+ 2\\)",
          testId: 344,
          options: [
            {
              id: 70092,
              content: "S+2",
              isMathJax: false,
              practiceId: 17675,
            },
            {
              id: 70093,
              content: "\\(S^2 + 2\\)",
              isMathJax: true,
              practiceId: 17675,
            },
            {
              id: 70094,
              content: "S – 2",
              isMathJax: false,
              practiceId: 17675,
            },
            {
              id: 70095,
              content: "\\(S^2 – 2\\)",
              isMathJax: true,
              practiceId: 17675,
            },
          ],
        },
        {
          id: 12252,
          isSectioned: false,
          isFirstSection: false,
          section: null,
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question: "Find n if \\(34_n = 10011_2\\)",
          isQuestionMathJax: true,
          answerId: 48492,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/mathematics/2012/Q1.mp3",
          answerUrl:
            "\\(3&nbsp;\\times n^1 + 4 \\times n^0 = 1 \\times 2^4 + 0 \\times 2^3 + 0 \\times 2^2 + 1 \\times 2^1 + 1 \\times 2^0 \\)\n\\(\\\\ 3n + 4 = 16 + 0 + 0 + 2 + 1\\)\n\\( \\\\ 3n + 4 = 19 \\)\n\\(\\\\ 3n = 19 – 4\\)\n\\(\\\\ 3n = 15\\)\n\\(\\\\ n = \\frac{15}{3}\\)\n\\(\\\\ n = 5 \\)&nbsp;",
          testId: 248,
          options: [
            {
              id: 48491,
              content: "6",
              isMathJax: false,
              practiceId: 12252,
            },
            {
              id: 48492,
              content: "5",
              isMathJax: false,
              practiceId: 12252,
            },
            {
              id: 48493,
              content: "4",
              isMathJax: false,
              practiceId: 12252,
            },
            {
              id: 48494,
              content: "8",
              isMathJax: false,
              practiceId: 12252,
            },
          ],
        },
        {
          id: 12265,
          isSectioned: false,
          isFirstSection: false,
          section: null,
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question:
            "If y varies directly as \\(\\sqrt{n}\\) and y = 4 when n = 4, find y when n = 1\\(\\frac{7}{9}\\)",
          isQuestionMathJax: true,
          answerId: 48545,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/mathematics/2012/Q14.mp3",
          answerUrl:
            "\\(y \\alpha \\sqrt{n} \\\\ y = k\\sqrt{n} \\\\ 4 = k \\sqrt{4} \\\\ 4 = 2k \\\\ k = 2 \\\\ y = 2 \\sqrt{n} \\\\ y = 2 \\sqrt{\\frac{16}{9}} \\\\ y = 2(\\frac{4}{3}) \\\\ y = \\frac{8}{3}\\)&nbsp;",
          testId: 248,
          options: [
            {
              id: 48543,
              content: "\\(\\sqrt{n}\\)",
              isMathJax: true,
              practiceId: 12265,
            },
            {
              id: 48544,
              content: "\\(\\frac{4}{2}\\)",
              isMathJax: true,
              practiceId: 12265,
            },
            {
              id: 48545,
              content: "\\(\\frac{8}{3}\\)",
              isMathJax: true,
              practiceId: 12265,
            },
            {
              id: 48546,
              content: "\\(\\frac{2}{3}\\)",
              isMathJax: true,
              practiceId: 12265,
            },
          ],
        },
        {
          id: 17767,
          isSectioned: false,
          isFirstSection: false,
          section: null,
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question:
            "Evaluate \\(    \\frac{\\frac{1}{10} \\times \\frac{2}{3} + \\frac{1}{4}}{\\frac{1}{2} \\div&nbsp; \\frac{3}{5} - \\frac{1}{4}}   \\)",
          isQuestionMathJax: true,
          answerId: 70461,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/mathematics/2004/Q3.mp3",
          answerUrl:
            "\\(  \\\\  \\frac{\\frac{1}{10} \\times \\frac{2}{3} + \\frac{1}{4}}{\\frac{1}{2} \\div \\frac{3}{5} - \\frac{1}{4}}   \\)\n\\(\\\\= \\frac{\\frac{2}{30} + \\frac{1}{4}}{\\frac{1}{2} \\times \\frac{5}{3} - \\frac{1}{4}}  \\)\n\\( \\\\=  \\frac{ \\frac{4 + 15}{60} }{ \\frac{5}{6} - \\frac{1}{4}}  \\)\n\\(= \\frac{19}{60} \\times \\frac{12}{7} = \\frac{19}{35} \\)",
          testId: 346,
          options: [
            {
              id: 70458,
              content: "\\(\\frac{2}{25}\\)",
              isMathJax: true,
              practiceId: 17767,
            },
            {
              id: 70459,
              content: "\\(\\frac{19}{60}\\)",
              isMathJax: true,
              practiceId: 17767,
            },
            {
              id: 70460,
              content: "\\(\\frac{7}{12}\\)",
              isMathJax: true,
              practiceId: 17767,
            },
            {
              id: 70461,
              content: "\\(\\frac{19}{35}\\)",
              isMathJax: true,
              practiceId: 17767,
            },
          ],
        },
        {
          id: 17770,
          isSectioned: false,
          isFirstSection: false,
          section: null,
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question:
            "Given that \\( \\sqrt[3]{4^{2x}}= 16\\), find the value of x.",
          isQuestionMathJax: true,
          answerId: 70470,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/mathematics/2004/Q6.mp3",
          answerUrl:
            "\\( \\sqrt[3]{4^{2x}}= 16\\),\n\\( \\\\ 4^{2x} = (4^2)^3 \\)\n\\( \\\\ 2^x = 6\\)\n\\( \\\\ x = 3\\)",
          testId: 346,
          options: [
            {
              id: 70470,
              content: "3",
              isMathJax: false,
              practiceId: 17770,
            },
            {
              id: 70471,
              content: "2",
              isMathJax: false,
              practiceId: 17770,
            },
            {
              id: 70472,
              content: "4",
              isMathJax: false,
              practiceId: 17770,
            },
            {
              id: 70473,
              content: "6",
              isMathJax: false,
              practiceId: 17770,
            },
          ],
        },
        {
          id: 13612,
          isSectioned: false,
          isFirstSection: false,
          section: null,
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question:
            "A man invested ₦5,000 for 9 months at 4%. What is the simple interest?",
          isQuestionMathJax: false,
          answerId: 53895,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/mathematics/2011/Q4.mp3",
          answerUrl:
            "\\( Using \\  I  = \\frac{PRT}{100} \\\\ P = ₦5000, R = 4\\%, T = \\frac{9}{12},  4\\% = \\frac{4}{100}\\\\ \\therefore = 5000 \\times \\frac{4}{100} \\times \\frac{9}{12} \\\\ = 50 \\times\\ 3 = ₦150 \\)",
          testId: 274,
          options: [
            {
              id: 53894,
              content: "₦250",
              isMathJax: false,
              practiceId: 13612,
            },
            {
              id: 53895,
              content: "₦150",
              isMathJax: false,
              practiceId: 13612,
            },
            {
              id: 53896,
              content: "₦220",
              isMathJax: false,
              practiceId: 13612,
            },
            {
              id: 53897,
              content: "₦130",
              isMathJax: false,
              practiceId: 13612,
            },
          ],
        },
        {
          id: 13615,
          isSectioned: false,
          isFirstSection: false,
          section: null,
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question: "If \\(log_{3} 18 + log_{3} 3 – log_{3} x = 3\\), find x",
          isQuestionMathJax: true,
          answerId: 53908,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/mathematics/2011/Q7.mp3",
          answerUrl:
            "\\(log_3 18 + log_3 3 – log_3 x = 3 \\\\ log_3 18 + log_3 3 – log_3 x = log_{3} 27&nbsp;\\\\ = log_{3} (\\frac{18 \\times\\ 3}{x}) = log_{3} 27 \\\\ =\\frac{18 \\times\\ 3}{x} = 27 \\\\ =  \\frac{54}{x} = \\frac{27}{1} \\\\ = 27x = 54 \\\\ x = \\frac{54}{27}=2 \\)",
          testId: 274,
          options: [
            {
              id: 53906,
              content: "0",
              isMathJax: false,
              practiceId: 13615,
            },
            {
              id: 53907,
              content: "1",
              isMathJax: false,
              practiceId: 13615,
            },
            {
              id: 53908,
              content: "2",
              isMathJax: false,
              practiceId: 13615,
            },
            {
              id: 53909,
              content: "3",
              isMathJax: false,
              practiceId: 13615,
            },
          ],
        },
        {
          id: 13369,
          isSectioned: false,
          isFirstSection: false,
          section: null,
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question:
            "Make n the subject of the formula if \\( w = \\frac{v(2+cn)}{1 – cn}\\)",
          isQuestionMathJax: true,
          answerId: 52934,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/mathematics/2007/Q11.mp3",
          answerUrl:
            "\\( Make \\ n \\ the \\ subject \\ of \\ the \\ formula \\ if \\ w \\ = \\frac{v(2+cn)}{1 – cn}\\)\n\\( \\\\ w(1 – cn) = v(2 + cn) \\)\n\\( \\\\ w – cnw = 2v + cnv \\)\n\\( \\\\ w – 2v = cnv + cnw \\ – collecting \\ like \\ terms \\)\n\\( \\\\ w – 2v = n(cv + cw) \\ – common \\ factor \\ is \\  n \\)\n\\( \\\\ n(cv + cw) = w – 2v \\)\n\\( \\\\ n = \\left( \\frac{w – 2v}{cv +w} \\right)\\)\n\\(\\\\  n = \\frac{1}{c} \\left( \\frac{w – 2v}{v +w} \\right)\\)",
          testId: 269,
          options: [
            {
              id: 52934,
              content:
                "\\(\\frac{1}{c} \\left( \\frac{w – 2v}{v +w} \\right)\\)",
              isMathJax: true,
              practiceId: 13369,
            },
            {
              id: 52935,
              content:
                "\\(\\frac{1}{c} \\left( \\frac{w – 2v}{v - w} \\right)\\)",
              isMathJax: true,
              practiceId: 13369,
            },
            {
              id: 52936,
              content:
                "\\(\\frac{1}{c} \\left( \\frac{w + 2v}{v - w} \\right)\\)",
              isMathJax: true,
              practiceId: 13369,
            },
            {
              id: 52937,
              content:
                "\\(\\frac{1}{c} \\left( \\frac{w + 2v}{v + w} \\right)\\)",
              isMathJax: true,
              practiceId: 13369,
            },
          ],
        },
        {
          id: 13377,
          isSectioned: false,
          isFirstSection: false,
          section: null,
          hasPassage: false,
          isFirstPassage: false,
          passage: null,
          question:
            "The solution of the quadratic inequality \\((x^2 \\ + \\ x \\ – \\ 12) \\geq\\ 0\\)",
          isQuestionMathJax: true,
          answerId: 52966,
          hasVideo: false,
          videoUrl: null,
          audioUrl: "audios/mathematics/2007/Q19.mp3",
          answerUrl:
            "\\(x^2 \\ +\\ x \\ - \\ 12  \\geq\\ 0\\)\n\\( \\\\ x^2 \\ +\\ 4x \\ - \\ 3x \\ -\\ 12 \\geq\\ 0\\)\n\\( \\\\ x(x + 4) – 3(x + 4) \\geq  0 \\)\n\\( \\\\ (x – 3)(x + 4) \\geq  0 \\)\n\\(\\\\  x \\geq 3  \\ or \\   x \\geq -4 \\)",
          testId: 269,
          options: [
            {
              id: 52966,
              content: "\\(x \\geq\\ 3 \\ or\\ x  \\geq\\ -4\\)",
              isMathJax: true,
              practiceId: 13377,
            },
            {
              id: 52967,
              content: "\\(x \\geq\\ 3 \\ or\\ x  \\leq\\ -4\\)",
              isMathJax: true,
              practiceId: 13377,
            },
            {
              id: 52968,
              content: "\\(x \\leq\\ 3 \\ or\\ x  \\leq\\ -4\\)",
              isMathJax: true,
              practiceId: 13377,
            },
            {
              id: 52969,
              content: "\\(x \\geq\\ -3 \\ or\\ x  \\leq\\ 4\\)",
              isMathJax: true,
              practiceId: 13377,
            },
          ],
        },
      ],
    },
  ],
};
