import React from "react";
import { connect } from "react-redux";
import { deleteActiveUser } from "../../creators/activeUserCreator";
import { setHistory } from "../../creators/historyCreator";


const Logout = (props) => {
    
    const onLogoutOut = () => {
        props.deleteActiveUser();
        props.setHistory("login");
      };
    
    return (
       <span onClick={onLogoutOut}  className="c-pointer log-out">
          <div className="d-inline">
            <img className="log-out-nav" src="images/log-out.png" />
          </div>
        </span>
    )
}

export default connect(null, { setHistory, deleteActiveUser })(Logout);

