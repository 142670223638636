import React from "react";

const Avatar = ({ name, sex, id, submitForm }) => {
  return (
    <div className="text-center c-pointer">
      <p className="name">{name}</p>
      <div>
        <img
          onClick={(e) => submitForm(e, id)}
          src={sex === "Male" ? "images/Male__.svg" : "images/Female__.svg"}
          alt={`${name}'s avatar icon`}
          className="img-fluid c-pointer avatar__ mt-0"
        />
      </div>
    </div>
  );
};

export default Avatar;
