import React from "react";

const SecondLevelContent = ({ subjects, setHistory, id, type, name }) => {
  //console.log(subjects)
  console.log("contents in slc", subjects);
  return (
    <button
      className="second-level-style item"
      onClick={() =>
        type === "video"
          ? setHistory(type, id, subjects.content, name)
          : setHistory(type, id)
      }
    >
      <img
        src={
          name.toLowerCase() === "mathematics"
            ? "images/Mathematics.svg"
            : name.toLowerCase() === "english language" ||
              name.toLowerCase() === "use of english"
            ? "images/English.svg"
            : name.toLowerCase() === "basic science and tech"
            ? "images/Basic Science and Technology.svg"
            : name.toLowerCase() === "religion and national value"
            ? "images/RNV.svg"
            : name.toLowerCase() ===
              "computer science and information technology"
            ? "images/comp and it.svg"
            : name.toLowerCase() === "computer science"
            ? "images/Computer Science.svg"
            : name.toLowerCase() === "financial accounting" ||
              name.toLowerCase() === "account"
            ? "images/Account.svg"
            : name.toLowerCase() === "chemistry"
            ? "images/Chemistry.svg"
            : name.toLowerCase() === "cultural and creative arts"
            ? "images/CCA.svg"
            : name.toLowerCase() === "agricultural science"
            ? "images/Agriculture.svg"
            : name.toLowerCase() === "civic education"
            ? "images/civic.svg"
            : name.toLowerCase() === "christian religious knowledge" ||
              name.toLowerCase() === "crs"
            ? "images/CRK.svg"
            : name.toLowerCase() === "economics"
            ? "images/econs.svg"
            : name.toLowerCase() === "further mathematics"
            ? "images/FA.svg"
            : name.toLowerCase() === "geography"
            ? "images/Geography.svg"
            : name.toLowerCase() === "government"
            ? "images/Government.svg"
            : name.toLowerCase() === "literature in english" ||
              name.toLowerCase() === "literature"
            ? "images/Lit in eng.svg"
            : name.toLowerCase() === "physics"
            ? "images/Physics.svg"
            : name.toLowerCase() === "commerce"
            ? "images/commerce.svg"
            : name.toLowerCase() === "biology"
            ? "images/Biology.svg"
            : ""
        }
        alt="study background"
        style={{ width: "82%", display: "inline-block" }}
      />
    </button>
  );
};

export default SecondLevelContent;

// onClick={() =>
// 	setHistory({
// 		path: "video",
// 		id: id,
// 		contents: subjects.content,
// 		subject: name
// 	})
// }
