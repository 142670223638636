export const failure = {
    display: 'block',
    border: '0.5px solid red',
    padding: 10,
    color: 'red'
}

export const defaultTo = {
    display: 'none',
}










