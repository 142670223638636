import { connect } from "react-redux";
import TimeNav from "./TimeNav";
import { timeUp } from "../../../../creators/timeUpCreator";
import { timeValue } from "../../../../creators/timeValueCreator";

const mapStateToProps = ({ time }) => {
  return { time };
};

const ConnectedTimeNav = connect(mapStateToProps, { timeUp, timeValue })(
  TimeNav
);

export default ConnectedTimeNav;
