export const VideoData = [
  {
    "Title ": "JSS",
    Video: [
      {
        Subject: "Basic Science and Tech",
        content: [
          { VideoName: "BST 13.mp4", id: "0", topic: "Geometric Construction" },
          { VideoName: "BST 16.mp4", id: "1", topic: "Wood work machines" },
          {
            VideoName: "BST 1.mp4",
            id: "2",
            topic: "Basic Science and Technology",
          },
          { VideoName: "BST 2.mp4", id: "3", topic: "Safety Guidelines" },
          { VideoName: "BST 5.mp4", id: "4", topic: "Building Materials" },
          { VideoName: "BST 7.mp4", id: "5", topic: "Freehand Sketches" },
          {
            VideoName: "BST 8.mp4",
            id: "6",
            topic: "Wood work and hand tools",
          },
          {
            VideoName: "BST 9.mp4",
            id: "7",
            topic: "Metal work and hand tools",
          },
          { VideoName: "BST 11.mp4", id: "8", topic: "First Aid Care" },
          { VideoName: "BST 14.mp4", id: "9", topic: "Rescue Operations" },
          { VideoName: "BST 15.mp4", id: "10", topic: "Plane Figures" },
          { VideoName: "BST 17.mp4", id: "11", topic: "Belt and Chain drives" },
          { VideoName: "BST 19.mp4", id: "12", topic: "Gears" },
          { VideoName: "BST 20.mp4", id: "13", topic: "Oblique Drawing" },
          {
            VideoName: "BST 34.mp4",
            id: "14",
            topic: "Work,  Energy and Power",
          },
          { VideoName: "BST 35.mp4", id: "15", topic: "Kinetics" },
          { VideoName: "BST 36.mp4", id: "16", topic: "Thermal Energy" },
          {
            VideoName: "BST 37.mp4",
            id: "17",
            topic: "Crude Oil amd Petrochemicals",
          },
          {
            VideoName: "BST 39.mp4",
            id: "18",
            topic: "Drug and Substance Abuse",
          },
          {
            VideoName: "BST 40.mp4",
            id: "19",
            topic: "Resources from Living Things",
          },
          {
            VideoName: "BST 41.mp4",
            id: "20",
            topic: "Resources from Non-Living Things",
          },
          { VideoName: "BST 42.mp4", id: "21", topic: "Light Energy" },
          { VideoName: "BST 43.mp4", id: "22", topic: "Sound Energy" },
          { VideoName: "BST 44.mp4", id: "23", topic: "Magnetism" },
          { VideoName: "BST 45.mp4", id: "24", topic: "Radioactivity" },
          { VideoName: "BST 46.mp4", id: "25", topic: "Electrical Energy" },
          {
            VideoName: "BST 47.mp4",
            id: "26",
            topic: "Ethical issues in science and development",
          },
          {
            VideoName: "BST 4.mp4",
            id: "27",
            topic: "Properties of Materials",
          },
          {
            VideoName: "BST 6.mp4",
            id: "28",
            topic: "Drawing Instruments and Materials",
          },
          {
            VideoName: "BST 21.mp4",
            id: "29",
            topic: "One Perspective Drawing",
          },
          { VideoName: "BST 24.mp4", id: "30", topic: "Orthographic Drawing" },
          { VideoName: "BST 25.mp4", id: "31", topic: "Metalwork" },
          {
            VideoName: "BST 26.mp4",
            id: "32",
            topic: "Scales and Scale Drawing",
          },
          { VideoName: "BST 27.mp4", id: "33", topic: "Machine Motion" },
          {
            VideoName: "BST 49",
            id: "34",
            topic: "Drawing of Plans and Blueprints for buildings",
          },
        ],
      },

      {
        Subject: "Cultural and Creative Arts",
        content: [
          {
            VideoName: "CCA 1.mp4",
            id: "35",
            topic: "Types and Features of Nigerian Traditional Art",
          },
          {
            VideoName: "CCA 2.mp4",
            id: "36",
            topic: "Contemporary Nigerian Arts and Artists",
          },
          {
            VideoName: "CCA 3.mp4",
            id: "37",
            topic: "Meaning and use of motifs",
          },
          { VideoName: "CCA 5.mp4", id: "38", topic: "Lettering" },
          {
            VideoName: "CCA 6.mp4",
            id: "39",
            topic: "Construction and Design",
          },
          {
            VideoName: "CCA 8.mp4",
            id: "40",
            topic: "Introduction to Embroidery",
          },
          { VideoName: "CCA 9.mp4", id: "41", topic: "Knitting" },
          { VideoName: "CCA 10.mp4", id: "42", topic: "Crocheting" },
          { VideoName: "CCA 11.mp4", id: "43", topic: "Batik work" },
          { VideoName: "CCA 12.mp4", id: "44", topic: "Uses of Music" },
          {
            VideoName: "CCA 13.mp4",
            id: "45",
            topic: "Creating Music and Sofa notations",
          },
          {
            VideoName: "CCA 15.mp4",
            id: "46",
            topic: "Opportunities for Career Dramatist",
          },
          {
            VideoName: "CCA 16.mp4",
            id: "47",
            topic: "Process of Choreography",
          },
          { VideoName: "CCA 22.mp4", id: "48", topic: "The Study of Colours" },
          { VideoName: "CCA 24.mp4", id: "49", topic: "Painting" },
          { VideoName: "CCA 25.mp4", id: "50", topic: "Bead Work" },
          { VideoName: "CCA 34.mp4", id: "51", topic: "What is Art" },
          {
            VideoName: "CCA 36.mp4",
            id: "52",
            topic: "Practical works in Tie-Dye using different methods",
          },
          {
            VideoName: "CCA 47.mp4",
            id: "53",
            topic: "Costume and make-up in dance",
          },
          {
            VideoName: "CCA 4.mp4",
            id: "54",
            topic: "Exhibition and Display Techniques",
          },
          { VideoName: "CCA 7.mp4", id: "55", topic: "Marketing of Art Works" },
          {
            VideoName: "CCA 14.mp4",
            id: "56",
            topic: "Drama and Development of Rural Areas",
          },
          { VideoName: "CCA 17.mp4", id: "57", topic: "Prospects of Dance" },
          { VideoName: "CCA 18.mp4", id: "58", topic: "Unity" },
          { VideoName: "CCA 19.mp4", id: "59", topic: "Elements of Design" },
          { VideoName: "CCA 20.mp4", id: "60", topic: "Principles of Design" },
          {
            VideoName: "CCA 21.mp4",
            id: "61",
            topic: "Materials, Tools and Equipments in Designing",
          },
          {
            VideoName: "CCA 23.mp4",
            id: "62",
            topic: "Colour Application in Craft",
          },
          { VideoName: "CCA 28.mp4", id: "63", topic: "Musical Practices" },
          { VideoName: "CCA 29.mp4", id: "64", topic: "Singing" },
          { VideoName: "CCA 30.mp4", id: "65", topic: "Design in the Theatre" },
          { VideoName: "CCA 32.mp4", id: "66", topic: "Types of Dance" },
          { VideoName: "CCA 33.mp4", id: "67", topic: "Dance as a Career" },
          { VideoName: "CCA 35.mp4", id: "68", topic: "Types of Crafts" },
          {
            VideoName: "CCA 36_1.mp4",
            id: "69",
            topic: "Paper Crafts Using Folding Techniwues",
          },
          {
            VideoName: "CCA 41.mp4",
            id: "70",
            topic: "Theories and Fundamentals of Music",
          },
          { VideoName: "CCA 42.mp4", id: "71", topic: "Rudiments of Music" },
          {
            VideoName: "CCA 48.mp4",
            id: "72",
            topic: "Introduction of Choreography",
          },
          { VideoName: "CCA 49.mp4", id: "73", topic: "Contemporary Dance" },
          {
            VideoName: "CCA 50.mp4",
            id: "74",
            topic: "Teamwork and the Sense of Belonging",
          },
        ],
      },
      {
        Subject: "English Language",
        content: [
          { VideoName: "JS English 1.mp4", id: "75", topic: "Parts of Speech" },
          { VideoName: "JS English 2.mp4", id: "76", topic: "Nouns" },
          {
            VideoName: "JS English 24.mp4",
            id: "77",
            topic: "Types of Adverb",
          },
          {
            VideoName: "JS English 25.mp4",
            id: "78",
            topic: "Similes and Metaphors",
          },
          {
            VideoName: "JS English 25_1.mp4",
            id: "79",
            topic: "Comparison of Adverbs",
          },
          { VideoName: "JS English 26.mp4", id: "80", topic: "Conjuction" },
          {
            VideoName: "JS English 27.mp4",
            id: "81",
            topic: "Active and Passive Verbs",
          },
          {
            VideoName: "JS English 27_1.mp4",
            id: "82",
            topic: "Types of Conjuction",
          },
          {
            VideoName: "JS English 28.mp4",
            id: "83",
            topic: "Verbs and its Kind",
          },
          {
            VideoName: "JS English 29.mp4",
            id: "84",
            topic: "Transitive and Intransitive Verb",
          },
          {
            VideoName: "JS English 30.mp4",
            id: "85",
            topic: "Main/Principal/Lexical Verb",
          },
          {
            VideoName: "JS English 31.mp4",
            id: "86",
            topic: "Regular and Irregular Verb",
          },
          {
            VideoName: "JS English 32.mp4",
            id: "87",
            topic: "Finite and Non-finite Verbs",
          },
          {
            VideoName: "JS English 33.mp4",
            id: "88",
            topic: "Auxiliary Verbs",
          },
          {
            VideoName: "JS English 34.mp4",
            id: "89",
            topic: "Direct and Indirect Speech",
          },
          {
            VideoName: "JS English 34_1.mp4",
            id: "90",
            topic: "Some Verbs and their Complexities",
          },
          { VideoName: "JS English 35.mp4", id: "91", topic: "Tenses" },
          {
            VideoName: "JS English 60.mp4",
            id: "92",
            topic: "Descriptive Essay",
          },
          {
            VideoName: "JS English 37.mp4",
            id: "93",
            topic: "Sequence of Tenses",
          },
          {
            VideoName: "JS English 38.mp4",
            id: "94",
            topic: "Active and Passive Voices",
          },
          {
            VideoName: "JS English 39.mp4",
            id: "95",
            topic: "Direct and Indirect Speeches",
          },
          { VideoName: "JS English 40.mp4", id: "96", topic: "Phrases" },
          {
            VideoName: "JS English 41.mp4",
            id: "97",
            topic: "Adverbial Phrase",
          },
          { VideoName: "JS English 42.mp4", id: "98", topic: "Noun Phrase" },
          {
            VideoName: "JS English 43.mp4",
            id: "99",
            topic: "Adjectival Phrase",
          },
          {
            VideoName: "JS English 44.mp4",
            id: "100",
            topic: "Prepositional Phrases",
          },
          { VideoName: "JS English 45.mp4", id: "101", topic: "Question Tag" },
          {
            VideoName: "JS English 46.mp4",
            id: "102",
            topic: "Direct and Indirect/Reported Speech",
          },
          {
            VideoName: "JS English 48.mp4",
            id: "103",
            topic: "Gerunds and Participles",
          },
          { VideoName: "JS English 49.mp4", id: "104", topic: "Clauses" },
          { VideoName: "JS English 50.mp4", id: "105", topic: "Sentences" },
          {
            VideoName: "JS English 51.mp4",
            id: "106",
            topic: "Classification of Sentence",
          },
          {
            VideoName: "JS English 52.mp4",
            id: "107",
            topic: "Antonyms and Synonyms",
          },
          {
            VideoName: "JS English 53.mp4",
            id: "108",
            topic: "Homonyms and Homophones",
          },
          { VideoName: "JS English 54.mp4", id: "109", topic: "Proper Nouns" },
          {
            VideoName: "JS English 54_1.mp4",
            id: "110",
            topic: "Letters and Sounds",
          },
          { VideoName: "JS English 56.mp4", id: "111", topic: "Essay Writing" },
          {
            VideoName: "JS English 57.mp4",
            id: "112",
            topic: "Narrative Essay",
          },
          {
            VideoName: "JS English 58.mp4",
            id: "113",
            topic: "Argumentative Essay",
          },
          {
            VideoName: "JS English 59.mp4",
            id: "114",
            topic: "Expository Essay",
          },
          {
            VideoName: "JS English 61.mp4",
            id: "115",
            topic: "Article Writing",
          },
          { VideoName: "JS English 64.mp4", id: "116", topic: "Synonyms" },
          { VideoName: "JS English 65.mp4", id: "117", topic: "Antonyms" },
        ],
      },
      {
        Subject: "Mathematics",
        content: [
          {
            VideoName: "JS Maths 1.mp4",
            id: "118",
            topic: "Brief History of Mathematics",
          },
          { VideoName: "JS Maths 2.mp4", id: "119", topic: "Roman Numerals I" },
          {
            VideoName: "JS Maths 3.mp4",
            id: "120",
            topic: "Addition of Whole Numbers",
          },
          {
            VideoName: "JS Maths 5.mp4",
            id: "121",
            topic: "Addition and Subtraction of Decimal Numbers",
          },
          {
            VideoName: "JS Maths 8.mp4",
            id: "122",
            topic: "Addition and Subtraction of Numbers with Letters",
          },
          { VideoName: "JS Maths 9.mp4", id: "123", topic: "Additive Inverse" },
          { VideoName: "JS Maths 11.mp4", id: "124", topic: "Prime Factors" },
          {
            VideoName: "JS Maths 12.mp4",
            id: "125",
            topic: "Counting in Base 2",
          },
          {
            VideoName: "JS Maths 13.mp4",
            id: "126",
            topic: "Highest Common Factor",
          },
          {
            VideoName: "JS Maths 14.mp4",
            id: "127",
            topic: "Counting in Base 2 II",
          },
          {
            VideoName: "JS Maths 15.mp4",
            id: "128",
            topic: "Conversion of Basic Numerals to Binary",
          },
          {
            VideoName: "JS Maths 17.mp4",
            id: "129",
            topic: "Addition and Subtraction of Fractions",
          },
          {
            VideoName: "JS Maths 18.mp4",
            id: "130",
            topic: "Multiplication of Fractions",
          },
          {
            VideoName: "JS Maths 19.mp4",
            id: "131",
            topic: "Division of Fractions",
          },
          {
            VideoName: "JS Maths 20.mp4",
            id: "132",
            topic: "Addition of Numbers in Base 2 Numerals",
          },
          {
            VideoName: "JS Maths 21.mp4",
            id: "133",
            topic: "Subtraction of Number Base 2 Numerals",
          },
          {
            VideoName: "JS Maths 22.mp4",
            id: "134",
            topic: "Multipication of Number Base 2 Numerals",
          },
          { VideoName: "JS Maths 24.mp4", id: "135", topic: "Simple Equation" },
          {
            VideoName: "JS Maths 25.mp4",
            id: "136",
            topic: "Simple Equations with Fractions",
          },
          { VideoName: "JS Maths 26.mp4", id: "137", topic: "Plane Shapes" },
          {
            VideoName: "JS Maths 27.mp4",
            id: "138",
            topic: "Three Dimensional Shapes",
          },
          {
            VideoName: "JS Maths 29.mp4",
            id: "139",
            topic: "Introduction to Angles",
          },
          {
            VideoName: "JS Maths 30.mp4",
            id: "140",
            topic: "Need for Statistics",
          },
          { VideoName: "JS Maths 31.mp4", id: "141", topic: "Data Collection" },
          {
            VideoName: "JS Maths 32.mp4",
            id: "142",
            topic: "Data Presentation",
          },
          { VideoName: "JS Maths 33.mp4", id: "143", topic: "Approximation" },
          {
            VideoName: "JS Maths 36.mp4",
            id: "144",
            topic: "Area of a Quadrilateral",
          },
          {
            VideoName: "JS Maths 37.mp4",
            id: "145",
            topic: "Perimeter of a Quadrilateral",
          },
          {
            VideoName: "JS Maths 38.mp4",
            id: "146",
            topic: "Calculating the Perimeter of a Trapezium",
          },
          {
            VideoName: "JS Maths 40.mp4",
            id: "147",
            topic: "Terms of a circle",
          },
          {
            VideoName: "JS Maths 41.mp4",
            id: "148",
            topic: "Circumference of a circle",
          },
          {
            VideoName: "JS Maths 42.mp4",
            id: "149",
            topic: "Area of a Circle",
          },
          {
            VideoName: "JS Maths 43.mp4",
            id: "150",
            topic: "Area of a Triangle",
          },
          {
            VideoName: "JS Maths 46.mp4",
            id: "151",
            topic: "Rational and Non Rational Numbers",
          },
          {
            VideoName: "JS Maths 48.mp4",
            id: "152",
            topic: "Concept of Substitution",
          },
          {
            VideoName: "JS Maths 49.mp4",
            id: "153",
            topic: "Simultaneous Linear Equation: Substitution Method",
          },
          {
            VideoName: "JS Maths 50.mp4",
            id: "154",
            topic: "Simultaneous Linear Equation: Elimination Method",
          },
          { VideoName: "JS Maths 51.mp4", id: "155", topic: "Mean of Data" },
          { VideoName: "JS Maths 52.mp4", id: "156", topic: "Median of Data" },
          { VideoName: "JS Maths 53.mp4", id: "157", topic: "Mode of Data" },
          { VideoName: "JS Maths 54.mp4", id: "158", topic: "Range of a Data" },
          {
            VideoName: "JS Maths 56.mp4",
            id: "159",
            topic: "Fractions with Unknown Denominators",
          },
          {
            VideoName: "JS Maths 57.mp4",
            id: "160",
            topic: "Arrangement of Fractions in Order of Magnitude",
          },
          {
            VideoName: "JS Maths 59.mp4",
            id: "161",
            topic: "Calculating the Angle of a Polygon",
          },
          { VideoName: "JS Maths 60.mp4", id: "162", topic: "Proportions" },
          { VideoName: "JS Maths 62.mp4", id: "163", topic: "Ratios" },
          {
            VideoName: "JS Maths 64.mp4",
            id: "164",
            topic: "Expression of Standard Forms to Ordinary Forms",
          },
          {
            VideoName: "JS Maths 65.mp4",
            id: "165",
            topic: "Pythagoras Theorem",
          },
          {
            VideoName: "JS Maths 66.mp4",
            id: "166",
            topic: "Angle of Elevation",
          },
          {
            VideoName: "JS Maths 67.mp4",
            id: "167",
            topic: "Angle of Depression",
          },
          { VideoName: "JS Maths 69.mp4", id: "168", topic: "Histogram" },
          { VideoName: "JS Maths 68.mp4", id: "169", topic: "Bar Chart" },
          {
            VideoName: "JS Maths 70.mp4",
            id: "170",
            topic: "Expansion of Algebraic Expressions",
          },
          { VideoName: "JS Maths 71.mp4", id: "171", topic: "Simple Interest" },
          {
            VideoName: "JS Maths 72.mp4",
            id: "172",
            topic: "Compound Interest",
          },
          {
            VideoName: "JS Maths 74.mp4",
            id: "173",
            topic: "Direct Variation",
          },
          {
            VideoName: "JS Maths 75.mp4",
            id: "174",
            topic: "Inverse Variation",
          },
          {
            VideoName: "JS Maths 77.mp4",
            id: "175",
            topic: "Sector of a Circle",
          },
          {
            VideoName: "JS Maths 79.mp4",
            id: "176",
            topic: "Conversion from Base 10 to any other Base",
          },
          {
            VideoName: "JS Maths 80.mp4",
            id: "177",
            topic: "Conversion from any other base to Base 10",
          },
          {
            VideoName: "JS Maths 83.mp4",
            id: "178",
            topic: "Expression of Quantities as Fractions",
          },
          {
            VideoName: "JS Maths 85.mp4",
            id: "179",
            topic: "Linear Inequalities",
          },
          {
            VideoName: "JS Maths 87.mp4",
            id: "180",
            topic: "Indices: Introduction and Rules",
          },
          {
            VideoName: "JS Maths 88.mp4",
            id: "181",
            topic: "Multiplication of Inequalities with Integers",
          },
          {
            VideoName: "JS Maths 89.mp4",
            id: "182",
            topic: "Division of Inequalities with Integers",
          },
          {
            VideoName: "JS Maths 92.mp4",
            id: "183",
            topic: "Introduction to Sequence",
          },
          {
            VideoName: "JS Maths 95.mp4",
            id: "184",
            topic: "Common Difference",
          },
          { VideoName: "JS Maths 96.mp4", id: "185", topic: "Common Ratio" },
          {
            VideoName: "JS Maths 98.mp4",
            id: "186",
            topic: "Volume of a Cone",
          },
          {
            VideoName: "JS Maths 99.mp4",
            id: "187",
            topic: "Difference of Two Squares",
          },
          {
            VideoName: "JS Maths 100.mp4",
            id: "188",
            topic: "Factorization of Quadratic Expressions",
          },
          {
            VideoName: "JS Maths 101.mp4",
            id: "189",
            topic: "Solving Quadratic Equations by Factorization",
          },
          {
            VideoName: "JS Maths 102.mp4",
            id: "190",
            topic: "Alternate Method of Solving Quadratic Equation",
          },
          {
            VideoName: "JS Maths 104.mp4",
            id: "191",
            topic: "Change of Subject of Formula",
          },
          {
            VideoName: "JS Maths 107.mp4",
            id: "192",
            topic:
              "Bisection of an Angle and Construction of a 90 Degree Angle",
          },
          {
            VideoName: "JS Maths 108.mp4",
            id: "193",
            topic: "Construction of a 45 and 135 Degree Angles",
          },
          {
            VideoName: "JS Maths 109.mp4",
            id: "194",
            topic: "Construction of a 60 and 120 Degree Angles",
          },
          {
            VideoName: "JS Maths 110.mp4",
            id: "195",
            topic: "Construction of a 30, 150 and 15 Degree Angles",
          },
          {
            VideoName: "JS Maths 113.mp4",
            id: "196",
            topic: "The Mean and Tally System",
          },
          {
            VideoName: "JS Maths 111.mp4",
            id: "197",
            topic: "Construction of a 75 and 105 Degree Angles",
          },
          {
            VideoName: "JS Maths 114.mp4",
            id: "198",
            topic: "Construction of Frequency Table with Tally",
          },
          {
            VideoName: "JS Maths 120.mp4",
            id: "199",
            topic: "Bisection of a Straight Line",
          },
          {
            VideoName: "JS Maths 121.mp4",
            id: "200",
            topic: "Rules in Angles and Triangles",
          },
          { VideoName: "JS Maths 122.mp4", id: "201", topic: "Pie Chart" },
        ],
      },
      {
        Subject: "Religion and National Value",
        content: [
          { VideoName: "RNV 7.mp4", id: "202", topic: "Jesus and His Family" },
          {
            VideoName: "RNV 8.mp4",
            id: "203",
            topic: "The Baptism and Temptation of Jesus",
          },
          {
            VideoName: "RNV 9.mp4",
            id: "204",
            topic: "The Call of the First Disciples",
          },
          {
            VideoName: "RNV 10_1.mp4",
            id: "205",
            topic: "The Sermon on the Mount I",
          },
          {
            VideoName: "RNV 10_2.mp4",
            id: "206",
            topic: "The Sermon on the Mount II",
          },
          {
            VideoName: "RNV 12.mp4",
            id: "207",
            topic: "Jesus' Death and Resurrection",
          },
          {
            VideoName: "RNV 15.mp4",
            id: "208",
            topic: "The Promise of the Holy Spirit",
          },
          {
            VideoName: "RNV 16.mp4",
            id: "209",
            topic: "The Persecution of Early Believers",
          },
          {
            VideoName: "RNV 18.mp4",
            id: "210",
            topic: "Paul's first Missionary Journey",
          },
          { VideoName: "RNV 21.mp4", id: "211", topic: "The Family" },
          {
            VideoName: "RNV 24.mp4",
            id: "212",
            topic: "Common Social Problems",
          },
          {
            VideoName: "RNV 26.mp4",
            id: "213",
            topic: "The Role of the Extended Family in a Child's Development",
          },
          {
            VideoName: "RNV 28.mp4",
            id: "214",
            topic: "Harmful Traditional Practices",
          },
          { VideoName: "RNV 30.mp4", id: "215", topic: "Cultism" },
          { VideoName: "RNV 31.mp4", id: "216", topic: "Purposes of Marriage" },
          {
            VideoName: "RNV 33.mp4",
            id: "217",
            topic: "Drug Trafficking and Prevention of Drug Trafficking",
          },
          {
            VideoName: "RNV 35.mp4",
            id: "218",
            topic: "Positive Group behaviour",
          },
          {
            VideoName: "RNV 45.mp4",
            id: "219",
            topic: "Nigeria as a Federation",
          },
          {
            VideoName: "RNV 50.mp4",
            id: "220",
            topic: "Democracy and Rules of Democracy",
          },
          {
            VideoName: "RNV 52.mp4",
            id: "221",
            topic: "Elections, Electoral Bodies and Electoral Malpractices",
          },
          { VideoName: "RNV 54.mp4", id: "222", topic: "Honesty" },
          { VideoName: "RNV 55.mp4", id: "223", topic: "Cooperation" },
          { VideoName: "RNV 56.mp4", id: "224", topic: "Self Reliance" },
          {
            VideoName: "RNV 58.mp4",
            id: "225",
            topic: "Rights and Duties of a Citizen",
          },
          { VideoName: "RNV 67.mp4", id: "226", topic: "Crime" },
          { VideoName: "RNV 14.mp4", id: "227", topic: "Easter Story" },
          {
            VideoName: "RNV 51.mp4",
            id: "228",
            topic: "Elections and Voters' Responsibilities",
          },
          { VideoName: "RNV 66.mp4", id: "229", topic: "Emergency Management" },
          // { "VideoName": "RNV 18.mp4", "id": "292", "topic": "Paul's First Missionary Journey" },
          // { "VideoName": "RNV 26.mp4", "id": "293", "topic": "The role of the extended family in child Development" },
          // { "VideoName": "RNV 30.mp4", "id": "294", "topic": "Cultism" },
          // { "VideoName": "RNV 33.mp4", "id": "295", "topic": "Drug Trafficking" },
          // { "VideoName": "RNV 35.mp4", "id": "296", "topic": "Positive Group Behaviour" },
          // { "VideoName": "RNV 45.mp4", "id": "297", "topic": "Nigeria as a Federation" },
          // { "VideoName": "RNV 50.mp4", "id": "298", "topic": "Democracy" },
          // { "VideoName": "RNV 56.mp4", "id": "299", "topic": "Self Reliance" },
        ],
      },
      {
        Subject: "Computer Science",
        content: [
          {
            VideoName: "SS Computer Lesson 1.mp4",
            id: "528",
            topic: "Overview of Computer Systems",
          },
          {
            VideoName: "SS Computer Lesson 4.mp4",
            id: "529",
            topic: "Components and Uses of Early Computing Devices",
          },
          {
            VideoName: "SS Computer Lesson 47.mp4",
            id: "530",
            topic: "Safety Measure in Computer Science",
          },
          {
            VideoName: "SS Computer Lesson 23.mp4",
            id: "735",
            topic: "Concept and handling of files",
          },
          {
            VideoName: "SS Computer Lesson 24.mp4",
            id: "736",
            topic: "Systems Development Life Cycle",
          },
          {
            VideoName: "SS Computer Lesson 33.mp4",
            id: "737",
            topic: "Copyrights in Computer Science",
          },
          {
            VideoName: "SS Computer Lesson 34.mp4",
            id: "738",
            topic: "Ownership to Texts, Images & Videos",
          },
          {
            VideoName: "SS Computer Lesson 35.mp4",
            id: "739",
            topic: "Privacy of Audio and Video Software",
          },
          {
            VideoName: "SS Computer Lesson 36.mp4",
            id: "740",
            topic: "Identity Theft and Internet Fraud",
          },
          {
            VideoName: "SS Computer Lesson 38.mp4",
            id: "741",
            topic: "High Level Languages",
          },
          {
            VideoName: "SS Computer Lesson 40.mp4",
            id: "742",
            topic: "Security Breaches and Control",
          },
          {
            VideoName: "SS Computer Lesson 41.mp4",
            id: "743",
            topic: "Computer Viruses",
          },
          {
            VideoName: "SS Computer Lesson 42.mp4",
            id: "744",
            topic: "Computer Maintenance",
          },
          {
            VideoName: "SS Computer Lesson 44.mp4",
            id: "745",
            topic: "Operating System",
          },
          {
            VideoName: "SS Computer Lesson 45.mp4",
            id: "746",
            topic: "Microsoft Word",
          },
          {
            VideoName: "SS Computer Lesson 48.mp4",
            id: "747",
            topic: "Computer Memory Unit",
          },
          {
            VideoName: "SS Computer Lesson 49.mp4",
            id: "748",
            topic: "ROM and RAM",
          },
          {
            VideoName: "SS Computer Lesson 50.mp4",
            id: "749",
            topic: "Database",
          },
          {
            VideoName: "SS Computer Lesson 51.mp4",
            id: "750",
            topic: "Database Management Systems (DBMS)",
          },
          {
            VideoName: "SS Computer Lesson 52.mp4",
            id: "751",
            topic: "Social Media",
          },
          {
            VideoName: "SS Computer Lesson 53.mp4",
            id: "752",
            topic: "Social Media Networks and their Features",
          },
          {
            VideoName: "SS Computer Lesson 54.mp4",
            id: "753",
            topic: "Basic Terms in Database Management Systems (DBMS)",
          },
          {
            VideoName: "SS Comuter Lessonp 56.mp4",
            id: "754",
            topic: "Careers in the ICT Industry",
          },
          // { "VideoName": "SS Computer Lesson 1.mp4", "id": "270", "topic": "Overview of Computer Systems" },
          // { "VideoName": "SS Computer Lesson 4.mp4", "id": "271", "topic": "Features, Components Uses of Early Computing Devices" },
          {
            VideoName: "SS Computer Lesson 8.mp4",
            id: "272",
            topic: "ENIAC, EDVAC and UNIVAC 1",
          },
          {
            VideoName: "SS Computer Lesson 10.mp4",
            id: "273",
            topic: "Computer Systems and Application Software",
          },
          {
            VideoName: "SS Computer Lesson 11.mp4",
            id: "274",
            topic: "Computer Systems and Application Software II",
          },
          {
            VideoName: "SS Computer Lesson 12.mp4",
            id: "275",
            topic: "Communication Systems under Computer Science",
          },
          {
            VideoName: "SS Computer Lesson 13.mp4",
            id: "276",
            topic: "Application areas of ICT",
          },
          {
            VideoName: "SS Computer Lesson 14.mp4",
            id: "277",
            topic: "Word Processing",
          },
          {
            VideoName: "SS Computer Lesson 15.mp4",
            id: "278",
            topic: "Application of Word Processing Packages",
          },
          {
            VideoName: "SS Computer Lesson 16.mp4",
            id: "279",
            topic: "Presentation Packages",
          },
          {
            VideoName: "SS Computer Lesson 17.mp4",
            id: "280",
            topic: "Application of Presentation Packages",
          },
          {
            VideoName: "SS Computer Lesson 18.mp4",
            id: "281",
            topic: "The Central Processing Unit (CPU)",
          },
          {
            VideoName: "SS Computer Lesson 19.mp4",
            id: "282",
            topic: "Introduction to Logic Gates",
          },
          {
            VideoName: "SS Computer Lesson 20.mp4",
            id: "283",
            topic: "Alternative Logic Gates",
          },
          {
            VideoName: "SS Computer Lesson 22.mp4",
            id: "284",
            topic: "Computer SOftware and Hardware",
          },
          // { "VideoName": "SS Computer Lesson 24.mp4", "id": "285", "topic": "System Development Life Cycle (SDLC)" },
          {
            VideoName: "SS Computer Lesson 25.mp4",
            id: "286",
            topic: "Program Development",
          },
          {
            VideoName: "SS Computer Lesson 26.mp4",
            id: "287",
            topic: "Algorithms and Flowchart",
          },
          {
            VideoName: "SS Computer Lesson 27.mp4",
            id: "288",
            topic: "The Internet",
          },
          {
            VideoName: "SS Computer Lesson 29.mp4",
            id: "289",
            topic: "Networking",
          },
          {
            VideoName: "SS Computer Lesson 30.mp4",
            id: "290",
            topic: "World Wide Web (WWW)",
          },
          {
            VideoName: "SS Computer Lesson 31.mp4",
            id: "291",
            topic: "Cables and Connectors",
          },
        ],
      },
      {
        Subject: "Computer Science and Information Technology",
        content: [
          {
            VideoName: "JS Computer 1.mp4",
            id: "230",
            topic: "Computers: Definition and Overview",
          },
          {
            VideoName: "JS Computer 2.mp4",
            id: "231",
            topic: "The History of Computers",
          },
          // { "VideoName": "JS Computer 3.mp4", "id": "232", "topic": "The Generations of Computers (Gen I & II)" },
          {
            VideoName: "JS Computer 4.mp4",
            id: "233",
            topic: "The Generations of Computers (Gen III & V)",
          },
          {
            VideoName: "JS Computer 5.mp4",
            id: "234",
            topic: "Types of Modern Day Computers",
          },
          {
            VideoName: "JS Computer 6.mp4",
            id: "235",
            topic: "Parts of a Computer",
          },
          {
            VideoName: "JS Computer 7.mp4",
            id: "236",
            topic: "Computer Maintenance Ethics",
          },
          {
            VideoName: "JS Computer 8.mp4",
            id: "237",
            topic: "Setting Up a Computer",
          },
          {
            VideoName: "JS Computer 9.mp4",
            id: "238",
            topic: "Application Software",
          },
          {
            VideoName: "JS Computer 11.mp4",
            id: "239",
            topic: "Fundamental Application Programs for a Computer",
          },
          {
            VideoName: "JS Computer 13.mp4",
            id: "240",
            topic: "Word Processing (Shortcourt Command Systems)",
          },
          { VideoName: "JS Computer 15.mp4", id: "241", topic: "The Internet" },
          {
            VideoName: "JS Computer 16.mp4",
            id: "242",
            topic: "The History of the Internet",
          },
          {
            VideoName: "JS Computer 17.mp4",
            id: "243",
            topic: "The Growth of the internet in Africa",
          },
          {
            VideoName: "JS Computer 18.mp4",
            id: "244",
            topic: "The internet in Today's world",
          },
          {
            VideoName: "JS Computer 19.mp4",
            id: "245",
            topic: "World Wide Web (WWW)",
          },
          { VideoName: "JS Computer 20.mp4", id: "246", topic: "Social Media" },
          {
            VideoName: "JS Computer 21.mp4",
            id: "247",
            topic: "Internet Management",
          },
          {
            VideoName: "JS Computer 23.mp4",
            id: "248",
            topic: "Internet Hacking",
          },
          {
            VideoName: "JS Computer 24.mp4",
            id: "249",
            topic: "Internet Security",
          },
          { VideoName: "JS Computer 25.mp4", id: "250", topic: "Viruses" },
          {
            VideoName: "JS Computer 26.mp4",
            id: "251",
            topic: "Internet Terminologies",
          },
          {
            VideoName: "JS Computer 27.mp4",
            id: "252",
            topic: "2G, 3G and 4G",
          },
          {
            VideoName: "JS Computer 31.mp4",
            id: "253",
            topic: "Disadvantages of the internet",
          },
          {
            VideoName: "JS Computer 35.mp4",
            id: "254",
            topic: "Internet Topology",
          },
          {
            VideoName: "JS Computer 32.mp4",
            id: "255",
            topic: "Major Intenet Service Providers in the World",
          },
          {
            VideoName: "JS Computer 33.mp4",
            id: "256",
            topic: "Local Area Network (LAN)",
          },
          {
            VideoName: "JS Computer 34.mp4",
            id: "257",
            topic: "Legal Matters in IT",
          },
          {
            VideoName: "JS Computer 36.mp4",
            id: "258",
            topic: "History of the Cell Phones",
          },
          {
            VideoName: "JS Computer 37.mp4",
            id: "259",
            topic: "Mobile Software Systems",
          },
          {
            VideoName: "JS Computer 38.mp4",
            id: "260",
            topic: "Mobile Application Programs",
          },
          {
            VideoName: "JS Computer 39.mp4",
            id: "261",
            topic: "Bluetooth and File Transfer",
          },
          {
            VideoName: "JS Computer 41.mp4",
            id: "262",
            topic: "TV Transmission",
          },
          { VideoName: "JS Computer 42.mp4", id: "263", topic: "Programming" },
          {
            VideoName: "JS Computer 43.mp4",
            id: "264",
            topic: "Computer Language",
          },
          {
            VideoName: "JS Computer 44.mp4",
            id: "265",
            topic: "Programming Languages II",
          },
          { VideoName: "JS Computer 45.mp4", id: "266", topic: "Python" },
          { VideoName: "JS Computer 47.mp4", id: "267", topic: "Java and C++" },
          {
            VideoName: "JS Computer 50.mp4",
            id: "268",
            topic: "Advantages of Studying Computer Science",
          },
          { VideoName: "JS Computer 46.mp4", id: "269", topic: "Visual Basic" },
        ],
      },
    ],
  },
  {
    Title: "SSS",
    Video: [
      {
        Subject: "English Language",
        content: [
          {
            VideoName: "SS English 61.mp4",
            id: "300",
            topic: "Prepositions with Negative Position and Negative Direction",
          },
          {
            VideoName: "SS English 62.mp4",
            id: "301",
            topic: "End Positions of Prepositions",
          },
          { VideoName: "SS English 63.mp4", id: "302", topic: "Conjuctions" },
          {
            VideoName: "SS English 64.mp4",
            id: "303",
            topic: "Conjunctions Types",
          },
          { VideoName: "SS English 65.mp4", id: "304", topic: "Interjections" },
          {
            VideoName: "SS English 66.mp4",
            id: "305",
            topic: "Punctuation Marks",
          },
          {
            VideoName: "SS English 67.mp4",
            id: "306",
            topic: "Punctuation Marks II",
          },
          {
            VideoName: "SS English 68.mp4",
            id: "307",
            topic: "Punctuation Marks III",
          },
          { VideoName: "SS English 69.mp4", id: "308", topic: "The Phrase" },
          {
            VideoName: "SS English 70.mp4",
            id: "309",
            topic: "Functions of the Noun Phrase",
          },
          {
            VideoName: "SS English 71.mp4",
            id: "310",
            topic: "The Adjectival Phrase",
          },
          {
            VideoName: "SS English 72.mp4",
            id: "311",
            topic: "Adverbial Phrase",
          },
          { VideoName: "SS English 73.mp4", id: "312", topic: "The Clause" },
          {
            VideoName: "SS English 74.mp4",
            id: "313",
            topic: "Subordinate Clause Types",
          },
          {
            VideoName: "SS English 75.mp4",
            id: "314",
            topic: "Functions of the Noun Clause",
          },
          {
            VideoName: "SS English 76.mp4",
            id: "315",
            topic: "Adverbial Clause",
          },
          {
            VideoName: "SS English 77.mp4",
            id: "316",
            topic: "Adverbial Clause Types",
          },
          { VideoName: "SS English 78.mp4", id: "317", topic: "The Sentence" },
          {
            VideoName: "SS English 79.mp4",
            id: "318",
            topic: "The Sentence II (Classification)",
          },
          {
            VideoName: "SS English 80.mp4",
            id: "319",
            topic: "Foreign Elements in English",
          },
          {
            VideoName: "SS English 81.mp4",
            id: "320",
            topic: "Sentence Patterns/Structure",
          },
          { VideoName: "SS English 82.mp4", id: "321", topic: "Essay Writing" },
          {
            VideoName: "SS English 83.mp4",
            id: "322",
            topic: "Essay Writing II",
          },
          { VideoName: "SS English 84.mp4", id: "323", topic: "Essay Types" },
          {
            VideoName: "SS English 85.mp4",
            id: "324",
            topic: "The Descriptive Essay",
          },
          {
            VideoName: "SS English 86.mp4",
            id: "325",
            topic: "The Expository Essay",
          },
          {
            VideoName: "SS English 87.mp4",
            id: "326",
            topic: "Speech and Article Writing",
          },
          {
            VideoName: "SS English 88.mp4",
            id: "327",
            topic: "Article Writing",
          },
          {
            VideoName: "SS English 89.mp4",
            id: "328",
            topic: "Letter Writing",
          },
          {
            VideoName: "SS English 90.mp4",
            id: "329",
            topic: "Formal Letter Writing",
          },
          { VideoName: "SS English 91.mp4", id: "330", topic: "Idioms" },
          { VideoName: "SS English 92.mp4", id: "331", topic: "Synonyms" },
          { VideoName: "SS English 93.mp4", id: "332", topic: "Antonyms" },
          { VideoName: "SS English 94.mp4", id: "333", topic: "Antonyms II" },
          { VideoName: "SS English 95.mp4", id: "334", topic: "Oral English" },
          {
            VideoName: "SS English 96.mp4",
            id: "335",
            topic: "Consonant Sounds",
          },
          {
            VideoName: "SS English 97.mp4",
            id: "336",
            topic: "The Vowel Sounds",
          },
          {
            VideoName: "SS English 99.mp4",
            id: "337",
            topic: "Consonant Clusters",
          },
          { VideoName: "SS English 100.mp4", id: "338", topic: "The Syllable" },
          {
            VideoName: "SS English 101.mp4",
            id: "339",
            topic: "Stress in English Language",
          },
          { VideoName: "SS English 102.mp4", id: "340", topic: "Stress II" },
          { VideoName: "SS English 103.mp4", id: "341", topic: "Intonations" },
          {
            VideoName: "SS English 104.mp4",
            id: "342",
            topic: "Intonation Patterns",
          },
          {
            VideoName: "SS English 105.mp4",
            id: "343",
            topic: "Sentence and Emphatic Stress",
          },
          {
            VideoName: "SS English 106.mp4",
            id: "344",
            topic: "Reading Comprehension",
          },
          {
            VideoName: "SS English 107.mp4",
            id: "345",
            topic: "Comprehension Skills",
          },
          {
            VideoName: "SS English 108.mp4",
            id: "346",
            topic: "Comprehension Skills II",
          },
          {
            VideoName: "SS English 109.mp4",
            id: "347",
            topic: "Identifying Gramatical Functions",
          },
          {
            VideoName: "SS English 110.mp4",
            id: "348",
            topic: "Identifying Figurative Expressions",
          },
          {
            VideoName: "SS English 111.mp4",
            id: "349",
            topic: "Sumary Writing",
          },
          {
            VideoName: "SS English 112.mp4",
            id: "350",
            topic: "Varieties of English",
          },
          {
            VideoName: "SS English 113.mp4",
            id: "351",
            topic: "Common Errors in English Language",
          },
          {
            VideoName: "SS English 114.mp4",
            id: "352",
            topic: "Wrongly Used Idioms",
          },
          {
            VideoName: "SS English 115.mp4",
            id: "353",
            topic: "Summary Writing -Sample Summary Exercise",
          },
          {
            VideoName: "SS English 1.mp4",
            id: "755",
            topic: "Introduction to English Grammar",
          },
          { VideoName: "SS English 2.mp4", id: "756", topic: "Noun Types" },
          { VideoName: "SS English 3.mp4", id: "757", topic: "Abstract Nouns" },
          {
            VideoName: "SS English 4.mp4",
            id: "758",
            topic: "Regular and Irregular Nouns",
          },
          { VideoName: "SS English 5.mp4", id: "759", topic: "Concrete Noun" },
          {
            VideoName: "SS English 6.mp4",
            id: "760",
            topic: "Uncountable Noun",
          },
          { VideoName: "SS English 7.mp4", id: "761", topic: "Countable Noun" },
          {
            VideoName: "SS English 8.mp4",
            id: "762",
            topic: "Countable Noun and Gender",
          },
          {
            VideoName: "SS English 9.mp4",
            id: "763",
            topic: "Functions of Noun",
          },
          { VideoName: "SS English 10.mp4", id: "764", topic: "Articles" },
          { VideoName: "SS English 11.mp4", id: "765", topic: "Articles II" },
          {
            VideoName: "SS English 12.mp4",
            id: "766",
            topic: "Possessive Noun",
          },
          {
            VideoName: "SS English 13.mp4",
            id: "767",
            topic: "Possessive Noun II",
          },
          {
            VideoName: "SS English 14.mp4",
            id: "768",
            topic: "Compound Nouns",
          },
          {
            VideoName: "SS English 15.mp4",
            id: "769",
            topic: "Plural of Compound Nouns",
          },
          {
            VideoName: "SS English 16.mp4",
            id: "770",
            topic: "Borrowed Words and their Plurals",
          },
          {
            VideoName: "SS English 17.mp4",
            id: "771",
            topic: "Borrowed Words and their Plurals II",
          },
          {
            VideoName: "SS English 22.mp4",
            id: "772",
            topic: "Functions of the Pronoun",
          },
          { VideoName: "SS English 23.mp4", id: "773", topic: "The Adjective" },
          {
            VideoName: "SS English 24.mp4",
            id: "774",
            topic: "Functions of the Adjectives",
          },
          {
            VideoName: "SS English 25.mp4",
            id: "775",
            topic: "Types of Adjectives",
          },
          {
            VideoName: "SS English 26.mp4",
            id: "776",
            topic: "Comparison of Adjectives",
          },
          {
            VideoName: "SS English 27.mp4",
            id: "777",
            topic: "Comparison of Adjectives II",
          },
          {
            VideoName: "SS English 28.mp4",
            id: "778",
            topic: "Order of Adjectives",
          },
          { VideoName: "SS English 29.mp4", id: "779", topic: "The Verb" },
          {
            VideoName: "SS English 30.mp4",
            id: "780",
            topic: "Auxilliary Verbs",
          },
          {
            VideoName: "SS English 36.mp4",
            id: "781",
            topic:
              "Uses of the Present Perfect and Present Perfect Continuous Tense",
          },
          {
            VideoName: "SS English 37.mp4",
            id: "782",
            topic: "The Past Tense",
          },
          {
            VideoName: "SS English 38.mp4",
            id: "783",
            topic: "The Past Continuous Tense",
          },
          {
            VideoName: "SS English 39 Future Continuous Tense.mp4",
            id: "784",
            topic: "Future Continous tense",
          },
          {
            VideoName: "SS English 39 Future Tense.mp4",
            id: "785",
            topic: "Future Tense",
          },
          { VideoName: "SS English 41.mp4", id: "786", topic: "Regular Verb" },
          {
            VideoName: "SS English 42.mp4",
            id: "787",
            topic: "Regular Verb Cont'd",
          },
          {
            VideoName: "SS English 43.mp4",
            id: "788",
            topic: "Irregular Verb",
          },
          { VideoName: "SS English 44.mp4", id: "789", topic: "Phrasal Verb" },
          {
            VideoName: "SS English 45.mp4",
            id: "790",
            topic: "Active Voice|Passive Voice",
          },
          {
            VideoName: "SS English 46.mp4",
            id: "791",
            topic: "Rules Guiding Active and Passive Voice",
          },
          {
            VideoName: "SS English 47.mp4",
            id: "792",
            topic: "Direct and Indirect Speech",
          },
          {
            VideoName: "SS English 48.mp4",
            id: "793",
            topic: "Direct and Indirect Speech II",
          },
          {
            VideoName: "SS English 49.mp4",
            id: "794",
            topic: "Changing Question to the Indirect Speech",
          },
          { VideoName: "SS English 50.mp4", id: "795", topic: "Question Tags" },
          {
            VideoName: "SS English 51.mp4",
            id: "796",
            topic: "Concord | Agreement",
          },
          {
            VideoName: "SS English 52.mp4",
            id: "797",
            topic: "Rule of COncord II",
          },
          {
            VideoName: "SS English 53.mp4",
            id: "798",
            topic: "Rule of COncord",
          },
          {
            VideoName: "SS English 54.mp4",
            id: "799",
            topic: "Rules of Concord IV",
          },
          { VideoName: "SS English 55.mp4", id: "800", topic: "The Adverb" },
          {
            VideoName: "SS English 57.mp4",
            id: "801",
            topic: "Comparison of Adverbs",
          },
          { VideoName: "SS English 58.mp4", id: "802", topic: "Adverb Types" },
        ],
      },
      {
        Subject: "Economics",
        content: [
          {
            VideoName: "Economics 55.mp4",
            id: "566",
            topic: "Private Participation in the economic development",
          },
          {
            VideoName: "Economics 66.mp4",
            id: "567",
            topic: "Economic Development Challenges",
          },
          {
            VideoName: "Economics 67.mp4",
            id: "568",
            topic: "Economic Reforms Programs",
          },
          {
            VideoName: "Economics 68.mp4",
            id: "569",
            topic: "Entrpreneurship in Nigeria",
          },
          { VideoName: "Economics 70.mp4", id: "570", topic: "Stock Market" },
          {
            VideoName: "Economics 71.mp4",
            id: "571",
            topic: "Careers in Economics",
          },
          {
            VideoName: "Economics 1.mp4",
            id: "572",
            topic: "Meaning of Economics",
          },
          {
            VideoName: "Economics 2.mp4",
            id: "573",
            topic: "Related Concepts in Economics",
          },
          {
            VideoName: "Economics 3.mp4",
            id: "574",
            topic: "Economics as an Art and Science",
          },
          {
            VideoName: "Economics 4.mp4",
            id: "575",
            topic: "Basic Tools of Economic Analysis",
          },
          {
            VideoName: "Economics 6.mp4",
            id: "576",
            topic: "Concept of Demand and Supply",
          },
          {
            VideoName: "Economics 7.mp4",
            id: "577",
            topic: "Theory of Production",
          },
          {
            VideoName: "Economics 8.mp4",
            id: "578",
            topic: "Firms and Industry",
          },
          {
            VideoName: "Economics 9.mp4",
            id: "579",
            topic: "How Government Participates in the Economy",
          },
          {
            VideoName: "Economics 10.mp4",
            id: "580",
            topic: "Basic Economic Problems of the Society",
          },
          {
            VideoName: "Economics 11.mp4",
            id: "581",
            topic: "Population Census",
          },
          {
            VideoName: "Economics 12.mp4",
            id: "582",
            topic: "Optimum Population",
          },
          {
            VideoName: "Economics 13.mp4",
            id: "583",
            topic: "Static and Declining Population",
          },
          { VideoName: "Economics 14.mp4", id: "584", topic: "Migration" },
          {
            VideoName: "Economics 15.mp4",
            id: "585",
            topic: "Migration: The Lagos Story",
          },
          {
            VideoName: "Economics 16.mp4",
            id: "586",
            topic: "Malthusian Theory of Population",
          },
          {
            VideoName: "Economics 17.mp4",
            id: "587",
            topic: "Labour Market and Wages",
          },
          { VideoName: "Economics 18.mp4", id: "588", topic: "Trade Union" },
          {
            VideoName: "Economics 19.mp4",
            id: "589",
            topic: "Economies of Scales",
          },
          {
            VideoName: "Economics 20.mp4",
            id: "590",
            topic: "Division of Labour & Specialization",
          },
          { VideoName: "Economics 24.mp4", id: "591", topic: "Money" },
          {
            VideoName: "Economics 25.mp4",
            id: "592",
            topic: "Channels of Distribution",
          },
          {
            VideoName: "Economics 26.mp4",
            id: "593",
            topic: "Instruments of Business Finance",
          },
          { VideoName: "Economics 28.mp4", id: "594", topic: "Cost Concepts" },
          {
            VideoName: "Economics 29.mp4",
            id: "595",
            topic: "Concept of Revenue",
          },
          { VideoName: "Economics 30.mp4", id: "596", topic: "Labour Market" },
          { VideoName: "Economics 31.mp4", id: "597", topic: "Utility Theory" },
          {
            VideoName: "Economics 32.mp4",
            id: "598",
            topic: "Market Structures",
          },
          {
            VideoName: "Economics 33.mp4",
            id: "599",
            topic: "Industries in Nigeria",
          },
          {
            VideoName: "Economics 34.mp4",
            id: "600",
            topic: "The Central Bank",
          },
          { VideoName: "Economics 35.mp4", id: "601", topic: "Public Finance" },
          { VideoName: "Economics 36.mp4", id: "602", topic: "Taxation" },
          {
            VideoName: "Economics 37.mp4",
            id: "603",
            topic: "Balanced and Unbalanced Budget",
          },
          {
            VideoName: "Economics 38.mp4",
            id: "604",
            topic: "National Income Accounting",
          },
          {
            VideoName: "Economics 39.mp4",
            id: "605",
            topic: "Elements of National Income Accounting",
          },
          {
            VideoName: "Economics 40.mp4",
            id: "606",
            topic: "Economic System",
          },
          {
            VideoName: "Economics 41.mp4",
            id: "607",
            topic: "Features of Economic Systems",
          },
          {
            VideoName: "Economics 42.mp4",
            id: "608",
            topic: "Demand for Money",
          },
          {
            VideoName: "Economics 43.mp4",
            id: "609",
            topic: "Supply of Money",
          },
          {
            VideoName: "Economics 44.mp4",
            id: "610",
            topic: "Elasticity of Demand and Supply",
          },
          { VideoName: "Economics 45.mp4", id: "611", topic: "Inflation" },
          { VideoName: "Economics 46.mp4", id: "612", topic: "Deflation" },
          {
            VideoName: "Economics 47.mp4",
            id: "613",
            topic: "Types of Financial Institutions",
          },
          {
            VideoName: "Economics 48.mp4",
            id: "614",
            topic: "Functions of Various Financial Institutions",
          },
          {
            VideoName: "Economics 50.mp4",
            id: "615",
            topic: "Petroleum and the Nigerian Economy",
          },
          {
            VideoName: "Economics 52.mp4",
            id: "616",
            topic: "Manufacturing in Nigeria",
          },
          {
            VideoName: "Economics 53.mp4",
            id: "617",
            topic: "Construction in Nigeria",
          },
          {
            VideoName: "Economics 54.mp4",
            id: "618",
            topic: "Service Industries",
          },
          {
            VideoName: "Economics 56.mp4",
            id: "619",
            topic: "Agencies that regulate the FInancial Market",
          },
          {
            VideoName: "Economics 57.mp4",
            id: "620",
            topic: "Functions and Roles of Regulatory Agencies",
          },
          {
            VideoName: "Economics 58.mp4",
            id: "621",
            topic: "International Trade",
          },
          {
            VideoName: "Economics 59.mp4",
            id: "622",
            topic: "Balance of Payment",
          },
          {
            VideoName: "Economics 60.mp4",
            id: "623",
            topic: "Economic Growth",
          },
          {
            VideoName: "Economics 61.mp4",
            id: "624",
            topic: "History of Recession in Nigeria",
          },
          {
            VideoName: "Economics 62.mp4",
            id: "625",
            topic: "Economic Development",
          },
          {
            VideoName: "Economics 64.mp4",
            id: "626",
            topic: "International Economic Organization",
          },
          {
            VideoName: "Economics 65.mp4",
            id: "627",
            topic: "Current Economic Plans",
          },
          { VideoName: "Economics 69.mp4", id: "628", topic: "Capital Market" },
        ],
      },
      {
        Subject: "Government",
        content: [
          {
            VideoName: "Govt 14.mp4",
            id: "678",
            topic: "The Structure and Organization of Government",
          },
          {
            VideoName: "Govt 30.mp4",
            id: "679",
            topic: "Settlements and Settlement Interactions",
          },
          {
            VideoName: "Govt 31.mp4",
            id: "680",
            topic: "GeoPolitical Issues in Nigeria",
          },
          { VideoName: "Govt 32.mp4", id: "681", topic: "Civil Service" },
          { VideoName: "Govt 33.mp4", id: "682", topic: "Public Corporations" },
          {
            VideoName: "Govt 34.mp4",
            id: "683",
            topic: "Personel Administration in Public Service",
          },
          {
            VideoName: "Govt 35.mp4",
            id: "684",
            topic: "Control and Problems of Public Corporation",
          },
          {
            VideoName: "Govt 36.mp4",
            id: "685",
            topic:
              "Commercialization, Privatization and Deregulation of Public Corporation",
          },
          {
            VideoName: "Govt 37.mp4",
            id: "686",
            topic: "Local Government Administration",
          },
          {
            VideoName: "Govt 38.mp4",
            id: "687",
            topic: "Local Government Reforms",
          },
          {
            VideoName: "Govt 39.mp4",
            id: "688",
            topic: "Pre-Colonial and Colonial Administration",
          },
          {
            VideoName: "Govt 40.mp4",
            id: "689",
            topic: "Nationalism Constitutional Development in Nigeria",
          },
          {
            VideoName: "Govt 41.mp4",
            id: "690",
            topic: "The 1999 Nigerian Constitution",
          },
          {
            VideoName: "Govt 42.mp4",
            id: "691",
            topic: "Origin of Federalism in Nigeria",
          },
          {
            VideoName: "Govt 43.mp4",
            id: "692",
            topic: "Nature and Structure of Nigeria Federalism",
          },
          {
            VideoName: "Govt 44.mp4",
            id: "693",
            topic: "Problems of Nigerian Federalism",
          },
          {
            VideoName: "Govt 45.mp4",
            id: "694",
            topic: "Minority Issues and the Creation of State",
          },
          {
            VideoName: "Govt 46.mp4",
            id: "695",
            topic: "Inter-Ethnic Rivalry and Issue of Secession",
          },
          {
            VideoName: "Govt 47.mp4",
            id: "696",
            topic: "Development of Political Parties in Nigeria",
          },
          { VideoName: "Govt 48.mp4", id: "697", topic: "NNDP and NYM" },
          { VideoName: "Govt 49.mp4", id: "698", topic: "The NCNC and AG" },
          { VideoName: "Govt 50.mp4", id: "699", topic: "The NPC and NEPU" },
          { VideoName: "Govt 51.mp4", id: "700", topic: "NPN, UNP and NPP" },
          { VideoName: "Govt 52.mp4", id: "701", topic: "GNPP, PRP and NAP" },
          { VideoName: "Govt 53.mp4", id: "702", topic: "The PDP and ANPP" },
          {
            VideoName: "Govt 54.mp4",
            id: "703",
            topic: "AD, AC and Other Small Political Party",
          },
          {
            VideoName: "Govt 55.mp4",
            id: "704",
            topic: "Major Political Crisis in Nigeria",
          },
          { VideoName: "Govt 57.mp4", id: "705", topic: "SDP and NRC" },
          {
            VideoName: "Govt 59.mp4",
            id: "706",
            topic: "Military Rule in Nigeria",
          },
          {
            VideoName: "Govt 60.mp4",
            id: "707",
            topic: "Conflict Resolution in Nigeria",
          },
          { VideoName: "Govt 61.mp4", id: "708", topic: "Peace Education" },
          {
            VideoName: "Govt 62.mp4",
            id: "709",
            topic: "International Organization",
          },
          {
            VideoName: "Govt 63.mp4",
            id: "710",
            topic: "Millenium Development Goal (MDGs)",
          },
          { VideoName: "Govt 64.mp4", id: "711", topic: "E-Government" },
          {
            VideoName: "Govt 65.mp4",
            id: "712",
            topic: "Leadership and Followership",
          },
          {
            VideoName: "Govt 1.mp4",
            id: "959",
            topic: "Government: Charateristics and Functions",
          },
          {
            VideoName: "Govt 2.mp4",
            id: "960",
            topic: "Government as a Process of Governing",
          },
          {
            VideoName: "Govt 3.mp4",
            id: "961",
            topic: "Government as an academic field of study",
          },
          {
            VideoName: "Govt 4.mp4",
            id: "962",
            topic: "Basic Concepts: Power",
          },
          { VideoName: "Govt 5.mp4", id: "963", topic: "Power and Authority" },
          {
            VideoName: "Govt 10.mp4",
            id: "964",
            topic: "Capitalism and Socialism",
          },
          {
            VideoName: "Govt 11.mp4",
            id: "965",
            topic: "Confederal Government",
          },
          {
            VideoName: "Govt 12.mp4",
            id: "966",
            topic: "Monarchy and Oligarchy",
          },
          {
            VideoName: "Govt 13.mp4",
            id: "967",
            topic: "Republicanism,  Constitution and Constitutionalsm",
          },
          { VideoName: "Govt 15.mp4", id: "968", topic: "Rule of Law" },
          {
            VideoName: "Govt 16.mp4",
            id: "969",
            topic: "Fundamental Human Rights",
          },
          { VideoName: "Govt 17.mp4", id: "970", topic: "Separation of Power" },
          {
            VideoName: "Govt 18.mp4",
            id: "971",
            topic: "Representative Government",
          },
          {
            VideoName: "Govt 19.mp4",
            id: "972",
            topic: "Centralization and Decentralization",
          },
          {
            VideoName: "Govt 20.mp4",
            id: "973",
            topic: "Delegated Legislation",
          },
          { VideoName: "Govt 21.mp4", id: "974", topic: "Citizenship" },
          {
            VideoName: "Govt 22.mp4",
            id: "975",
            topic: "Communism, Fascism and Totalitarianism",
          },
          { VideoName: "Govt 23.mp4", id: "976", topic: "Unitary Government" },
          { VideoName: "Govt 24.mp4", id: "977", topic: "Federal Government" },
          {
            VideoName: "Govt 25.mp4",
            id: "978",
            topic: "Parliamentary System of Government",
          },
          {
            VideoName: "Govt 26.mp4",
            id: "979",
            topic: "Presidential System of Government",
          },
          {
            VideoName: "Govt 27.mp4",
            id: "980",
            topic: "Political Party System",
          },
          { VideoName: "Govt 28.mp4", id: "981", topic: "Pressure Group" },
          { VideoName: "Govt 29.mp4", id: "982", topic: "Population" },
        ],
      },
      {
        Subject: "Mathematics",
        content: [
          {
            VideoName: "SS Maths 3.mp4",
            id: "803",
            topic: "Multiplication Operations in Modular Arithmetic",
          },
          {
            VideoName: "SS Maths 9.mp4",
            id: "804",
            topic: "Solution to Sets Using Venn Diagram",
          },
          {
            VideoName: "SS Maths 13.mp4",
            id: "805",
            topic: "Angle equal to a given angle",
          },
          {
            VideoName: "SS Maths 14.mp4",
            id: "806",
            topic:
              "Construction of Triangles,  Rhombus, Squares and Rectangles",
          },
          {
            VideoName: "SS Maths 15.mp4",
            id: "807",
            topic: "Points at a given distance from  given point",
          },
          {
            VideoName: "SS Maths 16.mp4",
            id: "808",
            topic:
              "Points equidistant from two given points and two given straight lines",
          },
          {
            VideoName: "SS Maths 17.mp4",
            id: "809",
            topic: "Trigonometric Ratio",
          },
          {
            VideoName: "SS Maths 21.mp4",
            id: "810",
            topic: "Evaluating Algebraic Expressions",
          },
          {
            VideoName: "SS Maths 36.mp4",
            id: "811",
            topic: "Calculating Angle of Elevation and Depression",
          },
          {
            VideoName: "SS Maths 37.mp4",
            id: "812",
            topic: "Application of Heights and Distance",
          },
          {
            VideoName: "SS Maths 43.mp4",
            id: "813",
            topic: "Volumes of cubes and cuboids",
          },
          {
            VideoName: "SS Maths 44.mp4",
            id: "814",
            topic: "Volumes of cylinders and cones",
          },
          {
            VideoName: "SS Maths 45.mp4",
            id: "815",
            topic: "Area and Volume of a sphere",
          },
          {
            VideoName: "SS Maths 50.mp4",
            id: "816",
            topic: "Cumulative Frequency Graph",
          },
          {
            VideoName: "SS Maths 53.mp4",
            id: "817",
            topic: "Trigonometric Graph of Trigonometric Ratio",
          },
          {
            VideoName: "SS Maths 62.mp4",
            id: "818",
            topic: "Use of Pythagoras Theorem to determine Length",
          },
          {
            VideoName: "SS Maths 68.mp4",
            id: "819",
            topic: "Application of Linear Equation to Capital Market",
          },
          { VideoName: "SS Maths 69.mp4", id: "820", topic: "Indices" },
          {
            VideoName: "SS Maths 86.mp4",
            id: "821",
            topic: "Frequency Polygon",
          },
          {
            VideoName: "SS Maths 89.mp4",
            id: "822",
            topic: "Property of Exterior Angles of a Polygon",
          },
          {
            VideoName: "SS Maths 93.mp4",
            id: "823",
            topic: "Basic Operations on Rational Numbers",
          },
          {
            VideoName: "SS Maths 98.mp4",
            id: "824",
            topic: "Mass, Distance, Time and Speed",
          },
          {
            VideoName: "SS Maths 100.mp4",
            id: "825",
            topic: "Graphical Solutions of Linear Equations",
          },
          {
            VideoName: "SS Maths 103.mp4",
            id: "826",
            topic: "Differentiation of Algebraic Functions",
          },
          {
            VideoName: "SS Maths 104.mp4",
            id: "827",
            topic: "Integration of Simple Algebraic Functions",
          },
          { VideoName: "SS Maths 107.mp4", id: "828", topic: "Vectors" },
          {
            VideoName: "SS Maths 108.mp4",
            id: "829",
            topic: "Reflection of Points and Shapes in the Cartesian Plane",
          },
          {
            VideoName: "SS Maths 109.mp4",
            id: "830",
            topic: "Coordinate Geometry of Straight Lines",
          },
          {
            VideoName: "SS Maths 65.mp4",
            id: "831",
            topic: "Length of Arc Circles, Perimeters of Sectors and Segment",
          },
          {
            VideoName: "SS Maths 6.mp4",
            id: "995",
            topic: "Relationship between indices and logarithms",
          },
          { VideoName: "SS Maths 10.mp4", id: "996", topic: "Constructions" },
          {
            VideoName: "SS Maths 11.mp4",
            id: "997",
            topic: "Line Parallel or Perpendicular to a given line",
          },
          {
            VideoName: "SS Maths 19.mp4",
            id: "998",
            topic: "Quadratic Equations",
          },
          {
            VideoName: "SS Maths 23.mp4",
            id: "999",
            topic: "Linear Equation in one variable",
          },
          {
            VideoName: "SS Maths 26.mp4",
            id: "1000",
            topic: "Change of Subject of Formula",
          },
          {
            VideoName: "SS Maths 28.mp4",
            id: "1001",
            topic: "Solution of Quadratic Equations",
          },
          {
            VideoName: "SS Maths 31.mp4",
            id: "1002",
            topic: "Proofs of Some Basic Equations",
          },
          {
            VideoName: "SS Maths 79.mp4",
            id: "1003",
            topic: "Roots of Quadratic Equation",
          },
          {
            VideoName: "SS Maths 51.mp4",
            id: "1004",
            topic: "Addition and Subtraction of Matrices",
          },
          {
            VideoName: "SS Maths 56.mp4",
            id: "1005",
            topic: "Areas of Triangles",
          },
          {
            VideoName: "SS Maths 57.mp4",
            id: "1006",
            topic: "Area of Quadrilaterals",
          },
          {
            VideoName: "SS Maths 58.mp4",
            id: "1007",
            topic: "Congruent Angles",
          },
          {
            VideoName: "SS Maths 59.mp4",
            id: "1008",
            topic: "Properties of Similar Triangles",
          },
          // { "VideoName": "SS Maths 65.mp4", "id": "1009", "topic": "Lengths of arc of circles, perimeters of sectors and segments" },
          // { "VideoName": "SS Maths 68.mp4", "id": "1010", "topic": "The sum of angles of a polygon" },
          {
            VideoName: "SS Maths 71.mp4",
            id: "1011",
            topic: "Partial and Joint Variations",
          },
          {
            VideoName: "SS Maths 72.mp4",
            id: "1012",
            topic: "Patterns of Sequence",
          },
          // { "VideoName": "SS Maths 75.mp4", "id": "1013", "topic": "Exponential Sequence/Geometric Progression" },
          {
            VideoName: "SS Maths 76.mp4",
            id: "1014",
            topic: "Finite and Infinite Series",
          },
          {
            VideoName: "SS Maths 78.mp4",
            id: "1015",
            topic: "Exponential Series",
          },
          {
            VideoName: "SS Maths 88.mp4",
            id: "1016",
            topic: "Sum of Angles of a Polygon",
          },
          { VideoName: "SS Maths 90.mp4", id: "1017", topic: "Paralellograms" },
          {
            VideoName: "SS Maths 91.mp4",
            id: "1018",
            topic: "Domain and COdomain",
          },
          {
            VideoName: "SS Maths 94.mp4",
            id: "1019",
            topic: "Simplification of Surds",
          },
          // { "VideoName": "SS Maths 98.mp4", "id": "1020", "topic": "Roots of Quadratic Equation" },
          {
            VideoName: "SS Maths 102.mp4",
            id: "1021",
            topic: "Representation of the number line",
          },
          {
            VideoName: "SS Maths 106.mp4",
            id: "1022",
            topic: "Cartesian Components of a Vector",
          },
        ],
      },
      {
        Subject: "Physics",
        content: [
          { VideoName: "Physics 1.mp4", id: "983", topic: "Concept of Matter" },
          {
            VideoName: "Physics 2.mp4",
            id: "984",
            topic: "Fundamental Quantities and Units",
          },
          {
            VideoName: "Physics 3.mp4",
            id: "985",
            topic: "Derived Quantities and Units",
          },
          { VideoName: "Physics 10.mp4", id: "986", topic: "Motion" },
          {
            VideoName: "Physics 16.mp4",
            id: "987",
            topic: "Distinction between Mass and Weight",
          },
          {
            VideoName: "Physics 20.mp4",
            id: "988",
            topic: "Speed and Velocity",
          },
          {
            VideoName: "Physics 40.mp4",
            id: "989",
            topic: "Newton's First Law of Motion",
          },
          {
            VideoName: "Physics 42.mp4",
            id: "990",
            topic: "Newton's Second Law of Motion: Impulse",
          },
          { VideoName: "Physics 47.mp4", id: "991", topic: "Kinetic Energy" },
          {
            VideoName: "Physics 53.mp4",
            id: "992",
            topic: "Thermal Linear Expansivity",
          },
          {
            VideoName: "Physics 60.mp4",
            id: "993",
            topic: "Magnetization and Demagnetization",
          },
          {
            VideoName: "Physics 72.mp4",
            id: "994",
            topic: "Evaporation and Boiling",
          },
        ],
      },
      {
        Subject: "Financial Accounting",
        content: [
          {
            VideoName: "Account 64.mp4",
            id: "354",
            topic: "Public and Private Sector Accounts",
          },
          {
            VideoName: "Account 65.mp4",
            id: "355",
            topic: "Sources of Public Revenue",
          },
          {
            VideoName: "Account 66.mp4",
            id: "356",
            topic: "Capital and Recurrent Expenditure",
          },
          {
            VideoName: "Account 67.mp4",
            id: "357",
            topic: "Preparation of a single Government Account",
          },
          {
            VideoName: "Account 68.mp4",
            id: "358",
            topic: "Meaning, Functions and Features of the Money Market",
          },
          {
            VideoName: "Account 70.mp4",
            id: "359",
            topic: "The Insurance Market",
          },
          {
            VideoName: "Account 71.mp4",
            id: "360",
            topic: "Raising Fund for the Capital Market",
          },
          {
            VideoName: "Account 72.mp4",
            id: "361",
            topic: "Raising funds for the Capital Market II",
          },
          {
            VideoName: "Account 73.mp4",
            id: "362",
            topic: "Raising Funds for the Capital Market III",
          },
          {
            VideoName: "Account 74.mp4",
            id: "363",
            topic: "Private Placement",
          },
          {
            VideoName: "Account 75.mp4",
            id: "364",
            topic: "Requirements for Accessing the Capital Market",
          },
          {
            VideoName: "Account 76.mp4",
            id: "365",
            topic: "Regulation of the Capital Market",
          },
          {
            VideoName: "Account 77.mp4",
            id: "366",
            topic: "Nature and Formation of Partnerships",
          },
          {
            VideoName: "Account 78.mp4",
            id: "367",
            topic: "Partnership Agreements or Deed",
          },
          {
            VideoName: "Account 79.mp4",
            id: "368",
            topic: "Profit and Loss Appropriation Account",
          },
          {
            VideoName: "Account 81.mp4",
            id: "369",
            topic: "Admission of a New Partner i a Partnership",
          },
          {
            VideoName: "Account 83.mp4",
            id: "370",
            topic: "Dissolution of Partnerships",
          },
          {
            VideoName: "Account 84.mp4",
            id: "371",
            topic: "Benefits of the Capital Market to Investors",
          },
          {
            VideoName: "Account 85.mp4",
            id: "372",
            topic: "Benefits of the Capital Market to Government",
          },
          {
            VideoName: "Account 86.mp4",
            id: "373",
            topic: "Benefits of the Capital Market to the Economy",
          },
          {
            VideoName: "Account 87.mp4",
            id: "374",
            topic: "Benefits of the Capital Market to Companies",
          },
          {
            VideoName: "Account 88.mp4",
            id: "375",
            topic: "Reasons of Regulation of Financial Systems",
          },
          {
            VideoName: "Account 89.mp4",
            id: "376",
            topic: "Provisions & Allowance for Doubtful Debts",
          },
          {
            VideoName: "Account 90.mp4",
            id: "377",
            topic: "How to Make Provisions for Discounts",
          },
          {
            VideoName: "Account 93.mp4",
            id: "378",
            topic: "Calculuating Depreciation: Sum of the Year's Digit Method",
          },
          {
            VideoName: "Account 95.mp4",
            id: "379",
            topic: "Accounting for Depreciation",
          },
          {
            VideoName: "Account 97.mp4",
            id: "380",
            topic: "Profit/Loss from the Opening & Closing Balance Sheet",
          },
          {
            VideoName: "Account 4_1.mp4",
            id: "832",
            topic: "Accounting Concepts",
          },
          {
            VideoName: "Account 11_1.mp4",
            id: "833",
            topic: "Profit and Loss Account",
          },
          {
            VideoName: "Account 12_1.mp4",
            id: "834",
            topic: "Adjustments in the Profit and Loss Account",
          },
          {
            VideoName: "Account 2.mp4",
            id: "835",
            topic: "Ethics of Accounting",
          },
          { VideoName: "Account 4.mp4", id: "836", topic: "General Journal" },
          {
            VideoName: "Account 5.mp4",
            id: "837",
            topic: "Analytical Cash Book",
          },
          {
            VideoName: "Account 7.mp4",
            id: "838",
            topic: "Classification of Accounts",
          },
          { VideoName: "Account 9.mp4", id: "839", topic: "Trial Balance" },
          {
            VideoName: "Account 9_1.mp4",
            id: "840",
            topic: "Profit and Loss Appropriation Account",
          },
          {
            VideoName: "Account 11.mp4",
            id: "841",
            topic: "The Balance Sheet",
          },
          { VideoName: "Account 12.mp4", id: "842", topic: "Purchase Ledger" },
          {
            VideoName: "Account 13.mp4",
            id: "843",
            topic: "Written down Value Method of Depreciation",
          },
          { VideoName: "Account 14.mp4", id: "844", topic: "Sales Ledger" },
          {
            VideoName: "Account 16.mp4",
            id: "845",
            topic: "Partnership Capital Accounts",
          },
          {
            VideoName: "Account 17.mp4",
            id: "846",
            topic: "Manufacturing Accounts",
          },
          {
            VideoName: "Account 18.mp4",
            id: "847",
            topic: "Importance of Manufacturing Accounts",
          },
          {
            VideoName: "Account 19.mp4",
            id: "848",
            topic: "Cost Classification in Manufacturing Accounts",
          },
          { VideoName: "Account 21.mp4", id: "849", topic: "Trading Account" },
          {
            VideoName: "Account 22.mp4",
            id: "850",
            topic: "The Admission of a Partner and Goodwill Account",
          },
          { VideoName: "Account 31.mp4", id: "851", topic: "Current Accounts" },
          {
            VideoName: "Account 46.mp4",
            id: "852",
            topic: "Bank Reconciliation Statement",
          },
          {
            VideoName: "Account 51.mp4",
            id: "853",
            topic: "Users of Accounting Information",
          },
          {
            VideoName: "Account 52.mp4",
            id: "854",
            topic: "Stages of the Accounting Process",
          },
          {
            VideoName: "Account 53.mp4",
            id: "855",
            topic: "Characteristics of Accounting Information",
          },
          {
            VideoName: "Account 54.mp4",
            id: "856",
            topic: "The Accounting Equation",
          },
          {
            VideoName: "Account 55.mp4",
            id: "857",
            topic: "Functions of Source Documents",
          },
          { VideoName: "Account 56.mp4", id: "858", topic: "Subsidiary Books" },
          {
            VideoName: "Account 57.mp4",
            id: "859",
            topic: "Classification of Accounts in the Ledger",
          },
          {
            VideoName: "Account 58.mp4",
            id: "860",
            topic: "Analytical Cash Book & the Petty Cash Book",
          },
          {
            VideoName: "Account 59.mp4",
            id: "861",
            topic: "Preparation of the Trial Balance",
          },
          {
            VideoName: "Account 60.mp4",
            id: "862",
            topic: "Bank Reconciliation Statement",
          },
          {
            VideoName: "Account 61.mp4",
            id: "863",
            topic: "Trading Accounts, Profit and Loss Accounts",
          },
          {
            VideoName: "Account 62.mp4",
            id: "864",
            topic: "The Balance Sheet",
          },
          {
            VideoName: "Account 63.mp4",
            id: "865",
            topic: "Adjustments to the Final Accounts",
          },
        ],
      },
      {
      	Subject: "Agricultural Science",
      	content: [
      		{
      			VideoName: "Agric 38.mp4",
      			id: "381",
      			topic: "Factors of Production"
      		},
      		{
      			VideoName: "Agric 39.mp4",
      			id: "382",
      			topic: "Principles of Demand and Supply in Agricultural Production"
      		},
      		{ VideoName: "Agric 40.mp4", id: "383", topic: "Farm Manager" },
      		{ VideoName: "Agric 41.mp4", id: "384", topic: "Crop Improvement" },
      		{ VideoName: "Agric 42.mp4", id: "385", topic: "Animal Improvement" },
      		{
      			VideoName: "Agric 43.mp4",
      			id: "386",
      			topic: "Animal Health Management"
      		},
      		{
      			VideoName: "Agric 44.mp4",
      			id: "387",
      			topic: "Aquaculture and Apiculture or Bee Keeping"
      		},
      		{
      			VideoName: "Agric 45.mp4",
      			id: "388",
      			topic: "Law of Diminishing Returns in Agriculture"
      		},
      		{
      			VideoName: "Agric 46.mp4",
      			id: "389",
      			topic: "Management/Entrpreneurship in Agriculture"
      		},
      		{
      			VideoName: "Agric 47.mp4",
      			id: "390",
      			topic: "Agricultural Finance"
      		},
      		{ VideoName: "Agric 48.mp4", id: "391", topic: "Forest Management" },
      		{ VideoName: "Agric 49.mp4", id: "392", topic: "Animal Husbandry" },
      		{
      			VideoName: "Agric 1.mp4",
      			id: "866",
      			topic: "Meaning and Importance of Agriculture"
      		},
      		{
      			VideoName: "Agric 2.mp4",
      			id: "867",
      			topic: "Problems of Agricultural development and possible solutions"
      		},
      		{
      			VideoName: "Agric 3.mp4",
      			id: "868",
      			topic:
      				"The role of Government and Non-Governmental Organizations in Agricultural Development"
      		},
      		{
      			VideoName: "Agric 4.mp4",
      			id: "869",
      			topic: "Agricultural Laws and Reforms"
      		},
      		{
      			VideoName: "Agric 6.mp4",
      			id: "870",
      			topic:
      				"Factors affecting land availability for Agricultural purposes"
      		},
      		{
      			VideoName: "Agric 7.mp4",
      			id: "871",
      			topic: "Agro Allied Industries"
      		},
      		{
      			VideoName: "Agric 8.mp4",
      			id: "872",
      			topic: "Relationship between Agricultural and Industry"
      		},
      		{
      			VideoName: "Agric 9.mp4",
      			id: "873",
      			topic: "Soil FOrmation and Profile Development"
      		},
      		{ VideoName: "Agric 11.mp4", id: "874", topic: "Farm Power" },
      		{
      			VideoName: "Agric 12.mp4",
      			id: "875",
      			topic: "Classification of Crops"
      		},
      		{
      			VideoName: "Agric 14.mp4",
      			id: "876",
      			topic: "Pasture and Forage Crops"
      		},
      		{
      			VideoName: "Agric 15.mp4",
      			id: "877",
      			topic: "Plant Nutrient and Nutrient Cycle"
      		},
      		{ VideoName: "Agric 16.mp4", id: "878", topic: "Irrigation" },
      		{ VideoName: "Agric 17.mp4", id: "879", topic: "Drainage" },
      		{
      			VideoName: "Agric 18.mp4",
      			id: "880",
      			topic: "Agricultural pollution"
      		},
      		{
      			VideoName: "Agric 19.mp4",
      			id: "881",
      			topic: "Farm Surveying and Planning"
      		},
      		{
      			VideoName: "Agric 20.mp4",
      			id: "882",
      			topic: "Principles of Farmstead"
      		},
      		{
      			VideoName: "Agric 34.mp4",
      			id: "883",
      			topic: "Livestock Management"
      		},
      		{ VideoName: "Agric 35.mp4", id: "884", topic: "Animal Nutrition" },
      		{
      			VideoName: "Agric 36.mp4",
      			id: "885",
      			topic: "Rangeland Management"
      		}
      	]
      },
      {
        Subject: "Biology",
        content: [
          {
            VideoName: "Bio 1.mp4",
            id: "393",
            topic: "Classification of Living things",
          },
          {
            VideoName: "Bio 2.mp4",
            id: "394",
            topic: "Classification of Living things II",
          },
          { VideoName: "Bio 3.mp4", id: "395", topic: "Biology and Life" },
          {
            VideoName: "Bio 5.mp4",
            id: "396",
            topic: "Organization of Life: Single-celled organisms",
          },
          {
            VideoName: "Bio 6.mp4",
            id: "397",
            topic: "Levels of organization of Life: Tissue",
          },
          {
            VideoName: "Bio 7.mp4",
            id: "398",
            topic: "Levels of Organization of Life: Organs",
          },
          {
            VideoName: "Bio 8.mp4",
            id: "399",
            topic: "Levels of Organization of Life: System",
          },
          {
            VideoName: "Bio 9.mp4",
            id: "400",
            topic: "Organization in Higher Organization",
          },
          {
            VideoName: "Bio 10.mp4",
            id: "401",
            topic: "Forms in which living cells exist",
          },
          {
            VideoName: "Bio 11.mp4",
            id: "402",
            topic: "Forms in which Living Cells Exist II",
          },
          { VideoName: "Bio 12.mp4", id: "403", topic: "Colony: Volvox" },
          { VideoName: "Bio 13.mp4", id: "404", topic: "Filament: Spirogyra" },
          {
            VideoName: "Bio 14.mp4",
            id: "405",
            topic: "Cell Structure and Functions of Cell Components",
          },
          {
            VideoName: "Bio 15.mp4",
            id: "406",
            topic:
              "The similarities and Differences between Plant and Animal cells",
          },
          {
            VideoName: "Bio 16.mp4",
            id: "407",
            topic: "Osmosis and Diffusion",
          },
          { VideoName: "Bio 17.mp4", id: "408", topic: "Nutrition" },
          { VideoName: "Bio 18.mp4", id: "409", topic: "Cellular Respiration" },
          {
            VideoName: "Bio 19.mp4",
            id: "410",
            topic: "Anaerobic Respiration and Energy Release",
          },
          // { "VideoName": "Bio 20.mp4", "id": "411", "topic": "Difference between plants and animals" },
          {
            VideoName: "Bio 20.mp4",
            id: "412",
            topic: "Excretion in single-celled aquatic organisms",
          },
          {
            VideoName: "Bio 21.mp4",
            id: "413",
            topic: "Growth and Cell Division",
          },
          {
            VideoName: "Bio 23.mp4",
            id: "414",
            topic: "Influence and Growth of Hormones and Auxins",
          },
          {
            VideoName: "Bio 24.mp4",
            id: "415",
            topic: "Growth Curvatures: Tropism",
          },
          {
            VideoName: "Bio 25.mp4",
            id: "416",
            topic: "Organnelles of Movement",
          },
          { VideoName: "Bio 26.mp4", id: "417", topic: "Asexual Reproduction" },
          { VideoName: "Bio 27.mp4", id: "418", topic: "Sexual Reproduction" },
          { VideoName: "Bio 29.mp4", id: "419", topic: "Skeletal Materials" },
          {
            VideoName: "Bio 28.mp4",
            id: "420",
            topic: "Tissues and Supporting Systems",
          },
          { VideoName: "Bio 30.mp4", id: "421", topic: "Types of Skeleton" },
          {
            VideoName: "Bio 31.mp4",
            id: "422",
            topic:
              "Bones of the Vertebral Column and the Appendicular Skeleton",
          },
          // { "VideoName": "Bio 22.mp4", "id": "423", "topic": "Regions of Fastest Growth in Plants" },
          {
            VideoName: "Bio 33.mp4",
            id: "424",
            topic: "Functions of Skeleton in Animals",
          },
          {
            VideoName: "Bio 34.mp4",
            id: "425",
            topic: "The Main Features of Supporting Tissues in Plants",
          },
          { VideoName: "Bio 35.mp4", id: "426", topic: "Transport System" },
          {
            VideoName: "Bio 32.mp4",
            id: "427",
            topic: "Mechanics of Support in Animals",
          },
          { VideoName: "Bio 37.mp4", id: "428", topic: "Transport in Animals" },
          { VideoName: "Bio 36.mp4", id: "429", topic: "Need for Transport" },
          {
            VideoName: "Bio 38.mp4",
            id: "430",
            topic: "Structure of the Heart",
          },
          {
            VideoName: "Bio 39.mp4",
            id: "431",
            topic: "Arteries, Veins and Capillaries (Circulatory System)",
          },
          {
            VideoName: "Bio 40.mp4",
            id: "432",
            topic: "Composition and Functions of Blood and Lymph",
          },
          {
            VideoName: "Bio 41.mp4",
            id: "433",
            topic: "Material for Transport",
          },
          { VideoName: "Bio 43.mp4", id: "434", topic: "Transport in Plants" },
          {
            VideoName: "Bio 44.mp4",
            id: "435",
            topic: "Translocation and Transpiration",
          },
          {
            VideoName: "Bio 45.mp4",
            id: "436",
            topic: "Uptake and Movement of Mineral Salts in Plants",
          },
          { VideoName: "Bio 46.mp4", id: "437", topic: "Respiratory System" },
          { VideoName: "Bio 47.mp4", id: "438", topic: "Fish Breathing" },
          {
            VideoName: "Bio 48.mp4",
            id: "439",
            topic: "Movement of Water to the Apex of Trees and Herbs",
          },
          {
            VideoName: "Bio 50.mp4",
            id: "440",
            topic: "Excretory System and Mechanism",
          },
          {
            VideoName: "Bio 51.mp4",
            id: "441",
            topic: "Mechanism od Gaseeous Exchange in Fish and Toad",
          },
          {
            VideoName: "Bio 52.mp4",
            id: "442",
            topic: "Regulation of Internal Environment: Homeostatis",
          },
          {
            VideoName: "Bio 53.mp4",
            id: "443",
            topic: "Kidney: Structure and Functions",
          },
          {
            VideoName: "Bio 54.mp4",
            id: "444",
            topic: "The Functions of the Liver",
          },
          {
            VideoName: "Bio 55.mp4",
            id: "445",
            topic: "The Structure and Function of the Skin",
          },
          { VideoName: "Bio 57.mp4", id: "446", topic: "Animal Hormones" },
          {
            VideoName: "Bio 58.mp4",
            id: "447",
            topic:
              "Functions and Effects of Over and Under-Secretion of Hormones",
          },
          { VideoName: "Bio 59.mp4", id: "448", topic: "Plant Hormones" },
          { VideoName: "Bio 60.mp4", id: "449", topic: "Nervous Cordination" },
          {
            VideoName: "Bio 61.mp4",
            id: "450",
            topic: "The Central Nervous System",
          },
          {
            VideoName: "Bio 62.mp4",
            id: "451",
            topic: "The Parts and Functions of the Brain",
          },
          {
            VideoName: "Bio 64.mp4",
            id: "452",
            topic: "Structure and Function of the Spinal Cord",
          },
          {
            VideoName: "Bio 65.mp4",
            id: "453",
            topic: "Peripheral Nervous System",
          },
          {
            VideoName: "Bio 66.mp4",
            id: "454",
            topic: "Structure, Functions and Classification of Neurons",
          },
          {
            VideoName: "Bio 67.mp4",
            id: "455",
            topic: "The Types of Nervous Actions",
          },
          { VideoName: "Bio 69.mp4", id: "456", topic: "Conditioned Reflex" },
          {
            VideoName: "Bio 70.mp4",
            id: "457",
            topic: "Structure and Function of the Eye",
          },
          { VideoName: "Bio 71.mp4", id: "458", topic: "The Ear" },
          { VideoName: "Bio 72.mp4", id: "459", topic: "Reproductive System" },
          {
            VideoName: "Bio 73.mp4",
            id: "460",
            topic: "The Structure and Function of the Male Reproductive System",
          },
          {
            VideoName: "Bio 74.mp4",
            id: "461",
            topic:
              "The Structure and Function of the Female Reproductive System",
          },
          {
            VideoName: "Bio 76.mp4",
            id: "462",
            topic: "Structure of Gametes (Sperms and Ovums)",
          },
          {
            VideoName: "Bio 77.mp4",
            id: "463",
            topic: "Fertilization Development of the Embryo",
          },
          {
            VideoName: "Bio 78.mp4",
            id: "464",
            topic: "Metamorphosis in insects",
          },
          {
            VideoName: "Bio 79.mp4",
            id: "465",
            topic: "Reproduction in Flowering Plants",
          },
          {
            VideoName: "Bio 80.mp4",
            id: "466",
            topic: "Reproduction in Flowering Plants II",
          },
          {
            VideoName: "Bio 81.mp4",
            id: "467",
            topic:
              "Arrangement of Floral Parts of a Named Insect-Pollinated Flowers",
          },
          { VideoName: "Bio 82.mp4", id: "468", topic: "Types of Association" },
          {
            VideoName: "Bio 83.mp4",
            id: "469",
            topic: "Photosynthesis and Process of Photosynthesis",
          },
          {
            VideoName: "Bio 84.mp4",
            id: "470",
            topic:
              "Structure and Function of the Male and Female Parts of a Flower",
          },
          {
            VideoName: "Bio 85.mp4",
            id: "471",
            topic: "Light and Dark Reactions",
          },
          { VideoName: "Bio 86.mp4", id: "472", topic: "Alimentary System" },
          {
            VideoName: "Bio 88.mp4",
            id: "473",
            topic: "Feeding in Protozoa and Mammals",
          },
          {
            VideoName: "Bio 89.mp4",
            id: "474",
            topic: "Basic Ecological COncepts",
          },
          { VideoName: "Bio 90.mp4", id: "475", topic: "Digestive Enzymes" },
          {
            VideoName: "Bio 91.mp4",
            id: "476",
            topic: "Balanced Diet and its importance",
          },
          {
            VideoName: "Bio 92.mp4",
            id: "477",
            topic: "Food Webs and Trophic Levels",
          },
          {
            VideoName: "Bio 93.mp4",
            id: "478",
            topic: "Genetic Terminologies",
          },
          {
            VideoName: "Bio 75.mp4",
            id: "734",
            topic: "Reproduction in Flowering Plant",
          },
        ],
      },
      {
        Subject: "Chemistry",
        content: [
          {
            VideoName: "Chemistry 9.mp4",
            id: "479",
            topic:
              "Classification of Mixtures, Sepration Techniques and Criteria for Purity",
          },
          {
            VideoName: "Chemistry 10.mp4",
            id: "480",
            topic: "Periodicity of the Elements",
          },
          {
            VideoName: "Chemistry 11.mp4",
            id: "481",
            topic: "Different Category of Elements in the Periodic Table",
          },
          {
            VideoName: "Chemistry 12.mp4",
            id: "482",
            topic: "Periodic Law: Trend in the Periodic Table",
          },
          {
            VideoName: "Chemistry 14.mp4",
            id: "483",
            topic: "Reactions Between Acids and Metals",
          },
          {
            VideoName: "Chemistry 15.mp4",
            id: "484",
            topic: "Periodic Gradation of the Elements in Group Seven",
          },
          {
            VideoName: "Chemistry 17.mp4",
            id: "485",
            topic: "Interatomic Bonding",
          },
          {
            VideoName: "Chemistry 18.mp4",
            id: "486",
            topic: "Formation of Ionic Bonds and Compounds",
          },
          {
            VideoName: "Chemistry 19.mp4",
            id: "487",
            topic: "Porperties of IONIC Compound",
          },
          {
            VideoName: "Chemistry 20.mp4",
            id: "488",
            topic: "Naming of IONIC compounds",
          },
          {
            VideoName: "Chemistry 22.mp4",
            id: "489",
            topic:
              "Properties of Covalent Compounds, Coodinate (Dative) Covalent Compounds",
          },
          {
            VideoName: "Chemistry 23.mp4",
            id: "490",
            topic: "Metallic Bonding and Factors Influencing its formation",
          },
          {
            VideoName: "Chemistry 24.mp4",
            id: "491",
            topic: "Properties of Metals: Intermolecular Bonding",
          },
          {
            VideoName: "Chemistry 25.mp4",
            id: "492",
            topic: "Intermolecular Forces of Covalent Compounds",
          },
          {
            VideoName: "Chemistry 27.mp4",
            id: "493",
            topic: "Symbols,  Formulae, Equations: Chemical Symbol",
          },
          {
            VideoName: "Chemistry 31.mp4",
            id: "494",
            topic: "Mole Ration and Standard Solutions",
          },
          {
            VideoName: "Chemistry 48.mp4",
            id: "495",
            topic: "Salts and its Uses",
          },
          {
            VideoName: "Chemistry 50.mp4",
            id: "496",
            topic: "Efflorescence,  Hygroscopy and Deliquescence",
          },
          {
            VideoName: "Chemistry 51.mp4",
            id: "497",
            topic: "Acid-Base Indicator and Titration",
          },
          {
            VideoName: "Chemistry 54.mp4",
            id: "498",
            topic: "Oxidation and Reduction Process",
          },
          {
            VideoName: "Chemistry 58.mp4",
            id: "499",
            topic: "Electrolytic Cell",
          },
          {
            VideoName: "Chemistry 59.mp4",
            id: "500",
            topic: "Principles of Electrolysis",
          },
          {
            VideoName: "Chemistry 60.mp4",
            id: "501",
            topic: "Faraday's Laws and its application",
          },
          {
            VideoName: "Chemistry 64.mp4",
            id: "502",
            topic: "Petroleum / Crude Oil",
          },
          { VideoName: "Chemistry 70.mp4", id: "503", topic: "Alkynes" },
          {
            VideoName: "Chemistry 71.mp4",
            id: "504",
            topic: "Chemical Reactions of Alkynes",
          },
          { VideoName: "Chemistry 72.mp4", id: "505", topic: "Benzene" },
          { VideoName: "Chemistry 73.mp4", id: "506", topic: "Alkanols" },
          {
            VideoName: "Chemistry 76.mp4",
            id: "507",
            topic: "Laboratory Test and Uses of Alkanoic Acids",
          },
          {
            VideoName: "Chemistry 78.mp4",
            id: "508",
            topic: "Air, Water and Soil Pollution",
          },
          { VideoName: "Chemistry 79.mp4", id: "509", topic: "Biotechnology" },
          {
            VideoName: "Chemistry 84.mp4",
            id: "510",
            topic: "Preparation of Soap",
          },
          {
            VideoName: "Chemistry 86.mp4",
            id: "511",
            topic: "Properties and Uses of Calcium",
          },
          { VideoName: "Chemistry 87.mp4", id: "512", topic: "Carbohydrates" },
          {
            VideoName: "Chemistry 88.mp4",
            id: "513",
            topic: "Destructive Distillation of Coal and Uses of Product",
          },
          {
            VideoName: "Chemistry 90.mp4",
            id: "514",
            topic: "Manufacture of Synthetic Gases and its Uses",
          },
          {
            VideoName: "Chemistry 91.mp4",
            id: "515",
            topic: "Carbon: Allotropes of Carbon",
          },
          { VideoName: "Chemistry 92.mp4", id: "516", topic: "Hydrogen" },
          { VideoName: "Chemistry 93.mp4", id: "517", topic: "Oxygen" },
        ],
      },
      {
        Subject: "Civic Education",
        content: [
          {
            VideoName: "Civic 51.mp4",
            id: "518",
            topic: "Bearing Influence of Foreign Donors",
          },
          { VideoName: "Civic 56.mp4", id: "519", topic: "Public Institions" },
          {
            VideoName: "Civic 57.mp4",
            id: "520",
            topic: "Factors Leading to Weak Public Institution",
          },
          {
            VideoName: "Civic 58.mp4",
            id: "521",
            topic: "Impact of Weak Institutions on the Polity of the Nation",
          },
          {
            VideoName: "Civic 59.mp4",
            id: "522",
            topic: "Impact of Weak Institutions on the Economy of the Nation",
          },
          {
            VideoName: "Civic 63.mp4",
            id: "523",
            topic: "Reasons for Government Regulations",
          },
          {
            VideoName: "Civic 64.mp4",
            id: "524",
            topic: "Importance of Government Regulations",
          },
          {
            VideoName: "Civic 65.mp4",
            id: "525",
            topic: "Criticism of Regulations in the Country",
          },
          {
            VideoName: "Civic 66.mp4",
            id: "526",
            topic: "Roles of Citizens to Ensure Public Growth",
          },
          { VideoName: "Civic 68.mp4", id: "527", topic: "E-Government" },
          { VideoName: "Civic 2.mp4", id: "886", topic: "Citizenship" },
          {
            VideoName: "Civic 4.mp4",
            id: "887",
            topic: "The Nationalistic Roles of Individuals and Groups",
          },
          {
            VideoName: "Civic 5.mp4",
            id: "888",
            topic: "The Identification of Local and World civic problems",
          },
          { VideoName: "Civic 6.mp4", id: "889", topic: "Human Rights" },
          {
            VideoName: "Civic 9.mp4",
            id: "890",
            topic: "Limitations to the Excercise of Human Rights",
          },
          {
            VideoName: "Civic 11.mp4",
            id: "891",
            topic: "Constituted Authority",
          },
          {
            VideoName: "Civic 13.mp4",
            id: "892",
            topic:
              "Importance of responsible parenthood in national development",
          },
          {
            VideoName: "Civic 14.mp4",
            id: "893",
            topic: "Traffic Regulations",
          },
          {
            VideoName: "Civic 15.mp4",
            id: "894",
            topic: "Interpersonal Relationships",
          },
          {
            VideoName: "Civic 17.mp4",
            id: "895",
            topic: "Inter-Communual Conflicts",
          },
          { VideoName: "Civic 18.mp4", id: "896", topic: "Cultism" },
          {
            VideoName: "Civic 19.mp4",
            id: "897",
            topic: "Drugs and Drug Abuse",
          },
          {
            VideoName: "Civic 20.mp4",
            id: "898",
            topic: "Symptoms of Drug Abuse",
          },
          {
            VideoName: "Civic 21.mp4",
            id: "899",
            topic: "Behaviours of Drug Addicts",
          },
          {
            VideoName: "Civic 25.mp4",
            id: "900",
            topic:
              "Efforts of Government and Individuals in Stopping Human Trafficking",
          },
          {
            VideoName: "Civic 26.mp4",
            id: "901",
            topic: "People Living with HIV/AIDS",
          },
          { VideoName: "Civic 28.mp4", id: "902", topic: "Youth Empowerment" },
          {
            VideoName: "Civic 29.mp4",
            id: "903",
            topic: "Efforts of Government Towards Youth Empowerment",
          },
          {
            VideoName: "Civic 31.mp4",
            id: "904",
            topic: "Meaning and Types of Democracy",
          },
          {
            VideoName: "Civic 32.mp4",
            id: "905",
            topic: "Importance and Pillars of Democracy",
          },
          {
            VideoName: "Civic 33.mp4",
            id: "906",
            topic: "Problems of Democracy",
          },
          { VideoName: "Civic 34.mp4", id: "907", topic: "Rule of Law" },
          {
            VideoName: "Civic 35.mp4",
            id: "908",
            topic: "Importance and Problems of Rule of Law",
          },
          {
            VideoName: "Civic 38.mp4",
            id: "909",
            topic: "The Meaning and Causes of Political Apathy",
          },
          {
            VideoName: "Civic 39.mp4",
            id: "910",
            topic: "Consequences of Political Apathy",
          },
          {
            VideoName: "Civic 40.mp4",
            id: "911",
            topic:
              "Why Leaders fail to protect the interests of their followers",
          },
          {
            VideoName: "Civic 41.mp4",
            id: "912",
            topic: "How Leaders can Protect the interests of their Followers",
          },
          {
            VideoName: "Civic 42.mp4",
            id: "913",
            topic: "Ways of Disouraging Political Apathy",
          },
          {
            VideoName: "Civic 43.mp4",
            id: "914",
            topic: "Popular Participation",
          },
          {
            VideoName: "Civic 45.mp4",
            id: "915",
            topic: "Traditional and Modern Mode of Popular Participation",
          },
          // { "VideoName": "Civic 50.mp4", "id": "916", "topic": "Problems of Civil Society" },
          // { "VideoName": "Civic 53.mp4", "id": "917", "topic": "Problems of the Public Service" },
          {
            VideoName: "Civic 54.mp4",
            id: "918",
            topic: "Reasons for the shortcomings in the Public Service",
          },
          {
            VideoName: "Civic 55.mp4",
            id: "919",
            topic: "Ways of solving the problems in the Public Service",
          },
          {
            VideoName: "Civic 61.mp4",
            id: "920",
            topic: "Regulatory Bodies in Nigeria",
          },
          {
            VideoName: "Civic 62.mp4",
            id: "921",
            topic: "Government Regulations",
          },
          { VideoName: "Civic 67.mp4", id: "922", topic: "Civic Unrest" },
          { VideoName: "Civic 69.mp4", id: "923", topic: "Civic Obligations" },
          {
            VideoName: "Civic 71.mp4",
            id: "924",
            topic: "Leadership and Followership",
          },
        ],
      },
      
      {
        Subject: "Christian Religious Knowledge",
        content: [
          {
            VideoName: "CRK 1.mp4",
            id: "531",
            topic: "Jesus as the lamb, the door and good shepherd",
          },
          {
            VideoName: "CRK 2.mp4",
            id: "532",
            topic: "Jesus the Light of the world and the True Vine",
          },
          {
            VideoName: "CRK 3.mp4",
            id: "533",
            topic: "Jesus as the Living Bread and Water",
          },
          { VideoName: "CRK 4.mp4", id: "534", topic: "God's Love for Man" },
          { VideoName: "CRK 5.mp4", id: "535", topic: "Love for One Another" },
          {
            VideoName: "CRK 6.mp4",
            id: "536",
            topic: "Justification by faith and Work",
          },
          { VideoName: "CRK 7.mp4", id: "537", topic: "New Life in Christ" },
          {
            VideoName: "CRK 8.mp4",
            id: "538",
            topic: "The fruit of the spirit",
          },
          {
            VideoName: "CRK 9.mp4",
            id: "539",
            topic: "The Resurrection And The Second Coming Of Christ",
          },
          { VideoName: "CRK 10.mp4", id: "540", topic: "Humility" },
          { VideoName: "CRK 11.mp4", id: "541", topic: "Effective Prayer" },
          {
            VideoName: "CRK 12.mp4",
            id: "542",
            topic: "Living a Life of witnessing for Christ",
          },
          {
            VideoName: "CRK 13.mp4",
            id: "543",
            topic: "Christian attitudes towards Persecution",
          },
          { VideoName: "CRK 14.mp4", id: "544", topic: "The Apostles Creed" },
          { VideoName: "CRK 15.mp4", id: "545", topic: "Baptism of Jesus" },
          { VideoName: "CRK 16.mp4", id: "546", topic: "Temptation of Jesus" },
          { VideoName: "CRK 17.mp4", id: "547", topic: "Mission of disciple" },
          { VideoName: "CRK 18.mp4", id: "548", topic: "The Holy spirit" },
          {
            VideoName: "CRK 19.mp4",
            id: "549",
            topic: "Mission to the gentiles",
          },
          {
            VideoName: "CRK 20.mp4",
            id: "550",
            topic: "Dignity of human labour",
          },
          {
            VideoName: "CRK 21.mp4",
            id: "551",
            topic: "Fellowship of the early Christian",
          },
          {
            VideoName: "CRK 22.mp4",
            id: "552",
            topic:
              "The Epistle of I Peter: Good Citizenship and Christians Living among non-Christians",
          },
          {
            VideoName: "CRK 23.mp4",
            id: "553",
            topic:
              "The Epistle of I Peter: Interpersonal Relationship among Christians",
          },
          {
            VideoName: "CRK 24.mp4",
            id: "554",
            topic: "Consequences of Saul’s disobedience",
          },
          {
            VideoName: "CRK 25.mp4",
            id: "555",
            topic: "Submission to the will of God: David",
          },
          {
            VideoName: "CRK 26.mp4",
            id: "556",
            topic: "Greed and its effects: Ahab and Gehazi",
          },
          {
            VideoName: "CRK 27.mp4",
            id: "557",
            topic: "Leadership roles Joseph and Moses, Joshua and Deborah",
          },
          {
            VideoName: "CRK 29.mp4",
            id: "558",
            topic: "Supremacy of God: Elijah at Mount Carmel",
          },
          {
            VideoName: "CRK 30.mp4",
            id: "559",
            topic: "The Epistles of James: Faith and Works",
          },
          {
            VideoName: "CRK 31.mp4",
            id: "560",
            topic: "The Epistles of James: Impartiality and Effective Prayers",
          },
          {
            VideoName: "CRK 32.mp4",
            id: "561",
            topic: "Parental Responsibility: Eli and Samuel",
          },
          {
            VideoName: "CRK 33.mp4",
            id: "562",
            topic: "Nature of God: True religion and social justice – Amos",
          },
          {
            VideoName: "CRK 34.mp4",
            id: "563",
            topic: "God’s divine love – Hosea",
          },
          { VideoName: "CRK 35.mp4", id: "564", topic: "The Trials of Jesus" },
          {
            VideoName: "CRK 36.mp4",
            id: "565",
            topic: "Jesus Teachings on Forgiveness",
          },
        ],
      },
      
      {
        Subject: "Further Mathematics",
        content: [
          {
            VideoName: "furtherMaths 1.mp4",
            id: "629",
            topic: "Introduction to Sets",
          },
          {
            VideoName: "furtherMaths 3.mp4",
            id: "630",
            topic: "Binary Operation",
          },
          {
            VideoName: "furtherMaths 4.mp4",
            id: "631",
            topic: "Rule of Syntax",
          },
          { VideoName: "furtherMaths 5.mp4", id: "632", topic: "Truth Table" },
          { VideoName: "furtherMaths 7.mp4", id: "633", topic: "Logarithms" },
          { VideoName: "furtherMaths 8.mp4", id: "634", topic: "Surds" },
          {
            VideoName: "furtherMaths 9.mp4",
            id: "635",
            topic: "Domain and Co-domain of a Function",
          },
          {
            VideoName: "furtherMaths 11.mp4",
            id: "636",
            topic: "Equalities and Inequalties",
          },
          {
            VideoName: "furtherMaths 12.mp4",
            id: "637",
            topic: "Arithmetic Geometric Series & Progression",
          },
          {
            VideoName: "furtherMaths 13.mp4",
            id: "638",
            topic: "Bearings, Trigonometric ratios of Special Angles",
          },
          {
            VideoName: "furtherMaths 14.mp4",
            id: "639",
            topic: "The Histogram and Bar Chart",
          },
          {
            VideoName: "furtherMaths 15.mp4",
            id: "640",
            topic: "Measures of Location",
          },
          {
            VideoName: "furtherMaths 16.mp4",
            id: "641",
            topic: "Measures of Dispersion",
          },
          { VideoName: "furtherMaths 17.mp4", id: "642", topic: "Polynomials" },
          {
            VideoName: "furtherMaths 18.mp4",
            id: "643",
            topic: "Factorization",
          },
          {
            VideoName: "furtherMaths 19.mp4",
            id: "644",
            topic:
              "Factor Roots of Quadratic Equation: The Elimation & Substitution Method",
          },
          {
            VideoName: "furtherMaths 20.mp4",
            id: "645",
            topic:
              "Roots of Quadratic Equation: The FOrmula Method and Completing the Square Method",
          },
          {
            VideoName: "furtherMaths 21.mp4",
            id: "646",
            topic: "Binomial Expansion Differentiation",
          },
          {
            VideoName: "furtherMaths 22.mp4",
            id: "647",
            topic: "Relative Frequency",
          },
          {
            VideoName: "furtherMaths 23.mp4",
            id: "648",
            topic: "Calculation of Probability Using Simple Sample Spaces",
          },
          {
            VideoName: "furtherMaths 24.mp4",
            id: "649",
            topic: "Addition and Multiplication of probabilities",
          },
          {
            VideoName: "furtherMaths 25.mp4",
            id: "650",
            topic: "Probability Distributions",
          },
          {
            VideoName: "furtherMaths 26.mp4",
            id: "651",
            topic: "Permutation and Combination",
          },
          {
            VideoName: "furtherMaths 27.mp4",
            id: "652",
            topic: "Trigonometric Functions",
          },
          {
            VideoName: "furtherMaths 28.mp4",
            id: "653",
            topic: "Definite and Indefinite Integrals",
          },
          {
            VideoName: "furtherMaths 29.mp4",
            id: "654",
            topic: "Vectors in Three Dimensions",
          },
          { VideoName: "furtherMaths 31.mp4", id: "655", topic: "Force" },
          {
            VideoName: "furtherMaths 35.mp4",
            id: "656",
            topic: "Moments of Force",
          },
          {
            VideoName: "furtherMaths 36.mp4",
            id: "657",
            topic: "Dynamics: Motions -Laws & Equations of Motion",
          },
          {
            VideoName: "furtherMaths 37.mp4",
            id: "658",
            topic: "The Impulse and Momentum Equations",
          },
          { VideoName: "furtherMaths 38.mp4", id: "659", topic: "Projectiles" },
          {
            VideoName: "furtherMaths 40.mp4",
            id: "660",
            topic: "Kinetic Energy and Potential Energy",
          },
          {
            VideoName: "furtherMaths 41.mp4",
            id: "661",
            topic: "Replacement Model",
          },
          {
            VideoName: "furtherMaths 42.mp4",
            id: "662",
            topic: "Inequalities",
          },
          {
            VideoName: "furtherMaths 43.mp4",
            id: "663",
            topic: "Matrices & Determinants 2 by 2 Matrices",
          },
          {
            VideoName: "furtherMaths 44.mp4",
            id: "664",
            topic: "Matrices & Determinants 3 by 3 Matrices",
          },
          {
            VideoName: "furtherMaths 45.mp4",
            id: "665",
            topic: "Inverse of a Matrix",
          },
          {
            VideoName: "furtherMaths 46.mp4",
            id: "666",
            topic: "Partial Fractions",
          },
          {
            VideoName: "furtherMaths 47.mp4",
            id: "667",
            topic: "Correlation, Spearman and Pearson Ranking",
          },
        ],
      },
      {
        Subject: "Geography",
        content: [
          {
            VideoName: "Geography 3.mp4",
            id: "668",
            topic: "Town, Village and Local Government Area",
          },
          {
            VideoName: "Geography 16.mp4",
            id: "669",
            topic: "Constraints on Nigeria's resources",
          },
          {
            VideoName: "Geography 24.mp4",
            id: "670",
            topic: "Industry in Nigeria",
          },
          {
            VideoName: "Geography 25.mp4",
            id: "671",
            topic: "The Impact of Geography on Industry",
          },
          {
            VideoName: "Geography 30.mp4",
            id: "672",
            topic: "Environmental Conservation",
          },
          {
            VideoName: "Geography 39.mp4",
            id: "673",
            topic: "The Nigerian Economy",
          },
          {
            VideoName: "Geography 41.mp4",
            id: "674",
            topic: "Geopolitical Issues",
          },
          {
            VideoName: "Geography 43.mp4",
            id: "675",
            topic: "Representation of Relief Forms",
          },
          { VideoName: "Geography 54.mp4", id: "676", topic: "Oil Production" },
          {
            VideoName: "Geography 55.mp4",
            id: "677",
            topic: "Plantation Agriculture",
          },
          {
            VideoName: "Geography 1.mp4",
            id: "925",
            topic: "Meaning of Geography",
          },
          {
            VideoName: "Geography 2.mp4",
            id: "926",
            topic: "Careers related to Geography",
          },
          {
            VideoName: "Geography 4.mp4",
            id: "927",
            topic: "The Earth and Other Planets",
          },
          {
            VideoName: "Geography 5.mp4",
            id: "928",
            topic: "Earth Rotation and Revolution",
          },
          { VideoName: "Geography 7.mp4", id: "929", topic: "Rocks" },
          { VideoName: "Geography 8.mp4", id: "930", topic: "Mountains" },
          { VideoName: "Geography 9.mp4", id: "931", topic: "Lowlands" },
          { VideoName: "Geography 10.mp4", id: "932", topic: "Climate" },
          {
            VideoName: "Geography 11.mp4",
            id: "933",
            topic: "The Geography Nigeria",
          },
          { VideoName: "Geography 12.mp4", id: "934", topic: "Weather" },
          {
            VideoName: "Geography 13.mp4",
            id: "935",
            topic: "The Environment",
          },
          {
            VideoName: "Geography 14.mp4",
            id: "936",
            topic: "Physical Setting of Nigeria",
          },
          {
            VideoName: "Geography 15.mp4",
            id: "937",
            topic: "Resources in Nigeria",
          },
          {
            VideoName: "Geography 17.mp4",
            id: "938",
            topic: "Population of Nigeria",
          },
          {
            VideoName: "Geography 18.mp4",
            id: "939",
            topic: "Map Reading and Distances",
          },
          {
            VideoName: "Geography 19.mp4",
            id: "940",
            topic: "Map Enlargement and Reduction",
          },
          {
            VideoName: "Geography 20.mp4",
            id: "941",
            topic: "Interpretation of Physical and Cultural Features",
          },
          {
            VideoName: "Geography 22.mp4",
            id: "942",
            topic: "Climatic Classification",
          },
          {
            VideoName: "Geography 23.mp4",
            id: "943",
            topic: "Environmental Resources",
          },
          { VideoName: "Geography 26.mp4", id: "944", topic: "GIS" },
          {
            VideoName: "Geography 27.mp4",
            id: "945",
            topic: "Renewable and Non-Renewable Resources",
          },
          {
            VideoName: "Geography 28.mp4",
            id: "946",
            topic: "Earth's External Processes and Landform Development",
          },
          {
            VideoName: "Geography 29.mp4",
            id: "947",
            topic: "Environmental Problems",
          },
          // { "VideoName": "Geography 31.mp4", "id": "948", "topic": "Agriculture" },
          // { "VideoName": "Geography 32.mp4", "id": "949", "topic": "Problems Facing Agriculture" },
          // { "VideoName": "Geography 35.mp4", "id": "950", "topic": "Problems of Transportation" },
          // { "VideoName": "Geography 37.mp4", "id": "951", "topic": "Manufacturing Indusries in Nigeria" },
          // { "VideoName": "Geography 38.mp4", "id": "952", "topic": "Population" },
          // { "VideoName": "Geography 40.mp4", "id": "953", "topic": "Settlements" },
          // { "VideoName": "Geography 42.mp4", "id": "954", "topic": "Direction and Bearing" },
          // { "VideoName": "Geography 45.mp4", "id": "955", "topic": "Weathering" },
          // { "VideoName": "Geography 47.mp4", "id": "956", "topic": "ECOWAS" },
          // { "VideoName": "Geography 48.mp4", "id": "957", "topic": "Remote Sensing" },
          // { "VideoName": "Geography 56.mp4", "id": "958", "topic": "Lumbering, Gold Mining and Copper Mining" }
        ],
      },
      
      {
        Subject: "Literature in English",
        content: [
          {
            VideoName: "LIT 1.mp4",
            id: "713",
            topic: "Concept of Literature and Literature Techniques",
          },
          { VideoName: "LIT 2.mp4", id: "714", topic: "Literary Awards" },
          { VideoName: "LIT 3.mp4", id: "715", topic: "Major Types of Drama" },
          { VideoName: "LIT 4.mp4", id: "716", topic: "Other Forms of Drama" },
          {
            VideoName: "LIT 5.mp4",
            id: "717",
            topic: "Elements of Drama: Plot & Background/Setting",
          },
          {
            VideoName: "LIT 6.mp4",
            id: "718",
            topic: "Elements of Drama: Theme and Character Analysis",
          },
          {
            VideoName: "LIT 10.mp4",
            id: "719",
            topic: "Poetry and Classification of Poetry",
          },
          {
            VideoName: "LIT 11.mp4",
            id: "720",
            topic: "Poetic/Literary Devices",
          },
          {
            VideoName: "Lit 25.mp4",
            id: "721",
            topic: "Anecdote and Innuendo",
          },
          {
            VideoName: "LIT 22.mp4",
            id: "722",
            topic: "Metonymy and Oxymoron",
          },
          {
            VideoName: "LIT 24.mp4",
            id: "723",
            topic: "Personification, Magical Realism and Fable",
          },
          { VideoName: "LIT 12.mp4", id: "724", topic: "Themes in Poetry" },
          {
            VideoName: "LIT 13.mp4",
            id: "725",
            topic: "Subject Matter of a Poem",
          },
          { VideoName: "LIT 14.mp4", id: "726", topic: "Rhymes" },
          {
            VideoName: "Lit 15.mp4",
            id: "727",
            topic: "Concepts and Types of Prose",
          },
          { VideoName: "LIT 16.mp4", id: "728", topic: "Elements of Prose" },
          {
            VideoName: "LIT 17.mp4",
            id: "729",
            topic: "Biography and Autobiography",
          },
          { VideoName: "LIT 18.mp4", id: "730", topic: "Plots of a Prose" },
          {
            VideoName: "LIT 20.mp4",
            id: "731",
            topic: "Figures of Speech: Simile and Metaphor",
          },
          {
            VideoName: "LIT 19.mp4",
            id: "732",
            topic: "Literary Appreciation",
          },
          {
            VideoName: "LIT 21.mp4",
            id: "733",
            topic: "Careers in Literature",
          },
        ],
      },
    ],
  },
];

export default VideoData;
