import React from 'react';

const TogglableItem = ({src, name}) => {
    return (
        <div className="link">
          <img src={src} alt="play button" />
          <span>{name}</span>
          <i className="fa fa-chevron-down"></i>
        </div>
    )
}

export default TogglableItem;