import {connect} from 'react-redux';
import Home from './Home';
import { setHistory } from '../../creators/historyCreator';

const mapStateToProps = ({user, history}) => {
    return {user, history};
}

const ConnectedHome = connect(mapStateToProps, {setHistory})(Home)

export default ConnectedHome;
