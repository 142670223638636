import React from 'react'

const NoTimeNav = ({text}) => {
    return (
        <div className="row">
           <div className="mode-styling no-time-mode" >
            <span className="mode">{text}</span>
        </div>
        </div>
       
    )
}

export default NoTimeNav;






    