import React, {useState, useEffect} from 'react'

export default function TimeDecision({timeValue}) {
    const [hour, setHour] = useState('00');
    const [minute, setMinutes] = useState('00');
    //Call this when state changes
    useEffect(() => {
        timeValue(addTime(hour, minute))
    }, [hour, minute, timeValue])
    
    const hourValidation = (val) => {
        return val <= 5 && val >= 0;
    }
    const minuteValidation = (val) => {
        return val <= 59 && val >= 0;
    }

    const studyTimeFiller = (val) => {
        const value = Number.parseInt(val);
        return value.toString().length <= 1 ? `0${value}` : value;
    }

    const addTime = (h, m) => Number.parseInt(h) * 60 * 60 + Number.parseInt(m) * 60
    
    const onHourChange = (e) => {
        const value = e.target.value;
        if(hourValidation(Number.parseInt(value))){
            setHour(studyTimeFiller(value));
            
        }
    }

    const onMinuteChange = (e) => {
        const value = e.target.value;
        const v = studyTimeFiller(value)
        if(minuteValidation(Number.parseInt(value))){
            setMinutes(studyTimeFiller(value));
        }
    }
    return (
        <React.Fragment>
            <div className="row edit set-time">Set Time</div>
            <div className="row">
                <div className="col-md-6 col-xs--6 pad-percent">
                  <input className="widthy another-left" onChange={onHourChange} value={hour} type="text" />
                  <div className="row edit edit-left">Hours</div>
                </div>
               <div className="col-md-6 col-xs--6 pad-percent">
                 <input className="widthy another" onChange={onMinuteChange} value={minute} type="text" />
                 <div className="row edit edit-right">Minutes</div>
               </div>
            </div>
            <div className="decision-bg">
                <p className="m-0 text-left">This simulates exam environment with timing.You can generate your result and review
                questions afterwards</p>
            </div>
        </React.Fragment>
   
    )
}
