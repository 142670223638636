// The root reducer for our app
import { combineReducers } from "redux";
import userReducer from "../reducers/userReducer";
import loaderReducer from "../reducers/loaderReducer";
import historyReducer from "../reducers/historyReducer";
import navReducer from "../reducers/navReducer";
import featuredCoursesReducer from "../reducers/featuredCoursesReducer";
import courseReducer from "../reducers/courseReducer";
import videoReducer from "../reducers/videoReducer";
import hamburgerReducer from "../reducers/hamburgerReducer";
import modeReducer from "../reducers/modeReducer";
import timeValueReducer from "../reducers/timeValueReducer";
import singleCourseReducer from "../reducers/singleCourseReducer";
import singleVideoReducer from "../reducers/singleVideoReducer";
import activeUserReducer from "../reducers/activeUserReducer";
import activeCourseReducer from "../reducers/activeCourseReducer";
import userCoursesReducer from "../reducers/userCoursesReducer";
import userTestReducer from "../reducers/userTestReducer";
import timeUpReducer from "../reducers/timeUpReducer";
import testDataReducer from "../reducers/testDataReducer";
import reviewReducer from '../reducers/reviewReducer';

const rootReducer = combineReducers({
  users: userReducer,
  user: activeUserReducer,
  loading: loaderReducer,
  history: historyReducer,
  navType: navReducer,
  featuredCourses: featuredCoursesReducer,
  courses: courseReducer,
  videos: videoReducer,
  hamburger: hamburgerReducer,
  mode: modeReducer,
  time: timeValueReducer,
  singleCourse: singleCourseReducer,
  singleVideo: singleVideoReducer,
  activeCourse: activeCourseReducer,
  userCourses: userCoursesReducer,
  userTest: userTestReducer,
  timeUp: timeUpReducer,
  testData: testDataReducer,
  review: reviewReducer,
});

export default rootReducer;
