import ls from '../sideEffects/ourLocalStorage'
const modeReducer = (state = null, action) => {
    switch (action.type) {
        case "SET_MODE":
            ls.persistItemInLS("mode", action.data, 2)
            return action.data;
        default:
            return state;
    }
}

export default modeReducer;