import React from "react";
import { setHistory } from "../../../creators/historyCreator";
import Slider from "react-slick";

function SimpleSlider() {
  const settings = {
    dots: true,
    //infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    //arrows: false
  };
  return (
    <div>
      <Slider {...settings}>
        <div>
          <div className="text-center" style={{ position: "relative" }}>
            <div className="slider-caption">
              <h1>Access quality content, everytime</h1>
              <h5>
                Access quality content, every time find all the lessons you need
                to excel in your next examinations on our platform, as we
                provide over 1000 of them here
              </h5>
            </div>

            <img
              src="images/SLIDE-1.png"
              className="img-responsive text-center"
              alt="register animate"
            />
          </div>
        </div>
        <div>
          <div className="text-center" style={{ position: "relative" }}>
            <div className="slider-caption">
              <h1> Choose, your Studymate, wisely</h1>
              <h5>
                We know you have got several friends out there. when it comes to
                your studies, you ought to choose that one that gets you the A
                grade you dream. Choose us.
              </h5>
            </div>

            <img
              src="images/SLIDE-2.png"
              className="img-responsive text-center"
              alt="register animate"
            />
          </div>
        </div>
        <div>
          <div className="text-center" style={{ position: "relative" }}>
            <div className="slider-caption">
              <h1> Track your progress</h1>
              <h5 style={{ color: "white" }}>
                Take tests in short bursts to benchmark your learning outcome
                and improve your motivation to go further ahead with your
                studies. Enjoy learning
              </h5>
            </div>

            <img
              src="images/SLIDE-3.png"
              className="img-responsive text-center"
              alt="register animate"
            />
          </div>
        </div>
      </Slider>
    </div>
  );
}

const RegisterForm = (props) => {
  console.log("sethistory props is", props);
  return (
    <div
      className="container-fluid register-wrapper"
      style={{ paddingLeft: "0px", paddingRight: "0px" }}
    >
      <div className="containerr">
        <div className="row">
          <div className="col-md-7 package hide-mobile">
            <SimpleSlider />
          </div>

          <div className="col-md-5 register-details">
            <div className="position-form">
              <div className="text-center">
                <img
                  src="images/logo-svg.svg"
                  alt="studymate logo"
                  style={{ width: "35%" }}
                />
                <h3>Your Dependable Learning Buddy</h3>
              </div>
              <form onSubmit={props.submitForm}>
                <div className="form-row">
                  <div className="form-group col-12">
                    <input
                      {...props.firstNameField}
                      required
                      className="form-control"
                      placeholder="First Name"
                    />
                    {!props.firstSuccess && (
                      <p className="text-small">
                        We believe your first name is like a typical homo
                        sapiens
                      </p>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <input
                      {...props.surNameField}
                      required
                      className="form-control"
                      placeholder="Last Name"
                    />
                    {!props.lastSuccess && (
                      <p className="text-small">
                        We believe your last name too is like a human
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <input
                    {...props.emailField}
                    required
                    className="form-control"
                    placeholder="Email"
                  />
                  {!props.emailSuccess && (
                    <p className="text-small">
                      Come on, your gmail, yahoo, ymail, yahoomail or outlook
                      account is just sufficient
                    </p>
                  )}
                </div>

                <div className="form-row">
                  <div className="form-group col-4">
                    <select
                      value={props.sex}
                      onChange={(e) => props.setSex(e.target.value)}
                      className="form-control select_"
                    >
                      <option>Female</option>
                      <option>Male</option>
                    </select>
                  </div>
                  <div className="form-group col-8">
                    <input
                      {...props.phoneNumberField}
                      required
                      className="form-control"
                      placeholder="phone"
                    />
                    {!props.phoneSuccess && (
                      <p className="text-small">
                        For simplicity just start with "0", leave the "+"
                      </p>
                    )}
                  </div>
                </div>

                <div className="agreement mt-4 mb-4 text-center">
                  <span>
                    By submitting this form, I agree to StudyMate Privacy Policy
                    and User Agreement.
                  </span>
                </div>
                {props.firstSuccess &&
                  props.lastSuccess &&
                  props.emailSuccess &&
                  props.phoneSuccess && (
                    <button type="submit" className="register">
                      Sign up
                    </button>
                  )}
              </form>
              <div className="agreement mt-4 mb-4 text-center">
                {props.users && props.users.length > 0 && (
                  <>
                    <span>Have an account?</span>
                    <button
                      className="reg-login"
                      onClick={() => props.gotoLogin()}
                    >
                      LOGIN
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
