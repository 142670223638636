import React, { useState, useEffect } from "react";
import VideoList from "./VideoList";
import Togglable from "../SideNav/Togglable";
import TogglableItem from "../SideNav/TogglableItem";
import FirstLevel from "../SideNav/FirstLevel";
import SecondLevel from "../SideNav/SecondLevel";
import AccordionVideos from "../SideNav/AccordionVideo";
import ConnectedSecondSearchBar from "../Navigation/ToRightOfNav/Searchbar/ConnectedSecondSearch";

const StudyVideo = ({
  isBusy,
  id,
  addVideo,
  videos,
  contents,
  setHistory,
  subject,
  ...props
}) => {
  const [showItem, setShowItem] = useState(false);

  props.type("SEARCH_BAR");
  console.log("contente", contents);
  console.log(props.history);
  let sideVideos = null;
  let mainVideo = null;
  let contentsWhenNull = null;
  let subjectWhenNull;
  if (id === null && videos.length > 0) {
    // console.log("my viddddd", videos);
    // console.log("yes o first", subject);
    const ss = videos[1];
    const firstVideo = ss.Video[0];
    contentsWhenNull = firstVideo.content;
    subjectWhenNull = firstVideo.Subject;
    sideVideos = contentsWhenNull
      ? contentsWhenNull.filter((video) => {
          return video.id !== contentsWhenNull[0].id;
        })
      : null;

    mainVideo = contentsWhenNull[0];
  } else {
    console.log("yes o second", subject);
    sideVideos = contents
      ? contents.filter((video) => {
          return video.id !== id;
        })
      : null;
    mainVideo = contents ? contents.find((video) => video.id === id) : null;
  }

  console.log(id, setHistory);

  return (
    <div className="container-fluid px">
      <div className="video-section custom-carousel">
        <div className="row">
          <div style={{ display: showItem ? "none" : "block" }}>
            <div className="container px-0">
              <div className="col-md-12 col-sm-12 col-xs-12 px-0">
                <h1 className=" page-title">Choose your preferred course</h1>
              </div>
            </div>

            <CourseNavigator
              setHistory={setHistory}
              type="video"
              videos={videos}
            />
            <div className="container px-0">
              <div className="col-md-12 col-sm-12 col-xs-12 searchpart px-0">
                <ConnectedSecondSearchBar />
              </div>
            </div>
          </div>

          <div className="container px-0">
            <div className="col-md-9 col-sm-12 col-xs-12 px-0">
              {showItem ? (
                <button className="unstyled" onClick={() => setShowItem(false)}>
                  <h1 className=" page-title mb-4 mt-2">
                    <img src="images/backArrow.svg" alt="back arrow" />
                    {props.history.subject
                      ? props.history.subject
                      : subjectWhenNull}
                  </h1>
                </button>
              ) : (
                <h1 className=" page-title mb-4 mt-2">
                  {props.history.subject
                    ? props.history.subject
                    : subjectWhenNull}
                </h1>
              )}

              {mainVideo && (
                <div className="video-container">
                  <div className="video-player">
                    <video
                      key={mainVideo.VideoName}
                      id="vid_source"
                      onPlay={() => setShowItem(true)}
                      width="100%"
                      controls
                      key={mainVideo.VideoName}
                      poster="images/vidThumbnail.png"
                    >
                      <source
                        src={`video/${mainVideo.VideoName || "good.mp4"}`}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div className="video-title">
                    <h2>{mainVideo.topic}</h2>
                  </div>
                  <div className="video-description">
                    <h5> </h5>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-3 col-sm-12 col-xs-12 padding-right0 px--0">
              <div className="videoList-container px--0">
                {sideVideos && (
                  <VideoList
                    subject={
                      (props.history && props.history.subject) ||
                      subjectWhenNull
                    }
                    list={sideVideos}
                    setHistory={setHistory}
                    contentz={contents || contentsWhenNull}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CourseNavigator = (props) => {
  const [school, setSchool] = useState("senior");
  const setHistory = (path, id, contents, name) => {
    if (path === "video") {
      props.setHistory({
        path,
        id,
        contents,
        subject: name,
      });
    } else if (path === "course") {
      props.setHistory({ path: "courses", id, search: false });
    }
  };

  const handleSchool = (type) => {
    setSchool(() => type);
  };
  return (
    <div className="container__">
      <div className="col-md-12 col-sm-12 col-xs-12 px-0">
        {props.type === "video" ? (
          <div>
            <div
              className={`mx-15 schoolLink ${
                school && school == "senior" && "bold"
              }`}
              id="senior"
              onClick={() => handleSchool("senior")}
            >
              Senior Secondary
            </div>
            <div
              className={`schoolLink ${school && school == "junior" && "bold"}`}
              id="junior"
              onClick={() => handleSchool("junior")}
            >
              Junior Secondary
            </div>
            {school == "junior" ? (
              <div className="grid-container">
                <CourseNavigatorItem
                  type={props.type}
                  setHistory={setHistory}
                  juniorSubjects={props.videos.length ? props.videos[0] : null}
                />
              </div>
            ) : (
              <div className="grid-container">
                <CourseNavigatorItem
                  type={props.type}
                  setHistory={setHistory}
                  juniorSubjects={props.videos.length ? props.videos[1] : null}
                />
              </div>
            )}
          </div>
        ) : (
          // <ul className="tabs">
          //   <li className="tab">
          //     <input type="radio" name="tabs" checked="checked" id="tab1" />

          //   </li>
          //   <li class="tab">
          //     <input type="radio" name="tabs" id="tab2" />
          //     <label htmlFor="tab2">Senior Secondary School</label>

          //   </li>
          // </ul>
          <div className="carousel-component">
            <div className="grid-container">
              <CourseNavigatorItem
                type={props.type}
                setHistory={setHistory}
                juniorSubjects={props.courseData}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const CourseNavigatorItem = ({ juniorSubjects, setHistory, type }) => {
  return (
    <main className="grid-item main">
      <SecondLevel
        type={type}
        setHistory={setHistory}
        juniorSubjects={juniorSubjects}
      />
    </main>
  );
};

export default StudyVideo;
