import ls from '../sideEffects/ourLocalStorage'
const activeCourseReducer = (state = null, action) => {
    console.log("state",state, action.data)
    switch (action.type) {
        case "SET_ACTIVE_COURSE":
            ls.persistItemInLS("activeCourse", action.data, 3)
            return action.data;
        case "PERSIST_ACTIVE_COURSE":
            return action.data;
        case "UPDATE_SCORE":
            return {...state, score: action.data};
        case "UPDATE_ACTIVE_COURSE":
            console.log("question in reducer", state, action.data)
            const activeCourse = { ...state, question: 
                state.question.find(question => 
                    question.questionId === action.data.questionId) ? state.question.map(question => 
                        question.questionId === action.data.questionId ? action.data: question) : state.question.concat(action.data) }
            ls.persistItemInLS("activeCourse", activeCourse, 3)
            return activeCourse;
        default:
            return state;
    }
}

export default activeCourseReducer;