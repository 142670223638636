import React, { useState, useEffect } from "react";
import useCustomTime from "../../../../customHooks/useCustomTime";

const TimeNav = ({ time, timeUp, timeValue }) => {
  const { hrs, mins, secs, currentTime } = useCustomTime(time);
  const [changeDetector, setChangeDetector] = useState(0);
  console.log(currentTime);
  useEffect(() => {
    return () => timeValue(currentTime / 1000);
  });

  useEffect(() => {
    return () => timeUp(false);
  });

  if (currentTime <= 0) {
    timeUp(true);
  }

  return (
    <React.Fragment>
      <div className="mode-styling row blue-row">
        {/* <div className="move-time d-inline"> Time mode</div>{" "} */}
        <div className="row new-blue">
          {" "}
          <div className="col-md-4 col-xs--4 reduced-timing-padding">
            <div>
              <span className=" timing"> {hrs}</span>
            </div>
            <div className="timing-top"> hrs </div>
          </div>
          <div className="col-md-4 col-xs--4 reduced-timing-padding ">
            <div>
              <span className=" timing"> {mins}</span>
            </div>
            <div className="timing-top"> mins </div>
          </div>
          <div className="col-md-4 col-xs--4 reduced-timing-padding ">
            <div>
              <span className=" timing"> {secs}</span>
            </div>
            <div className="timing-top"> secs </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TimeNav;
