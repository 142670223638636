import { connect } from "react-redux";
import { addVideo, editVideo } from "../../creators/singleVideoCreator";
import StudyVideo from "./StudyVideo";
import { setHistory } from "../../creators/historyCreator";

const mapStateToProps = ({ singleVideo, videos, history, subject }) => {
	return { singleVideo, videos, history, subject };
};

const ConnectedVideo = connect(mapStateToProps, {
	addVideo,
	editVideo,
	setHistory,
})(StudyVideo);

export default ConnectedVideo;
