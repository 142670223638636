import React, { useState } from "react";
import ls from "../../sideEffects/ourLocalStorage";
import { useHistory } from "react-router-dom";

export default function Overlay(props) {
  const appHistory = useHistory();
  const [show, setShow] = useState(false);
  console.log("total question", props.totalQuestions);
  const handleClose = () => {
    setShow(false);
    console.log("this is the user", props.user);
    if (props.mode === "SKILL_MODE") {
      const updatedUser = { ...props.user, hasTakenTest: true, totalScore: 30 };
      console.log("updated user", updatedUser);
      props.editUser(updatedUser);
      props.setActiveUser(updatedUser);
      console.log("frustrating", props.user);
      console.log(props.activeCourse);
      appHistory.push("/thanks")
    } else dismissQuiz();
    document.body.style.overflowY = "auto"
    document.body.style.height = "100%"
    document.body.style.width = "100%"
    document.body.style.position = "relative"
  };

  const handleShow = () => {
    props.sendQuestionToStore();
    setShow(true);
    document.body.style.overflowY = "hidden";
    document.body.style.height = "100%";
    document.body.style.width = "100%";
    document.body.style.position = "fixed";
  };

  const point = [
    {
      Ypercentage: "4",
      Ypoints: "20",
      Ppercentage: "80",
      Ypoints: "400",
    },
  ];

  const [review, setReview] = useState();
  const handleReview = () => {
    setReview(true);
  };

  const dismissQuiz = () => {
    appHistory.push("/thanks")
  }

  const reviewMode = () => {
    props.startReview();
    document.body.style.overflowY = "auto"
    document.body.style.height = "100%"
    document.body.style.width = "100%"
    document.body.style.position = "relative"
   
  }

  return (
    <React.Fragment>
      <section id="quiz" className={show ? "overlays" : "overlay-none"}>
        <div className={show ? "overlays" : "overlay-none"}>
          <div className="row get-down">
            <div className="col-md-12">
              <div
                className="card centered my-auto mx-auto branded"
                style={{ width: "54rem", marginBottom: "40px important" }}
              >
                <h5 className="card-header result text-center">Result</h5>
                <div className="card-body blue-box box">
                  {point.map((points, i) => (
                    <div className="underline" key={i}>
                      <div className="row">
                        <div className="col-md-6">
                          <p className="score">Your Score:</p>
                        </div>
                        <div className="col-md-6">
                          <p className="points">
                            {!isNaN(
                              props.activeCourse.score / props.totalQuestions
                            )
                              ? (
                                  (props.activeCourse.score /
                                    props.totalQuestions) *
                                  100
                                ).toFixed(2)
                              : 0}
                            % ({props.activeCourse.score} points)
                          </p>
                        </div>
                      </div>
                      <div className="row row-bottom">
                        <div className="col-md-6">
                          <p className="score">Passing Score:</p>
                        </div>
                        <div className="col-md-6">
                          <p className="points">50% (4 Points)</p>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="row row-top">
                    <div className="col-md-6 col-12">
                      <p className="result-score">Result:</p>
                    </div>
                    <div className="col-md-6 col-12">
                      <ResultOutcome passed={props.activeCourse.score >= 4} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 white text-center pad-up">
                  {props.mode !== "SKILL_MODE" && (
                    <button
                      onClick={reviewMode}
                      className="color-button yellow"
                    >
                      Review Quiz
                    </button>
                  )}

                  <button onClick={handleClose} className="finish-button blue">
                    Next
                  </button>
                </div>
                <div className="center"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {props.mode !== "REVIEW_MODE" ? (
        <button onClick={handleShow} className={props.className}>
          SUBMIT
        </button>
      ) : (
        <button onClick={dismissQuiz} className={props.className}>
          FINISH
        </button>
      )}
    </React.Fragment>
  );
}

const ResultOutcome = ({ passed }) => {
  return (
    <p className="points">
      <img
        className="img-logo"
        alt={passed ? "success indication" : "failure indication"}
        src={passed ? "images/tick.png" : "images/red.png"}
      />
      {passed ? "Congratulations" : "You did not pass"}
    </p>
  );
};
