import React, { useState, useRef } from "react";
import SecondLevelContent from "./SecondLevelContent";
// import Flickity from "react-flickity-component";
// // import "./flickity.css";

// function Carousel(props) {
//   return (
//     <Flickity>
//       {props.sliders.map((slide) => {
//         return <img src="https://placeimg.com/640/480/animals" />;
//       })}
//     </Flickity>
//   );
// }

const SecondLevel = ({ juniorSubjects, setHistory, type }) => {
  const itemsRef = useRef(null);
  const [className, setClassName] = useState("");
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(itemsRef && itemsRef.scrollLeft);

  const mouseLeavesOrUp = () => {
    setClassName("");
    setIsDown(false);
  };

  const mouseDown = (e) => {
    setClassName("active");
    setIsDown(true);
    setStartX(e.pageX - itemsRef.current.offsetLeft);
    setScrollLeft(itemsRef.current.scrollLeft);
  };
  const mouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - itemsRef.current.offsetLeft;
    const walk = (x - startX) * 3;
    itemsRef.current.scrollLeft = scrollLeft - walk;
    console.log("srollleft on slide", itemsRef.current.scrollLeft);
  };

  const moveLeftward = (e) => {
    itemsRef.current.scroll({
      left: itemsRef.current.scrollLeft - itemsRef.current.offsetWidth,
      behavior: "smooth",
    });
  };

  const moveRightward = () => {
    itemsRef.current.scroll({
      left: itemsRef.current.scrollLeft + itemsRef.current.offsetWidth,
      behavior: "smooth",
    });
  };
  console.log("junir", juniorSubjects);
  return (
    <div className="custom-carousel">
      <div
        style={{
          position: "relative",
          marginLeft: "-15px",
        }}
      >
        <div
          className={`items ${className}`}
          onMouseLeave={mouseLeavesOrUp}
          onMouseUp={mouseLeavesOrUp}
          onMouseMove={mouseMove}
          onMouseDown={mouseDown}
          ref={itemsRef}
          style={{
            position: "relative",
            width: "100%",
            borderRadius: "0px",
            marginRight: "-15px",
          }}
        >
          {type === "video" &&
            juniorSubjects &&
            //  (
            //   <Carousel sliders={juniorSubjects.Video} />
            // )
            juniorSubjects.Video.map((subject, key) => {
              return (
                <SecondLevelContent
                  key={key}
                  id={subject.content[0].id}
                  setHistory={setHistory}
                  type={type}
                  subjects={subject}
                  name={subject.Subject}
                />
              );
            })}
          {type === "course" &&
            juniorSubjects &&
            juniorSubjects.map((course) => {
              return (
                <SecondLevelContent
                  key={course.id}
                  type={type}
                  id={course.id}
                  setHistory={setHistory}
                  name={course.name}
                />
              );
            })}
        </div>
        <div>
          <img
            src="images/previous_.svg"
            className="b-previous items-control"
            onClick={moveLeftward}
          />

          <img
            src="images/next_.svg"
            className="b-next items-control"
            onClick={moveRightward}
          />
        </div>
      </div>
    </div>
  );
};

export default SecondLevel;
