import React, { useEffect, useState } from "react";
import ls from "../sideEffects/ourLocalStorage";
import AppPresentation from "./AppPresentation";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import ConnectedLogin from "../components/Authentication/Login/ConnectedLogin";
import AuthLayout from "../components/Layouts/AuthLayout/AuthLayout";
import AuthNavigation from "../components/Navigation/AuthNavigation/AuthNavigation";
import ConnectedRegister from "../components/Authentication/Register/ConnectedRegister";
import { VideoData } from "../helpers/videoData";
import { coursesData } from "../components/Data/Data";
import { Route, Redirect, useLocation } from "react-router-dom";
import ConnectedHome from "../components/Home/ConnectedHome";
import LandingPage from "../components/Home/LandingPage";
import ErrorBoundary from "../ErrorBoundary";
import { postUserCourse } from "../sideEffects/apis/api";

const validDate = (date) => {
	let valid = true;
	if(date){
	  const today = new Date();
	  const lastDate = new Date(date);
	  const diffDateInMilSecs = today - lastDate;
	  const diffInSecs = diffDateInMilSecs / 1000;
	  const diffInMins = diffInSecs / 60;
	  const diffInHrs = diffInMins / 60;
	  const diffInDays = diffInHrs / 24;
	  if(diffInDays < 3){
		valid = false;
	  }
	  valid = true;
	}
	return valid;
  }
  

const studies = coursesData;
const videos = VideoData;
function App(props) {
	const [sent, setSent] = useState(false)
	const location = useLocation();
	const url = location && location.search ? location.search.substring(location.search.indexOf('=')+1) : "";
	//    const url = search ? search.substring(search.indexOf('=') + 1) : ''
	//get current user
	const userFromStorage = ls.getItemInLs("user");
	let user = props.user;
	if (!user && userFromStorage) {
		props.setActiveUser(userFromStorage);
		user = userFromStorage;
	}
	useEffect(() => {
		props.setTestData(studies);
		props.setVideos(videos);
	}, [VideoData, coursesData]);
	//get all users
	const usersFromStorage = ls.getItemInLs("users");
	let users = props.users;
	if (!users.length && usersFromStorage) {
		props.persistUser(usersFromStorage);
		users = usersFromStorage;
	}
	// const user = props.user
	// const users = props.users
	//get history
	const historyFromStorage = ls.getItemInLs("history");
	!props.history && props.setHistory(historyFromStorage);
	const history = props.history || historyFromStorage;

	//get time
	const timeFromStorage = ls.getItemInLs("time");
	!props.time && props.timeValue(timeFromStorage);

	//get activeCourse
	const acFromStorage = ls.getItemInLs("activeCourse");
	!props.activeCourse && props.persistActiveCourse(acFromStorage);

	//get activeCourse
	const coursesFromStorage = ls.getItemInLs("courses");
	!props.courses && props.setCourses(coursesFromStorage);

	console.log("user from app", user);
	console.log("users from app", users);

// const user = props.user
  // const users = props.users
  //Push user to store if there is no date or date has expired
  //Date expires after 3 days
  //After 3 days we make another push
  

  useEffect(() => {
    if(!sent){
      const lastDate = ls.getItemInLs("sentOn");
      if(validDate(lastDate)){
        const userCourses = users.length &&
        users.reduce((acc, cur) => {
          if(cur.hasTakenTest){
			const {firstName, surName, email, phoneNumber, sex, totalScore} = cur;
			console.log({cur})
            const uc = {firstName, surName, email, phoneNumber, sex, textScore: totalScore}
            cur.courses.forEach(course => {
              if(course.mode !== "LEARN_MODE" && course.mode !== "SKILL_MODE")
              {
                acc = acc.concat({...uc, testId: course.courseId, score: course.score, dateTaken: course.date || new Date()})
              }
            })
          }
          return acc;
        }, [])
        if(window.navigator.onLine && userCourses.length){
          console.log("Hello")
		  postUserCourse(userCourses)
				.then(res => {
					console.log({res})
					ls.persistItemInLS("sentOn", new Date())
				})
				.catch(err => console.log(err))
        }
      }
      setSent(true);
    }
  }, [sent])


	return (
		<div className="container-fluid"
		style={{ paddingLeft: "0px", paddingRight: "0px" }}>
			<Route path="/" render={() => (
				<ErrorBoundary>
					<ConnectedHome url={url} user={user} type={props.setNav} users={users} />
				</ErrorBoundary>
			) } />
			{/*<div
			className="container-fluid"
			style={{ paddingLeft: "0px", paddingRight: "0px" }}
		>
			
			{user && history && (
				<AppPresentation history={history} setNav={props.setNav} />
			)}
			{!user &&
				(history === null || history === "login") &&
				users &&
				users.length > 0 && (
					<React.Fragment>
						<AuthNavigation
							users={users}
							path="register"
							text=""
							link="NEW PROFILE"
							history={history}
							setHistory={props.setHistory}
						/>
						<div
							className="col-md-12"
							style={{ paddingLeft: "0px", paddingRight: "0px" }}
						>
							<AuthLayout>
								<ConnectedLogin />
							</AuthLayout>
						</div>
					</React.Fragment>
				)}
			{!user &&
				(history === null || history === "register") &&
				users &&
				(users.length === 0 || users.length > 0) && (
					<React.Fragment>
						<div
							className="col-md-12"
							style={{ paddingLeft: "0px", paddingRight: "0px" }}
						>
							<AuthLayout>
								<ConnectedRegister />
							</AuthLayout>
						</div>
					</React.Fragment>
				)}
				</div>*/}
		</div>	
	);
}

export default App;
