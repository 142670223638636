import React from "react";
import MathJax from 'react-mathjax-preview'


const MathJaxParse = ({question}) => {
    return (
           <MathJax math={question} />
    )
}

export default MathJaxParse;
